(function() {
    'use strict';

    angular
        .module('app.common')
        .service('listStorage', listStorage);

    listStorage.$inject = [];

    /* @ngInject */
    function listStorage() {
        var s = this;

        s.clearAll = clearAll;
        s.clearExcept = clearExcept;
        s.getItem = getItem;
        s.setItem = setItem;

        function clearAll() {
            s.clearExcept(null);
        }

        function clearExcept(key) {
            var keys = [];

            _.forOwn(localStorage, function(value, key) {
                keys.push({'key': key, 'value': value});
            });

            _(keys)
                .filter(function(item) {
                    return _.endsWith(item.key, 'ListSearch') && item.key !== key;
                })
                .each(function(item) {
                    item.value = JSON.parse(item.value);
                    if (_.has(item.value, 'status')) item.value.status = '';
                    if (_.has(item.value, 'search')) item.value.search = '';
                    if (_.has(item.value, 'tags')) item.value.tags = [];
                    setItem(item.key, item.value);
                });
        }

        function getItem(key) {
            return JSON.parse(localStorage.getItem(key));
        }

        function setItem(key, data) {
            localStorage.setItem(key, JSON.stringify(data));
        }
    }
})();
