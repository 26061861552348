(function() {
    'use strict';

    angular
        .module('app.settings')
        .controller('BillingController', BillingController);

    BillingController.$inject = ['$filter', 'Billing'];

    /* @ngInject */
    function BillingController($filter, Billing) {
        var vm = this;

        vm.billing = Billing;
        vm.daysLeftMessage = $filter('translate')('BILLING.DAYS_LEFT', {'days': Billing.trialDaysLeft()});
    }
})();
