(function() {
    'use strict';

    angular
        .module('app.reports')
        .service('Reports', Reports);

    Reports.$inject = ['$http', '$resource', '$filter', 'globals'];

    /* @ngInject */
    function Reports($http, $resource, $filter, globals) {
        var s = this;

        s.duplicate = duplicate;
        s.templatesFields = templatesFields;
        s.documentsFields = documentsFields;
        s.results = results;
        s.transformColumns = transformColumns;
        s.transformReport = transformReport;
        s.transformFields = transformFields;
        s.transformFilters = transformFilters;

        s.api = $resource(globals.apiUrl + '/reports/:id', {id: '@id'}, {update: {method: 'PUT'}});

        function duplicate(params) {
            return $http.post(globals.apiUrl + '/reports/' + params.id + '/duplicate', params);
        }

        function templatesFields(params) {
            return $http({
                url: globals.apiUrl + '/templates/' + params.id + '/fields',
                method: 'GET',
                params: params
            });
        }

        function documentsFields(params) {
            return $http({
                url: globals.apiUrl + '/documents/fields',
                method: 'GET',
                params: params
            });
        }

        function results(params) {
            return $http({
                url: globals.apiUrl + '/reports/' + params.id + '/results',
                method: 'POST',
                data: {current: params.current || null}
            });
        }

        function transformFields(documentFields, templateFields) {

            var fields = [],
                documentField,
                templateField;

            for (var i = 0; i < documentFields.length; i++) {

                documentField = documentFields[i];

                fields.push({
                    from: 'system',
                    group: $filter('translate')('system'),
                    id: documentField.name,
                    type: documentField.type,
                    comparators: documentField.comparators.data,
                    options: {name: $filter('translate')(documentField.name)},
                    filterable: documentField.filterable
                });
            }

            for (var k = 0; k < templateFields.length; k++) {

                templateField = templateFields[k];

                if (templateField.type !== 'separator') {
                    if (templateField.type === 'table') {
                        if (templateField.options.data.rows.length > 0) {
                            for (var j = 0; j < templateField.options.data.rows[0].length; j++) {
                                if (!angular.equals({}, templateField.options.data.rows[0][j])) {
                                    fields.push({
                                        from: 'template',
                                        group: templateField.options.data.name,
                                        id: templateField.options.data.rows[0][j].id,
                                        type: templateField.options.data.rows[0][j].type,
                                        comparators: templateField.options.data.rows[0][j].comparators,
                                        options: templateField.options.data.rows[0][j].options
                                    });
                                }
                            }
                        }
                    } else {
                        fields.push({
                            from: 'template',
                            group: $filter('translate')('template'),
                            id: templateField.id,
                            type: templateField.type,
                            comparators: templateField.comparators.data,
                            options: templateField.options.data
                        });
                    }
                }
            }

            return fields;
        }

        function transformColumns(report, documentFields, templateFields) {
            for (var i = 0, len = report.columns.data.length; i < len; i++) {
                var column = report.columns.data[i];

                angular.forEach(documentFields, function(documentField) {
                    if (column.field.id === documentField.name) {
                        column.field.name = documentField.name;
                        column.field.from = 'system';
                        column.field.group = $filter('translate')('system');
                    }
                });

                angular.forEach(templateFields, function(templateField) {
                    if (column.field.id === templateField.id) {
                        column.field.name = templateField.options.data.name;
                        column.field.from = 'template';
                        column.field.group = $filter('translate')('template');
                        if (templateField.type === 'calculations') {
                            column.field.formulaType = templateField.options.data.formulaType;
                        }
                    }
                });
            }
        }

        function transformReport(report, documentFields, templateFields, fields) {

            transformColumns(report, documentFields, templateFields);

            angular.forEach(report.filters.data, function(filter) {
                if (filter.value && filter.value.length > 0) {
                    angular.forEach(filter.value, function(value, idx) {
                        if (filter.field.type === 'date') {
                            if (value) {
                                value = new Date(value);
                                filter.value[idx] = value;
                            }
                        } else if (filter.field.type === 'users' || filter.field.type === 'status' || filter.field.type === 'radioGroup') {
                            if (value) {
                                filter.value.splice(idx, 1, {id: value});
                            }
                        } else {
                            filter.value[idx] = value;
                        }
                    });
                }

                angular.forEach(documentFields, function(documentField) {
                    documentField.from = 'system';
                    documentField.group = $filter('translate')('system');
                    if (filter.field.id === documentField.name) {
                        filter.field.name = documentField.name;
                        filter.field.comparators = documentField.comparators.data;
                        filter.field.from = 'system';
                        filter.field.group = $filter('translate')('system');
                    }
                });

                angular.forEach(templateFields, function(templateField) {
                    templateField.from = 'template';
                    templateField.group = $filter('translate')('template');
                    if (filter.field.id === templateField.id) {
                        filter.field.name = templateField.options.data.name;
                        filter.field.comparators = templateField.comparators.data;
                        filter.field.from = 'template';
                        filter.field.group = $filter('translate')('template');
                        filter.field.options = templateField.options.data;
                    }
                });

                angular.forEach(fields, function(field) {
                    if (filter.field.id === field.id && filter.field.type === 'radioGroup') {
                        filter.field.name = field.options.name;
                        filter.field.from = 'template';
                        filter.field.group = $filter('translate')('template');
                        filter.field.options = field.options;
                    }
                });

                if (filter.comparator) {
                    if (filter.comparator === 'is_empty' || filter.comparator === 'is_not_empty') {
                        filter.fieldCount = 0;
                    } else {
                        filter.fieldCount = filter.field.comparators[filter.comparator].field_count || 0;
                    }
                }
            });

            return report;
        }

        function transformFilters(filters) {

            filters = setFilterOrder(filters);

            var filtersCopy = angular.copy(filters);
            var postFilters = [];

            angular.forEach(filtersCopy, function(filter) {
                if (filter.comparator && filter.field.id !== 0) {
                    if (filter.value && filter.value.length > 0) {
                        angular.forEach(filter.value, function(value, idx) {
                            if (value && typeof value === 'object' && value.hasOwnProperty('id')) {
                                filter.value.splice(idx, 1, value.id);
                            }
                            if (value === null || value === '') {
                                filter.value.splice(idx, 1);
                            }
                        });
                    }
                    postFilters.push(filter);
                }
            });

            return postFilters;
        }

        function setFilterOrder(filters) {
            var order = 0;

            return filters
                .map(setItemOrder);

            function setItemOrder(item) {
                item.order = order;
                order++;
                return item;
            }
        }

    }
})();
