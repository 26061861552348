(function() {
    'use strict';

    angular
        .module('app.core', [
            'ngSanitize',
            'ngResource',

            'blocks.exception',
            'blocks.logger',
            'blocks.router',
            'blocks.slugifier',
            'blocks.fileinput',
            'blocks.validator',
            'blocks.recursion',

            'ui.router',
            'pascalprecht.translate',
            'formsTags',
            'ui.bootstrap',
            'tmh.dynamicLocale',
            'ng.deviceDetector',
            'angular-bind-html-compile'
        ]);
})();
