(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('emailsInput', emailsInput);

    emailsInput.$inject = ['$filter', 'Validate'];

    /* @ngInject */
    function emailsInput($filter, Validate) {
        return {
            restrict: 'E',
            template:
                '<forms-tags-input ' +
                    'ng-model="vm.emails" ' +
                    'add-on-paste="true"' +
                    'paste-split-pattern="[ ,]"' +
                    'allowed-tags-pattern="^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([a-z]{2,6}(?:\\.[a-z]{2})?)$" ' +
                    'allow-leftover-text="true"' +
                    'placeholder="' + $filter('translate')('EMAILS_INPUT.EMAILS') + '">' +
                '</forms-tags-input>',
            scope: {
                emails: '=',
                invalidEmails: '='
            },
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller() {
            var enteredEmails, pasteEmails, resultEmails,
                vm = this;

            vm.bulkPaste = bulkPaste;

            function bulkPaste(event) {
                event.preventDefault();

                enteredEmails = angular.copy(vm.emails);
                pasteEmails = event.originalEvent.clipboardData.getData('text/plain').split(/[ ,]+/);
                resultEmails = enteredEmails ? enteredEmails.map(function(email) {
                    return email.text;
                }).concat(pasteEmails) : pasteEmails;

                vm.invalidEmails = [];
                vm.emails = verifyEmails(resultEmails);

                function verifyEmails(emails) {
                    var result = [];
                    var seen = {};

                    emails = emails.filter(Boolean); // remove empty strings
                    emails = emails.filter(function(email) {
                        return seen.hasOwnProperty(email) ? false : (seen[email] = true); // check for duplicates
                    });
                    for (var i = 0; i < emails.length; i++) {
                        if (Validate.email(emails[i])) { // validate email format
                            result.push(emails[i]);
                        } else {
                            vm.invalidEmails.push(emails[i]); // show invalid emails to the user
                        }
                    }
                    return result;
                }
            }
        }
    }
})();
