(function() {
    'use strict';

    angular
        .module('app.templates')
        .controller('MapperController', MapperController);

    MapperController.$inject = ['$rootScope', '$scope', '$q', '$filter', '$document', '$state', '$stateParams', '$timeout', 'Sources', 'Templates', 'Fields', 'Session', 'Documents', 'logger', 'hotRegisterer', 'SweetAlert', 'keyboardManager', 'constLineHeight', 'msgBus'];

    /* @ngInject */
    function MapperController($rootScope, $scope, $q, $filter, $document, $state, $stateParams, $timeout, Sources, Templates, Fields, Session, Documents, logger, hotRegisterer, SweetAlert, keyboardManager, constLineHeight, msgBus) {
        var modal,
            hot,
            fileinput = angular.element('.fileinput'),
            droppable = null,
            saveTimer,
            lastBindings = '',
            vm = this;

        $scope.$on('$destroy', function() {
            removeBindings();
        });

        vm.jwt = Session.jwt;
        vm.defaults = {
            canvas: {width: 800},
            field: {
                margin: 20,
                fontSize: 14,
                lineHeight: constLineHeight
            }
        };
        vm.fontSizeSlider = {floor: 6, ceil: 60, onEnd: updateOptions};
        vm.timeout = 500;
        vm.successTimeout = 2000;
        vm.fields = [];
        vm.fieldsMeta = {};
        vm.selectedField = null;
        vm.availableFields = [];
        vm.templateLoading = false;
        vm.template = {};
        vm.templateMeta = {};
        vm.pages = [];
        vm.page = 1;
        vm.loadingDataSources = false;
        vm.dataSources = [];
        vm.dataSource = {
            settings: {
                colWidths: [300, 300],
                colHeaders: [
                    $filter('translate')('MAPPER.DATASOURCE.OPTION'),
                    $filter('translate')('MAPPER.DATASOURCE.VALUE')
                ],
                stretchH: 'all',
                minSpareRows: 1,
                minSpareCols: 0,
                contextMenu: ['remove_row'],
                afterChange: afterHotChange,
                afterRemoveRow: afterHotChange
            },
            view: 'new-source',
            changedRows: [],
            cache: {},
            loading: false
        };
        vm.formula = {
            available: [],
            tags: [],
            type: ''
        };

        vm.reorderFieldsListener = {
            'placeholder': 'ui-sortable-placeholder',
            'handle': '.ui-sortable-item-handle',
            'axis': 'y',
            'containment': '.toolbox-content',
            stop: reOrderFields
        };

        vm.progress = {fieldDelete: false, fieldSave: false, fieldMoving: false};

        vm.changeState = changeState;
        vm.switchPage = switchPage;
        vm.initView = initView;
        vm.initDesigner = initDesigner;

        vm.getTemplate = getTemplate;
        vm.uploadFile = uploadFile;
        vm.updateTemplate = updateTemplate;

        vm.getAvailableFields = getAvailableFields;
        vm.getFieldsForPage = getFieldsForPage;
        vm.getFieldsForList = getFieldsForList;

        vm.addField = addField;
        vm.addFieldDesigner = addFieldDesigner;
        vm.selectField = selectField;
        vm.moveFields = moveFields;
        vm.resizeFields = resizeFields;
        vm.duplicateField = duplicateField;
        vm.deleteField = deleteField;
        vm.clearField = clearField;
        vm.setSelectDefault = setSelectDefault;
        vm.clearSelectDefault = clearSelectDefault;
        vm.updateOptions = updateOptions;

        vm.addColumns = addColumns;
        vm.addRows = addRows;
        vm.getRows = function(num) {
            return new Array(num);
        };

        vm.resizeStop = resizeStop;
        vm.onDrop = onDrop;

        vm.isAllowedToTable = isAllowedToTable;
        vm.onTableDrop = onTableDrop;
        vm.columnResizeStop = columnResizeStop;

        vm.changeDataSourceView = changeDataSourceView;
        vm.showDataSource = showDataSource;
        vm.getDataSources = getDataSources;
        vm.loadDataSource = loadDataSource;
        vm.createDataSource = createDataSource;
        vm.updateDataSource = updateDataSource;
        vm.addDataSource = addDataSource;
        vm.deleteDataSource = deleteDataSource;
        vm.deleteDataSourceConfirm = deleteDataSourceConfirm;
        vm.removeDataSource = removeDataSource;
        vm.removeDataSourceConfirm = removeDataSourceConfirm;
        vm.populateDataSource = populateDataSource;
        vm.initHot = initHot;

        vm.updateFormula = updateFormula;
        vm.getAvailableFieldsForFormula = getAvailableFieldsForFormula;
        vm.formulaChanged = formulaChanged;

        vm.reOrderFields = reOrderFields;

        vm.doSaveTime = doSaveTime;
        vm.unique = unique;

        vm.changeFieldType = changeFieldType;

        vm.addSeparator = addSeparator;
        vm.addRadio = addRadio;

        vm.tableFilter = function(item) {
            return !item.options.table && item.type !== 'separator';
        };

        vm.getFieldsForNameFormula = getFieldsForNameFormula;
        vm.nameFormulaChanged = nameFormulaChanged;

        activate();

        function activate() {
            var promises = [getAvailableFields(), getTemplate()];

            return $q.all(promises)
                .then(success)
                .catch(fail);

            function success() {
                initView();

                msgBus.on('breadcrumbs.action', function(event, data) {
                    vm.updateTemplate('name', data.name);
                }, $scope);

                msgBus.emit('breadcrumbs.setObject', {
                    object: vm.template,
                    meta: {new: $stateParams.new}
                });

                if ($stateParams.new) msgBus.emit('breadcrumbs.focus');
            }

            function fail() {
                vm.templateLoading = false;
            }
        }

        function getAvailableFields() {
            return Fields.available.get({templateId: $stateParams.id, include: 'designer,list'}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.availableFields = Fields.sortForView(Fields.transform.forToolbar(response.data), 'mapper');
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function getTemplate() {
            vm.templateLoading = true;

            return Templates.api.get({id: $stateParams.id, include: 'images'}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.template = response.data;
                vm.templateMeta = response.meta;

                if (vm.template.images.data.vectors) {
                    angular.element('#svg').html('');

                    Documents.getSvg(vm.template.images.data.vectors[vm.page].source)
                        .then(function(response) {
                            angular.element('#svg').append(response.data);
                            angular.element('#svg svg').attr('width', vm.template.images.data.vectors[vm.page].width);
                            angular.element('#svg svg').attr('height', vm.template.images.data.vectors[vm.page].height);
                        })
                        .catch(fail);
                }
            }

            function fail(response) {
                logger.error(response.data);

                if (response.status == 403 || response.status == 404) {
                    $state.go('app.templateError');
                }
            }
        }

        function getFieldsForNameFormula(query) {
            var params = {
                templateId: vm.template.id,
                query: query
            };

            return Fields.getSettingsNameAutocomplete.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                return _.map(response.data, function(item) {
                    return {
                        id: item.id,
                        name: item.options.data.name
                    };
                });
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function nameFormulaChanged() {
            vm.updateTemplate('settings');
        }

        function initView() {
            if (vm.template.type === 'pdf') {
                if (vm.template.file) {
                    vm.getFieldsForPage();
                } else {
                    vm.templateLoading = false;
                }
            } else {
                vm.getFieldsForList();
            }
        }

        function changeState(state) {
            $state.go(state, {}, {location: 'replace'});
        }

        function getFieldsForPage(cursor) {

            vm.templateLoading = true;

            var params = {
                templateId: vm.template.id,
                page: vm.page,
                include: 'designer,list',
                current: cursor,
                number: 200,
                orderBy: 'position',
                order: 'asc'
            };

            return Fields.fieldsOnPage.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                if (cursor) {
                    vm.fields = vm.fields.concat(Fields.transform.forDesigner.forPopulate(response.data));
                } else {
                    vm.fields = Fields.transform.forDesigner.forPopulate(response.data);
                }

                if (response.meta.cursor.next) {
                    getFieldsForPage(response.meta.cursor.next);
                } else {
                    vm.initDesigner();
                }
            }

            function fail(response) {
                vm.templateLoading = false;
                logger.error(response.data);
            }
        }

        function getFieldsForList(cursor) {
            vm.templateLoading = true;

            var params = {
                templateId: vm.template.id,
                include: 'list',
                current: cursor,
                number: 200,
                orderBy: 'position',
                order: 'asc'
            };

            return Fields.fields.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                if (cursor) {
                    vm.fields = vm.fields.concat(Fields.transform.forDesigner.forPopulate(response.data));
                } else {
                    vm.fields = Fields.transform.forDesigner.forPopulate(response.data);
                }

                if (response.meta.cursor.next) {
                    getFieldsForList(response.meta.cursor.next);
                }

                vm.templateLoading = false;
            }

            function fail(response) {
                vm.templateLoading = false;
                logger.error(response.data);
            }
        }

        function calculateColumnWidths(tableElement, field) {
            if (field && field.type === 'table') {
                var tableWidth = tableElement.width();
                var columns = tableElement.find('tr:first').children();

                angular.forEach(columns, function(value, key) {
                    field.options.columns[key].width = angular.element(value).outerWidth(true) * 100 / tableWidth;
                });
                angular.element(window).triggerHandler('resize.JColResizer');
            }
        }

        function resizeStop(event, ui) {
            if (vm.selectedField.type === 'table') {
                var table = angular.element(ui.element).find('table');

                calculateColumnWidths(table, vm.selectedField);
            }
            resizeFields(ui);
        }

        function onDrop(event, ui) {
            if (angular.element(event.target).attr('id') !== 'canvas-wrapper') return;

            var draggable = angular.element(ui.draggable);

            if (draggable.hasClass('widget')) {
                moveFields(ui);
            } else {
                var field = draggable.scope().field;
                var position = {
                    top: ui.position.top - droppable.offset().top - 1,
                    left: ui.position.left - droppable.offset().left - 1
                };

                addFieldDesigner(field, position);
            }
        }

        function isAllowedToTable(draggable, field, column) {
            if (draggable.field.id || draggable.field.type === 'radioGroup' || draggable.field.type === 'table' || column.field) {
                return false;
            }
            return true;
        }

        function onTableDrop(event, ui, tableField, position) {
            event.stopPropagation();

            if (vm.selectedField) return;

            var draggable = angular.element(ui.draggable);
            var field = draggable.scope().field;

            var newField = angular.copy(field);

            newField.options.table = tableField.id;
            newField.options.row = 0;
            newField.options.column = position.column;

            return Fields.fieldsOnPage.save(Fields.transform.fromDesigner.forSave(newField, vm.template.id, vm.page)).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                field = Fields.transform.forDesigner.forPopulate([response.data])[0];

                Fields.transform.forTable.forPopulate(vm.fields, field);

                vm.progress.fieldSave = false;

                logger.clear();

                vm.doSaveTime();
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function columnResizeStop(event, scope, attrs, tableField) {
            var table = angular.element(event.currentTarget);

            calculateColumnWidths(table, tableField);
            updateOptions(tableField);
        }

        function clickBody(event) {
            if (angular.element(event.target).is('body')) {
                clearField();
            }
        }

        function clickDroppable(event) {
            var target = angular.element(event.target);

            if (!target.hasClass('widget') && !target.parents('.widget').length > 0) {
                clearField();
            }
        }

        function initDesigner() {
            if (!vm.template.file) return false;

            if ($state.params.fieldId && !vm.selectedField) {
                var find = vm.fields.filter(function(v) {
                    return v.id == $state.params.fieldId;
                });

                if (find.length > 0) {
                    vm.selectField(find[0]);
                } else {
                    vm.clearField();
                }
            }

            droppable = angular.element('#canvas-wrapper');
            addBindings('designer');

            Fields.transform.forTable.forPopulate(vm.fields);
            vm.pages = Templates.transform.pages(vm.template.images.data.thumbnails);
            vm.templateLoading = false;
        }

        function addBindings(from) {
            lastBindings = from;

            droppable.on('click', clickDroppable);

            keyboardManager.bind('up', function() {
                manualMove('up', 'move');
            }, {'inputDisabled': true});

            keyboardManager.bind('shift+up', function() {
                manualMove('up', 'resize');
            }, {'inputDisabled': true});

            keyboardManager.bind('down', function() {
                manualMove('down', 'move');
            }, {'inputDisabled': true});

            keyboardManager.bind('shift+down', function() {
                manualMove('down', 'resize');
            }, {'inputDisabled': true});

            keyboardManager.bind('left', function() {
                manualMove('left', 'move');
            }, {'inputDisabled': true});

            keyboardManager.bind('shift+left', function() {
                manualMove('left', 'resize');
            }, {'inputDisabled': true});

            keyboardManager.bind('right', function() {
                manualMove('right', 'move');
            }, {'inputDisabled': true});

            keyboardManager.bind('shift+right', function() {
                manualMove('right', 'resize');
            }, {'inputDisabled': true});

            keyboardManager.bind('backspace', function() {
                if (vm.selectedField) {
                    deleteField(vm.selectedField.id);
                }
            }, {'inputDisabled': true});

            keyboardManager.bind('tab', function() {
                if (vm.selectedField) {
                    var index = vm.fields.indexOf(vm.selectedField) + 1;

                    if (index > vm.fields.length - 1) {
                        vm.selectField(vm.fields[0]);
                    } else {
                        vm.selectField(vm.fields[index]);
                    }
                }
            }, {'inputDisabled': true});

        }

        function removeBindings() {
            if (droppable) {
                droppable.off('click', clickDroppable);
            }

            $document.off('click', clickBody);

            for (var key in keyboardManager.keyboardEvent) {
                keyboardManager.unbind(key);
            }
        }

        function switchPage(page) {
            if (page > 0 && page <= vm.template.file.pages && vm.page != page) {
                vm.page = parseInt(page);
                removeBindings();
                clearField();
                vm.getTemplate().then(vm.getFieldsForPage());
            }
        }

        function uploadFile() {
            if (!vm.newFile) return false;
            vm.templateLoading = true;

            return Templates.upload(vm.newFile, vm.template.id)
                .then(success)
                .catch(fail);

            function success(response) {
                vm.page = 1;
                vm.newFile = null;

                vm.template = response.data.data;

                fileinput.fileinput('clear'); // clear the input so a file with the same name can be uploaded again

                initView();

                vm.templateMeta = response.meta;
                vm.templateLoading = false;
                vm.doSaveTime();
            }

            function fail(response) {
                vm.newFile = null;
                vm.templateLoading = false;
                fileinput.fileinput('clear');
                logger.error(response.data);
            }
        }

        function updateTemplate(input) {
            var params = {};

            if (input === 'name') {
                if (!vm.template.name) return;

                params = {
                    id: vm.template.id,
                    name: vm.template.name
                };
            } else if (input === 'settings') {
                params = {
                    id: vm.template.id,
                    settings: vm.template.settings
                };
            }

            return Templates.api.update(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.template.settings = response.data.settings;
                vm.template.name = response.data.name;

                vm.templateMeta = response.meta;
                vm.templateLoading = false;
                vm.doSaveTime();
            }

            function fail(response) {
                vm.templateLoading = false;
                logger.error(response.data);
            }
        }

        function addField(field) {
            var fieldCopy = angular.copy(field);

            fieldCopy.designer = null;

            vm.progress.fieldSave = true;
            logger.saving();

            return Fields.fields.save({templateId: vm.template.id}, Fields.transform.fromDesigner.forSave(fieldCopy)).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                field = Fields.transform.forDesigner.forPopulate([response.data])[0];

                vm.fields.push(field);

                vm.progress.fieldSave = false;
                logger.clear();

                vm.doSaveTime();
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function addFieldDesigner(field, coordinates, inPercentages) {
            var fieldCopy = angular.copy(field);

            var fieldObject = {
                left: coordinates ? coordinates.left : vm.defaults.field.margin,
                top: coordinates ? coordinates.top : vm.defaults.field.margin,
                width: fieldCopy.designer.width,
                height: fieldCopy.designer.height
            };

            if (inPercentages) {
                fieldCopy.designer = fieldObject;
            } else {
                fieldCopy.designer = Fields.calcToPrecentage(fieldObject, droppable.height(), droppable.width());
            }

            vm.progress.fieldSave = true;
            logger.saving();

            return Fields.fieldsOnPage.save(Fields.transform.fromDesigner.forSave(fieldCopy, vm.template.id, vm.page)).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                field = Fields.transform.forDesigner.forPopulate([response.data])[0];

                vm.fields.push(field);

                Fields.transform.forTable.forPopulate(vm.fields);

                vm.progress.fieldSave = false;

                logger.clear();

                vm.selectField(field);

                vm.doSaveTime();
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function loadFieldsForSync() {
            if (!vm.selectedField) return false;

            var currentField = angular.copy(vm.selectedField);

            vm.availableForSync = [];

            var params = {
                templateId: vm.template.id,
                type: currentField.type,
                duration: currentField.options.duration,
                number: 200
            };

            Fields.fields.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.availableForSync = Fields.transform.forSync(response.data, currentField);
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function selectField(field, noBlur) {
            if (vm.progress.fieldSave) return;

            if (!noBlur) angular.element('#toolbox input[type=text]').blur();
            if (field.type === 'radioGroup') field = field.options.radios[0];

            vm.selectedField = Fields.transform.fromDesigner.forPopover(field);
            vm.selectedRadioGroup = vm.selectedField.options.hasOwnProperty('radio')
                ? _.find(vm.fields, {'id': vm.selectedField.options.radio})
                : null;

            if ($state.includes('mapper.pdf')) {
                $state.go('mapper.pdf.field', {fieldId: field.id});
            } else {
                $state.go('mapper.list.field', {fieldId: field.id});
            }

            initHot('data-source'); // hot-id of the hot-table

            if (!$rootScope.$$phase) $rootScope.$digest();

            if (vm.selectedField.options.hasOwnProperty('syncId') && !vm.selectedField.options.hasOwnProperty('table')) {
                loadFieldsForSync();
            }

            if (vm.selectedField.options.dataSource) {
                vm.dataSource.loading = true;
                return Sources.api.get({id: vm.selectedField.options.dataSource}).$promise
                    .then(dataSourceSuccess)
                    .catch(fail);
            }

            populateDataSource(); // empties vm.dataSource

            if (vm.selectedField.options.hasOwnProperty('formula')) {
                vm.formula.tags = [];

                if (vm.selectedField.options.formula) {
                    Fields.transform.forFormula.forPopover(
                        vm.selectedField.options.formula,
                        vm.selectedField.options.formulaFields
                    )
                        .then(formulaSuccess);
                }
            }

            function dataSourceSuccess(response) {
                vm.dataSource.loading = false;
                populateDataSource(response.data);
            }

            function formulaSuccess(response) {
                vm.formula.tags = response;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function clearField() {
            if ($state.includes('mapper.pdf')) {
                $state.go('mapper.pdf');
            } else if ($state.includes('mapper.list')) {
                $state.go('mapper.list');
            }

            vm.selectedField = null;
            vm.selectedRadioGroup = null;
        }

        function resizeFields(ui) {
            if (!vm.selectedField) return;

            vm.progress.fieldSave = true;

            logger.saving();

            var sizeInPrecentages = Fields.calcToPrecentage(
                {
                    width: ui.size.width,
                    height: ui.size.height
                },
                droppable.height(),
                droppable.width()
            );

            vm.selectedField.designer.width = sizeInPrecentages.width;
            vm.selectedField.designer.height = sizeInPrecentages.height;

            return Fields.api.update(vm.selectedField).$promise
                .then(success);

            function success() {
                angular.element(ui.element).css({width: 'auto', height: 'auto'});

                vm.progress.fieldSave = false;

                logger.clear();

                vm.doSaveTime();
            }
        }

        function moveFields(ui) {
            if (!vm.selectedField) return;

            vm.progress.fieldSave = true;

            logger.saving();

            var positionInPrecentages = Fields.calcToPrecentage(
                {
                    left: ui.position.left,
                    top: ui.position.top
                },
                droppable.height(),
                droppable.width()
            );

            vm.selectedField.designer.left = positionInPrecentages.left;
            vm.selectedField.designer.top = positionInPrecentages.top;

            return Fields.api.update(vm.selectedField).$promise
                .then(success);

            function success() {
                vm.progress.fieldSave = false;

                logger.clear();

                vm.doSaveTime();
            }
        }

        function manualMove(direction, action) {

            if (!vm.selectedField || !direction) return;

            vm.progress.fieldMoving = true;

            if (action === 'move') {
                if (direction === 'up') {
                    vm.selectedField.designer.top -= Fields.onePixelInPrecentage(droppable, 'top');
                }
                if (direction === 'down') {
                    vm.selectedField.designer.top += Fields.onePixelInPrecentage(droppable, 'top');
                }
                if (direction === 'left') {
                    vm.selectedField.designer.left -= Fields.onePixelInPrecentage(droppable, 'left');
                }
                if (direction === 'right') {
                    vm.selectedField.designer.left += Fields.onePixelInPrecentage(droppable, 'left');
                }
            } else {
                if (direction === 'up') {
                    vm.selectedField.designer.height -= Fields.onePixelInPrecentage(droppable, 'height');
                    if (vm.selectedField.type === 'checkbox' || vm.selectedField.type === 'radio') {
                        vm.selectedField.designer.width -= Fields.onePixelInPrecentage(droppable, 'width');
                    }
                }
                if (direction === 'down') {
                    vm.selectedField.designer.height += Fields.onePixelInPrecentage(droppable, 'height');
                    if (vm.selectedField.type === 'checkbox' || vm.selectedField.type === 'radio') {
                        vm.selectedField.designer.width += Fields.onePixelInPrecentage(droppable, 'width');
                    }
                }
                if (direction === 'left') {
                    vm.selectedField.designer.width -= Fields.onePixelInPrecentage(droppable, 'width');
                    if (vm.selectedField.type === 'checkbox' || vm.selectedField.type === 'radio') {
                        vm.selectedField.designer.height -= Fields.onePixelInPrecentage(droppable, 'height');
                    }
                }
                if (direction === 'right') {
                    vm.selectedField.designer.width += Fields.onePixelInPrecentage(droppable, 'width');
                    if (vm.selectedField.type === 'checkbox' || vm.selectedField.type === 'radio') {
                        vm.selectedField.designer.height += Fields.onePixelInPrecentage(droppable, 'height');
                    }
                }

                angular.element(window).triggerHandler('resize.JColResizer');
            }

            if (saveTimer) {
                $timeout.cancel(saveTimer);
            }

            saveTimer = $timeout(save, 800);

            function save() {
                vm.progress.fieldSave = true;

                logger.saving();

                return Fields.api.update(vm.selectedField).$promise
                    .then(success);

                function success() {
                    vm.progress.fieldMoving = false;
                    vm.progress.fieldSave = false;

                    logger.clear();

                    vm.doSaveTime();
                }

            }
        }

        function duplicateField() {
            if (vm.progress.fieldSave || vm.progress.fieldMoving || vm.progress.fieldDelete || vm.selectedField.options.table) return false;

            var field = null;

            vm.progress.fieldSave = true;

            logger.saving();

            if (vm.selectedRadioGroup) {
                field = angular.copy(vm.selectedRadioGroup);
            } else {
                field = angular.copy(vm.selectedField);
            }

            return Fields.duplicate.save(field).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                field = Fields.transform.forDesigner.forPopulate([response.data])[0];

                vm.fields.push(field);

                Fields.transform.forTable.forPopulate(vm.fields);

                vm.progress.fieldSave = false;

                logger.clear();

                vm.selectField(field);

                vm.doSaveTime();
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function clearOrSelectOtherRadio(radioGroup, removedIndex) {
            selectField(radioGroup[removedIndex > 0 ? (removedIndex - 1) : 0]);
        }

        function deleteField(id, keepView) {
            if (vm.progress.fieldSave || vm.progress.fieldMoving || vm.progress.fieldDelete) return false;

            vm.progress.fieldDelete = true;

            logger.saving();

            return Fields.api.delete({id: id || (vm.selectedRadioGroup ? vm.selectedRadioGroup.id : vm.selectedField.id)}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                if (response.data.type === 'radio') {
                    var groupIndex = _.findIndex(vm.fields, {'id': response.data.options.data.radio});
                    var groupField = vm.fields[groupIndex];
                    var removedRadioIndex = _.findIndex(groupField.options.radios, {'id': response.data.id});

                    groupField.options.radios = _.reject(groupField.options.radios, {'id': response.data.id});

                    if (groupField.options.radios.length === 0) {
                        vm.fields.splice(groupIndex, 1);
                        clearField();
                    } else if (!keepView) {
                        clearOrSelectOtherRadio(groupField.options.radios, removedRadioIndex);
                    }
                } else {
                    for (var i = 0; i < vm.fields.length; i++) {
                        if (vm.fields[i].options.syncId == response.data.id) {
                            vm.fields[i].options.syncId = null;
                        }

                        if (vm.fields[i].id == response.data.id) {
                            var spliceIndex = i;
                        }
                    }

                    if (angular.isDefined(spliceIndex)) {
                        vm.fields.splice(spliceIndex, 1);

                        if (!keepView) clearField();
                    }
                }

                if (response.data.options.data.table) clearField();

                if (response.data.type !== 'table') {
                    var field = Fields.transform.forDesigner.forPopulate([response.data])[0];

                    Fields.transform.forTable.forPopulate(vm.fields, field, true /* remove */);
                }


                vm.progress.fieldDelete = false;

                if (vm.fieldsMeta.cursor && vm.fieldsMeta.cursor.next) {
                    vm.fieldsMeta.cursor.next = btoa(+atob(vm.fieldsMeta.cursor.next) - 1);
                }

                logger.clear();
                logger.success($filter('translate')('MAPPER.FIELD_DELETED'));

                vm.doSaveTime();
            }

            function fail(response) {
                vm.progress.fieldDelete = false;

                logger.clear();
                logger.error(response.data);
            }
        }

        function addRows(field, oldRowCount) {
            if (!vm.selectedField.options.rowsCount || vm.selectedField.options.rowsCount < 1 || vm.selectedField.options.rowsCount > 50) {
                vm.selectedField.options.rowsCount = parseInt(oldRowCount);
                logger.error($filter('translate')('MAPPER.POPOVER.ALLOWED_ROW_COUNT'));
                return false;
            }

            angular.element(window).triggerHandler('resize.JColResizer');

            $timeout(function() {
                updateOptions();
            });
        }

        function addColumns(field, oldColumnCount) {

            if (!vm.selectedField.options.columnsCount || vm.selectedField.options.columnsCount < 1 || vm.selectedField.options.columnsCount > 20) {
                vm.selectedField.options.columnsCount = parseInt(oldColumnCount);
                logger.error($filter('translate')('MAPPER.POPOVER.ALLOWED_COLUMN_COUNT'));
                return false;
            }

            var subract = 0;
            var lastColumn;

            var currentColumns = vm.selectedField.options.columns;
            var changed = field.options.columnsCount - oldColumnCount;

            if (changed > 0) {

                lastColumn = currentColumns[currentColumns.length - 1];

                var width = lastColumn.width / (changed + 1);

                var newColumn = {width: width};

                for (var i = 0; i < changed; i++) {
                    currentColumns.push(angular.copy(newColumn));
                }

                lastColumn.width = width;

            } else {

                angular.forEach(currentColumns, function(value, key) {
                    if (key > field.options.columnsCount - 1) {
                        subract += value.width;
                    }
                });

                currentColumns.splice(field.options.columnsCount, currentColumns.length);
                lastColumn = currentColumns[currentColumns.length - 1];

                lastColumn.width += subract;
            }

            updateOptions();
            angular.element(window).triggerHandler('resize.JColResizer');
        }

        function setSelectDefault(row) {
            vm.selectedField.options.default = row;

            vm.updateOptions();
        }

        function clearSelectDefault() {
            vm.selectedField.options.default = '';

            vm.updateOptions();
        }

        function updateOptions(field) {
            if (!field && !vm.selectedField) return;
            if (!field) field = vm.selectedField;

            vm.progress.fieldSave = true;

            logger.saving();

            return Fields.api.update(Fields.transform.fromDesigner.fromPopover.forUpdate(field)).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.progress.fieldSave = false;

                field = Fields.transform.forDesigner.forPopulate(response.data);

                logger.clear();
                vm.doSaveTime();
                return response;
            }

            function fail(response) {
                vm.progress.fieldSave = false;

                logger.clear();
                logger.error(response.data);
            }
        }

        function changeDataSourceView(view) {
            if (view == 'new-source' || view == 'existing-source') {
                vm.dataSource.view = view;
            } else if (view == 'load-source') {
                vm.dataSource.previousView = angular.copy(vm.dataSource.view);

                vm.getDataSources();

                vm.dataSource.view = view;
            } else if (view == 'source-back') {
                vm.dataSource.view = angular.copy(vm.dataSource.previousView);
            }
        }

        function showDataSource(view) {
            modal = angular.element('#modalDataSource');

            vm.dataSource.status = false;
            vm.dataSource.view = view ? view : 'new-source';

            modal.off('shown.bs.modal');
            modal.on('shown.bs.modal', function() {
                angular.element(document).off('focusin.bs.modal');

                if (vm.selectedField.options.dataSource) {
                    loadDataSource(vm.selectedField.options.dataSource);
                } else {
                    hot.loadData([['', '']]);
                }

                $document.on('keydown', function(e) {
                    if (e.which === 8 && e.target.id !== 'data-source-name') e.preventDefault();
                });

                removeBindings();
            });

            modal.on('hidden.bs.modal', function() {
                $document.off('keydown');

                addBindings(lastBindings);

                modal.off('hidden.bs.modal');
            });
        }

        function getDataSources(cursor) {
            if (!cursor) vm.dataSources = [];

            var params = {
                current: cursor,
                number: 200
            };

            vm.loadingDataSources = true;

            return Sources.api.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.dataSources = vm.dataSources.concat(response.data);

                if (response.meta.cursor.next) {
                    vm.getDataSources(response.meta.cursor.next);
                } else {
                    vm.loadingDataSources = false;
                }
            }

            function fail(response) {
                vm.loadingDataSources = false;
                logger.error(response.data);
            }
        }

        function loadDataSource(id) {
            return Sources.api.get({id: id, include: 'rows'}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.dataSource.cache = response.data;
                vm.dataSource.id = response.data.id;

                vm.dataSource.status = '';
                changeDataSourceView('existing-source');

                hot.loadData(
                    response.data.rows.data.length
                        ? Sources.transform.rows.forShow(response.data.rows.data)
                        : [['', '']]
                );
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function createDataSource() {
            var params = {
                name: vm.dataSource.newName == '' ? null : vm.dataSource.newName,
                headers: [
                    $filter('translate')('MAPPER.DATASOURCE.OPTION'),
                    $filter('translate')('MAPPER.DATASOURCE.VALUE')
                ],
                rows: Sources.transform.rows.forSend(hot.getData())
            };

            return Sources.api.save(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.dataSource.cache = response.data;

                vm.dataSource.newName = '';
                vm.dataSource.status = 'saved';
                $timeout(function() {
                    vm.dataSource.status = false;
                }, vm.successTimeout);

                return response.data.id;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function updateDataSource() {
            var params = {
                id: vm.dataSource.id,
                rows: Sources.transform.rows.forSend(hot.getData())
            };

            return Sources.api.update(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.dataSource.cache = response.data;
                vm.dataSource.rowCount = response.data.rowCount;

                vm.dataSource.status = 'saved';
                $timeout(function() {
                    vm.dataSource.status = false;
                }, vm.successTimeout);
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function addDataSource() {
            // if the datasource is saved as reusable before attaching it to the field
            if (vm.dataSource.id && !vm.dataSource.status) {
                vm.dataSource.name = vm.dataSource.cache.name;
                vm.populateDataSource(vm.dataSource.cache);

                vm.selectedField.options.dataSource = vm.dataSource.id;
                vm.selectedField.options.default = '';

                vm.updateOptions();
            } else {
                if (!Sources.transform.rows.forSend(hot.getData()).length) return false;

                vm.createDataSource().then(function(id) {
                    vm.populateDataSource(vm.dataSource.cache);

                    vm.selectedField.options.dataSource = id;
                    vm.selectedField.options.default = '';

                    vm.updateOptions();
                });
            }
        }

        function deleteDataSource(id) {
            SweetAlert.swal({
                title: $filter('translate')('MAPPER.ARE_YOU_SURE'),
                text: $filter('translate')('MAPPER.ABOUT_TO_DELETE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('MAPPER.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteDataSourceConfirm(id);
                }
            });
        }

        function deleteDataSourceConfirm(id) {
            return Sources.api.delete({id: id}).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.getDataSources();
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function removeDataSource(skipClear) {
            SweetAlert.swal({
                title: $filter('translate')('MAPPER.ARE_YOU_SURE'),
                text: $filter('translate')('MAPPER.ABOUT_TO_REMOVE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('MAPPER.REMOVE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    removeDataSourceConfirm(skipClear);
                }
            });
        }

        function removeDataSourceConfirm(skipClear) {
            vm.selectedField.options.dataSource = null;
            vm.selectedField.options.default = '';

            vm.updateOptions();

            vm.populateDataSource(); // empties vm.dataSource
            if (!skipClear) hot.clear();
        }

        function afterHotChange(changes) {
            if (changes && !vm.dataSource.status) vm.dataSource.status = vm.dataSource.view == 'new-source' ? 'new' : 'existing';
        }

        function populateDataSource(data) {
            vm.dataSource.id = data ? data.id : false;
            vm.dataSource.name = data ? data.name : '';
            vm.dataSource.rowCount = data ? data.rowCount : false;
            vm.dataSource.status = false; // gets changed in afterChange of the hot instance
        }

        function initHot(id) {
            if (!hot) hot = hotRegisterer.getInstance(id);
        }

        function updateFormula() {
            vm.selectedField.options.formula = Fields.transform.forFormula.forSave(vm.formula.tags, vm.fields);

            // pass field to distinguish between formula change and name change
            vm.updateOptions(vm.selectedField).then(function(response) {
                vm.selectedField.options.formulaFields = response.data.options.data.formulaFields;
            });
        }

        function getAvailableFieldsForFormula(query) {
            var params = {
                templateId: vm.template.id,
                fieldId: vm.selectedField.id,
                query: query,
                formula: vm.selectedField.options.formula
            };

            return Fields.getAutocompleteFields.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                return Fields.transform.forFormula.forAutoComplete(response.data);
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function formulaChanged(tag) {
            var updateFormulaTimeout;

            if (tag) tag.index = Fields.formulaIndex++; // tag is passed when a tag is added
            if (updateFormulaTimeout) $timeout.cancel(updateFormulaTimeout);

            updateFormulaTimeout = $timeout(function() {
                vm.updateFormula();
            }, vm.timeout);
        }

        function reOrderFields(event, ui) {
            if (!angular.isDefined(ui.item.sortable.dropindex)) return false;

            vm.progress.fieldSave = true;

            logger.saving();

            var params = {
                id: ui.item.sortable.model.id,
                position: ui.item.sortable.index,
                nextId: ui.item.sortable.droptargetModel[ui.item.sortable.dropindex + 1] ? ui.item.sortable.droptargetModel[ui.item.sortable.dropindex + 1].id : null,
                direction: ui.item.sortable.dropindex > ui.item.sortable.index ? 'right' : 'left'
            };

            return Fields.order.update(params).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.progress.fieldSave = false;

                logger.clear();
            }

            function fail(response) {
                vm.progress.fieldSave = false;

                logger.clear();
                logger.error(response.data);
            }
        }

        function doSaveTime() {
            vm.template.updated = new Date();

            msgBus.emit('breadcrumbs.setObject', {object: vm.template});
        }

        function unique() { // provide a unique value for list view fields to prevent as-sortable from losing items
            function s4() {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
        }

        function changeFieldType(newType) {
            var field = vm.selectedField;

            if (newType === 'increment' && !field.options.autoIncrement) {
                delete field.options.required;
                field.options.autoIncrement = {start: 0};
                field.options.syncId = null;
            } else if (newType === 'manual' && field.options.autoIncrement) {
                field.options.required = false;
                field.options.autoIncrement = false;
            } else if (newType === 'duration' && !field.options.duration) {
                field.options.duration = true;
                field.options.default = false;
                loadFieldsForSync();
            } else if (newType === 'time' && field.options.duration) {
                field.options.duration = false;
                field.options.default = false;
                loadFieldsForSync();
            }

            updateOptions();
        }

        function addSeparator(field) {
            if (vm.progress.fieldSave || vm.progress.fieldDelete) {
                return false;
            }

            vm.progress.fieldSave = true;

            var separatorField = vm.availableFields.filter(function(field) {
                return field.type === 'separator';
            })[0];

            return Fields.fieldsOnPage
                .save({
                    templateId: vm.template.id,
                    nextId: field.id
                },
                Fields.transform.fromDesigner.forSave(separatorField, vm.template.id, vm.page)
                ).$promise
                    .then(success)
                    .catch(fail);

            function success(response) {
                var responseField = Fields.transform.forDesigner.forPopulate([response.data])[0];

                vm.fields.splice(vm.fields.indexOf(field), 0, responseField);

                vm.progress.fieldSave = false;
                vm.doSaveTime();
            }

            function fail(response) {
                vm.progress.fieldSave = false;
                logger.error(response.data);
            }
        }

        function addRadio() {
            if (vm.progress.fieldSave || vm.progress.fieldDelete) {
                return false;
            }

            vm.progress.fieldSave = true;

            return Fields.duplicate.save(vm.selectedField)
                .$promise
                .then(success)
                .catch(fail);

            function success(response) {
                var field = Fields.transform.forDesigner.forPopulate([response.data])[0];

                vm.selectedRadioGroup.options.radios.push(field);
                vm.progress.fieldSave = false;

                vm.selectField(field);
                vm.doSaveTime();
            }

            function fail(response) {
                vm.progress.fieldSave = false;
                logger.error(response.data);
            }
        }
    }
})();
