(function() {
    'use strict';

    angular
        .module('app.auth')
        .service('APIInterceptor', APIInterceptor);

    APIInterceptor.$inject = ['$q', '$injector', 'Session', 'Version', 'logger', 'bugsnag'];

    /* @ngInject */
    function APIInterceptor($q, $injector, Session, Version, logger, bugsnag) {
        var params,
            s = this;

        s.request = request;
        s.response = response;
        s.responseError = responseError;

        function request(config) {
            var token = localStorage.getItem('eezeToken') ? localStorage.getItem('eezeToken') : 0;

            config.headers.authorization = 'Bearer ' + token;

            params = angular.copy(config);

            return config;
        }

        function response(config) {
            var headers = config.headers();

            if (headers.authorization) {
                Session.refreshToken(headers.authorization.substr(7));
            }

            if (headers['accept-version']) {
                Version.checkUiVersion(headers['accept-version']);
            }

            bugsnag.beforeNotify = function(payload, metaData) {
                var response = angular.copy(config);

                if (response.data) {
                    response.data = JSON.stringify(response.data);
                }

                metaData['Response'] = response;
                metaData['Params'] = params;
            };

            return config;
        }

        function responseError(config) {
            var $state = $injector.get('$state');
            var body = angular.element('body');
            var backdrop = angular.element('.modal-backdrop.in');
            var headers = config.headers();

            if (config.status == 401) {
                if ($state.current.name != 'auth.login') {
                    Session.lastState = {
                        name: angular.copy($state.current.name),
                        params: angular.copy($state.params)
                    };
                }

                Session.removeData();
                $state.go('auth.login');

                if (backdrop.length) {
                    body.removeClass('modal-open');
                    backdrop.remove();
                }
            } else if (config.status == 402) {
                $state.go('app.settings.billing');
            } else {
                if (config.status >= 500) logger.error(config.statusText);
                if (headers.authorization) Session.refreshToken(headers.authorization.substr(7));
            }

            bugsnag.beforeNotify = function(payload, metaData) {
                var response = angular.copy(config);

                if (response.data) {
                    response.data = JSON.stringify(response.data);
                }

                metaData['Response'] = response;
                metaData['Params'] = params;
            };

            return $q.reject(config);
        }
    }
})();
