(function() {
    'use strict';

    angular
        .module('app.settings')
        .run(appRun);

    appRun.$inject = ['$filter', 'routerHelper'];

    /* @ngInject */
    function appRun($filter, routerHelper) {
        routerHelper.configureStates([
            {
                state: 'app.settings',
                parent: 'app',
                config: {
                    url: '/settings',
                    templateUrl: 'app/settings/settings.html',
                    title: $filter('translate')('NAV.SETTINGS'),
                    controller: 'SettingsController',
                    controllerAs: 'vm',
                    settings: {
                        nav: 0,
                        icon: 'icon-settings',
                        label: 'NAV.SETTINGS'
                    }
                }
            },
            {
                state: 'app.settings.account',
                parent: 'app',
                config: {
                    url: '/account',
                    templateUrl: 'app/account/account.html',
                    controller: 'AccountController',
                    controllerAs: 'vm',
                    title: $filter('translate')('NAV.ACCOUNT'),
                    settings: {
                        nav: 0,
                        icon: 'icon-account',
                        label: 'NAV.ACCOUNT',
                        breadcrumbs: {parent: 'app.settings', self: true}
                    }
                }
            },
            {
                state: 'app.settings.mail',
                parent: 'app.settings',
                config: {
                    url: '/mail',
                    templateUrl: 'app/settings/mail-server.html',
                    title: $filter('translate')('NAV.MAIL_SERVER'),
                    settings: {
                        nav: 0,
                        breadcrumbs: {parent: 'app.settings', self: true}
                    }
                }
            },
            {
                state: 'app.settings.tags',
                parent: 'app.settings',
                config: {
                    url: '/tags',
                    templateUrl: 'app/settings/manage-tags.html',
                    title: $filter('translate')('NAV.MANAGE_TAGS'),
                    settings: {
                        nav: 0,
                        breadcrumbs: {parent: 'app.settings', self: true}
                    }
                }
            },
            {
                state: 'app.settings.groups',
                parent: 'app.settings',
                config: {
                    url: '/groups',
                    templateUrl: 'app/settings/manage-groups.html',
                    title: $filter('translate')('NAV.MANAGE_GROUPS'),
                    settings: {
                        nav: 0,
                        breadcrumbs: {parent: 'app.settings', self: true}
                    }
                }
            },
            {
                state: 'app.settings.users',
                parent: 'app',
                config: {
                    url: '/users',
                    templateUrl: 'app/users/users.html',
                    controller: 'UsersController',
                    controllerAs: 'vm',
                    title: $filter('translate')('NAV.USERS'),
                    settings: {
                        nav: 0,
                        icon: 'icon-user',
                        label: 'NAV.USERS',
                        breadcrumbs: {parent: 'app.settings', self: true}
                    }
                }
            },
            {
                state: 'app.settings.billing',
                parent: 'app',
                config: {
                    url: '/billing',
                    templateUrl: 'app/settings/billing/overview.html',
                    controller: 'BillingController',
                    controllerAs: 'vm',
                    title: $filter('translate')('NAV.BILLING'),
                    settings: {
                        nav: 0,
                        label: 'NAV.BILLING',
                        breadcrumbs: {parent: 'app.settings', self: true}
                    }
                }
            }
        ]);
    }
})();
