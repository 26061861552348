(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('timeField', timeField);

    function timeField() {
        return {
            require: '?ngModel',
            link: function(scope, element, attrs, ngModel) {
                if (!ngModel) return;

                ngModel.$formatters.unshift(function(value) {
                    return value.replace(/:00\.000$/, '');
                });
            }
        };
    }
})();
