(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('SelectModalController', SelectModalController);

    SelectModalController.$inject = ['$scope', '$element', '$q', '$uibModal', 'Sources', 'Zoom', 'logger'];

    /* @ngInject */
    function SelectModalController($scope, $element, $q, $uibModal, Sources, Zoom, logger) {
        var modal,
            params,
            body,
            scrollPos,
            vm = this;

        vm.searchFilter = {search: ''};
        vm.inProgress = false;
        vm.timeout = 300;

        vm.rows = [];
        vm.rowsMeta = {};

        vm.getDatasourceRows = getDatasourceRows;
        vm.moreRows = moreRows;
        vm.selectRow = selectRow;
        vm.cancel = cancel;
        vm.clear = clear;

        $element.bind('click', function() {
            $element.blur();

            modal = $uibModal.open({
                animation: false,
                templateUrl: 'app/documents/select-modal/select-modal.html',
                scope: $scope
            });

            activate();

            modal.closed
                .then(close);
        });

        function activate() {
            if (!vm.preventZoom) Zoom.disable();

            var promises = [getDatasourceRows()];

            body = angular.element('body');
            scrollPos = body[0].scrollTop;

            body.css({
                'width': '100%',
                'overflow-y': 'scroll',
                'position': 'fixed',
                'top': -scrollPos
            });

            return $q.all(promises);
        }

        function close() {
            if (!vm.preventZoom) Zoom.enable();

            body.css({
                'width': '',
                'overflow-y': '',
                'position': '',
                'top': ''
            }).scrollTop(scrollPos);
        }

        function getDatasourceRows() {
            if (!vm.source) return false;

            vm.inProgress = true;

            params = {
                id: vm.source,
                search: vm.searchFilter.search ? vm.searchFilter.search : null,
                order: 'asc'
            };

            return Sources.rows.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.rows = response.data;
                vm.rowsMeta = response.meta;

                vm.inProgress = false;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function moreRows() {
            if (vm.rows.length && vm.rowsMeta.cursor.next && !vm.inProgress) {
                vm.inProgress = true;

                params = {
                    id: vm.source,
                    search: vm.searchFilter.search ? vm.searchFilter.search : null,
                    current: vm.rowsMeta.cursor.next,
                    prev: vm.rowsMeta.cursor.current,
                    order: 'asc'
                };

                return Sources.rows.get(params).$promise
                    .then(success);
            }

            function success(response) {
                vm.rows = vm.rows.concat(response.data);
                vm.rowsMeta = response.meta;
                vm.inProgress = false;
            }
        }

        function selectRow(row) {
            modal.close(row);
            vm.action(row);
        }

        function cancel() {
            modal.dismiss('cancel');
        }

        function clear() {
            modal.close();
            vm.clearaction();
        }
    }
})();
