(function() {
    'use strict';

    angular
        .module('app.users')
        .service('Users', Users);

    Users.$inject = ['$resource', '$http', 'globals'];

    /* @ngInject */
    function Users($resource, $http, globals) {
        var s = this;

        s.validateEmail = validateEmail;
        s.invite = invite;
        s.resendInvitation = resendInvitation;
        s.roles = roles;

        s.api = $resource(globals.apiUrl + '/users/:id', {id: '@id'}, {update: {method: 'PUT'}});
        s.profile = $resource(globals.apiUrl + '/users/profile', null, {update: {method: 'PUT'}});
        s.groups = $resource(globals.apiUrl + '/groups/:id', {id: '@id'}, {update: {method: 'PUT'}});

        function validateEmail(email) {
            return $http.post(globals.apiUrl + '/users/email', {email: email});
        }

        function invite(data) {
            return $http.post(globals.apiUrl + '/users/invite', {emails: data});
        }

        function resendInvitation(id) {
            return $http.post(globals.apiUrl + '/users/reinvite', {id: id});
        }

        function roles(params) {
            return $http.get(globals.apiUrl + '/roles', {params: params});
        }
    }
})();
