(function() {
    'use strict';

    angular
        .module('app.templates')
        .directive('juiDraggable', juiDraggable);

    function juiDraggable() {
        return {
            restrict: 'A',
            link: link,
            scope: {
                juiCreateNew: '@',
                juiOnDrag: '&',
                juiOnDragStart: '&',
                juiOnDragStop: '&'
            }
        };

        function link(scope, elem, attrs) {

            if (scope.juiCreateNew) {

                var field = elem.scope().field;

                var dragHelper = function() {
                    return angular.element('<div></div>')
                            .addClass('widget-toolbox widget-type-' + field.type)
                            .css({
                                width: field.designer.width,
                                height: field.designer.height
                            })
                            .append('<div class=\'widget-content\'></div>');
                };

                elem.draggable({
                    cursor: 'move',
                    cursorAt: {top: 5, left: 5},
                    iframeFix: true,
                    containment: 'document',
                    connectToSortable: '#canvas-wrapper',
                    revert: 'invalid',
                    appendTo: 'body',
                    helper: dragHelper
                });

            } else {
                elem.draggable({
                    containment: 'parent',
                    revert: 'invalid'
                });
            }

            elem.on('drag', function(event, ui) {
                if (scope.juiOnDrag) {
                    scope.juiOnDrag({
                        event: event,
                        ui: ui,
                        scope: scope,
                        attrs: attrs
                    });
                }
            });

            elem.on('dragstart', function(event, ui) {
                if (scope.juiOnDragStart) {
                    scope.juiOnDragStart({
                        event: event,
                        ui: ui,
                        scope: scope,
                        attrs: attrs
                    });
                }
            });

            elem.on('dragstop', function(event, ui) {
                if (scope.juiOnDragStop) {
                    scope.juiOnDragStop({
                        event: event,
                        ui: ui,
                        scope: scope,
                        attrs: attrs
                    });
                }
            });

        }
    }
})();
