(function() {
    'use strict';

    var eezeBugsnag;

    angular
        .module('blocks.exception')
        .config(['$provide', function($provide) {
            $provide.provider({
                bugsnag: function() {

                    // if a script blocker blocks the bugsnag library Bugsnag will be undefined at this point, so we initialize it to an object
                    // with methods that do nothing but are declared and won't throw errors later by the angular-bugsnag
                    // module calling them
                    var Bugsnag = window.Bugsnag || {
                        notifyException: angular.noop,
                        notify: angular.noop,
                        noConflict: angular.noop
                    };

                    eezeBugsnag = Bugsnag;
                    var self = this;
                    var bfNotify;

                    this.noConflict = function() {
                        eezeBugsnag = Bugsnag.noConflict();
                        return self;
                    };

                    this.apiKey = function(apiKey) {
                        eezeBugsnag.apiKey = apiKey;
                        return self;
                    };

                    this.releaseStage = function(releaseStage) {
                        eezeBugsnag.releaseStage = releaseStage;
                        return self;
                    };

                    this.notifyReleaseStages = function(notifyReleaseStages) {
                        eezeBugsnag.notifyReleaseStages = notifyReleaseStages;
                        return self;
                    };

                    this.appVersion = function(appVersion) {
                        eezeBugsnag.appVersion = appVersion;
                        return self;
                    };

                    this.user = function(user) {
                        eezeBugsnag.user = user;
                        return self;
                    };

                    this.projectRoot = function(projectRoot) {
                        eezeBugsnag.projectRoot = projectRoot;
                        return self;
                    };

                    this.endpoint = function(endpoint) {
                        eezeBugsnag.endpoint = endpoint;
                        return self;
                    };

                    this.metaData = function(metaData) {
                        eezeBugsnag.metaData = metaData;
                        return self;
                    };

                    this.autoNotify = function(autoNotify) {
                        eezeBugsnag.autoNotify = autoNotify;
                        return self;
                    };

                    this.beforeNotify = function(beforeNotify) {
                        bfNotify = beforeNotify;
                        return self;
                    };

                    this.testRequest = function(testRequest) {
                        eezeBugsnag.testRequest = testRequest;
                        return self;
                    };

                    this.autoBreadCrumbs = function(autoBreadCrumbs) {
                        if (autoBreadCrumbs) {
                            eezeBugsnag.enableAutoBreadcrumbs();
                        } else {
                            eezeBugsnag.disableAutoBreadcrumbs();
                        }
                    };

                    this.$get = ['$injector', function($injector) {
                        if (bfNotify) {
                            eezeBugsnag.beforeNotify = angular.isString(bfNotify) ? $injector.get(bfNotify) : $injector.invoke(bfNotify);
                        }
                        return eezeBugsnag;
                    }];

                }
            });
        }])
        .run(['bugsnag', '$location', '$rootScope', function(bugsnag, $location, $rootScope) {
            // Set the context from $location.url().  We cannot do this above b/c injecting $location creates a circular dependency.
            bugsnag.fixContext = function() {
                bugsnag.context = $location.url();
            };
            // refresh the rate-limit
            $rootScope.$on('$locationChangeSuccess', bugsnag.refresh || angular.noop);
        }]);
}());
