(function() {
    'use strict';

    angular
        .module('app.users')
        .controller('UsersEditController', UsersEditController);

    UsersEditController.$inject = ['$rootScope', '$uibModalInstance', '$filter', '$q', 'Users', 'logger', 'Session', 'user', 'index'];

    /* @ngInject */
    function UsersEditController($rootScope, $uibModalInstance, $filter, $q, Users, logger, Session, user, index) {
        var vm = this;

        vm.progress = {roles: false, groups: false};

        vm.roles = [];
        vm.groups = [];
        vm.timeout = 300;

        vm.self = Session.id;
        vm.permissions = Session.permissions;

        vm.user = user;
        vm.index = index;

        vm.getUser = getUser;
        vm.save = save;

        vm.getRoles = getRoles;
        vm.moreRoles = moreRoles;

        vm.getGroups = getGroups;

        vm.resendInvitation = resendInvitation;

        vm.cancel = cancel;

        activate();

        function activate() {
            var promises = [getUser(), getRoles(), getGroups()];

            return $q.all(promises);
        }

        function getUser() {
            return Users.api.get({id: vm.user.id, include: 'role,groups'}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.user = response.data;
                vm.user.role = response.data.role.data.id;
                vm.user.group = response.data.groups ? response.data.groups.data.id : 0;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function save() {
            if (vm.userEditForm.$invalid) return false;

            return Users.api.update(vm.user).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                logger.success($filter('translate')('USERS.SAVED'));

                if (response.data.id == vm.self) {
                    Session.data.user = response.data;
                    $rootScope.$broadcast('nameChange');
                }

                $uibModalInstance.close({user: response.data, index: vm.index});
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function getRoles() {
            if (!vm.permissions.roles.view) return false;

            vm.progress.roles = true;

            return Users.roles({number: 200})
                .then(success)
                .catch(fail);

            function success(response) {
                vm.roles = response.data.data;

                if (response.data.meta.cursor.next) {
                    vm.moreRoles(response.data.meta.cursor.next);
                } else {
                    vm.progress.roles = false;
                }
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function moreRoles(cursor) {
            var params = {
                current: cursor,
                number: 200
            };

            return Users.roles(params)
                .then(success)
                .catch(fail);

            function success(response) {
                vm.roles = vm.roles.concat(response.data.data);

                if (response.data.meta.cursor.next) {
                    vm.moreRoles(response.data.meta.cursor.next);
                } else {
                    vm.progress.roles = false;
                }
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function getGroups(cursor) {
            if (!vm.permissions.groups['view.list']) return false;

            vm.progress.groups = true;

            var params = {
                current: cursor,
                number: 200
            };

            return Users.groups.get(params).$promise
                .then(success)
                .catch(fail);


            function success(response) {
                vm.groups = vm.groups.concat(response.data);

                if (response.meta.cursor.next) {
                    vm.getGroups(response.meta.cursor.next);
                } else {
                    vm.groups.unshift({id: 0, name: $filter('translate')('USERS.NONE')});

                    vm.progress.groups = false;
                }
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function resendInvitation(id) {
            return Users.resendInvitation(id)
                .then(success)
                .catch(fail);

            function success() {
                logger.success($filter('translate')('USERS.INVITE_RESENT'));
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
