(function() {
    'use strict';

    angular
        .module('app.templates')
        .run(appRun);

    appRun.$inject = ['$filter', 'routerHelper'];

    /* @ngInject */
    function appRun($filter, routerHelper) {
        routerHelper.configureStates(getStates());

        function getStates() {
            return [
                {
                    state: 'app.templates',
                    parent: 'app',
                    config: {
                        url: '/templates',
                        templateUrl: 'app/templates/templates.html',
                        controller: 'TemplatesController',
                        controllerAs: 'vm',
                        title: $filter('translate')('NAV.TEMPLATES'),
                        settings: {
                            nav: 2,
                            icon: 'icon-templates',
                            label: 'NAV.TEMPLATES',
                            breadcrumbs: {self: true}
                        }
                    }
                }
            ];
        }
    }
})();
