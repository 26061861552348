(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('parseListCalculations', parseListCalculations);

    parseListCalculations.$inject = ['$filter'];

    /* @ngInject */
    function parseListCalculations($filter) {
        return {
            link: link,
            require: 'ngModel'
        };

        function link(scope, element, attrs, ngModelCtrl) {
            ngModelCtrl.$formatters.push(function(value) {
                return attrs.parseListCalculations === 'number'
                    ? value
                    : $filter('timeDurationFormatter')({value: value}, attrs.parseListCalculations);
            });
        }
    }
})();
