(function() {
    'use strict';

    angular
        .module('app.documents')
        .service('Tags', Tags);

    Tags.$inject = ['$resource', '$http', '$rootScope', 'globals', 'logger'];

    /* @ngInject */
    function Tags($resource, $http, $rootScope, globals, logger) {
        var s = this;

        s.list = [];
        s.initiated = false;

        s.get = get;
        s.search = search;
        s.clearAll = clearAll;

        s.api = $resource(globals.apiUrl + '/tags/:id', {id: '@id'}, {update: {method: 'PUT'}});

        s.document = $resource(globals.apiUrl + '/documents/:id/tags', {id: '@id'}, {update: {method: 'PUT'}});

        s.transform = {
            forSave: tagsForSave,
            forFilter: tagsForFilter,
            removeDuplicates: tagsRemoveDuplicates
        };

        function get(cursor) {
            if (!cursor && s.initiated) return false;
            if (!cursor && s.list.length) return false;

            s.initiated = true;

            var params = {
                number: 200,
                current: cursor
            };

            return s.api.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                s.list = s.list.concat(response.data);

                if (response.meta.cursor.next) {
                    s.get(response.meta.cursor.next);
                } else {
                    $rootScope.$broadcast('gotTags');
                }
            }

            function fail(response) {
                $rootScope.$broadcast('gotTags');
                logger.error(response.data);
            }
        }

        function search(params) {
            return $http.get(globals.apiUrl + '/tags', {params: params});
        }

        function tagsForSave(tags) {
            var array = [];

            for (var i = 0; i < tags.length; i++) {
                array.push(tags[i].name);
            }

            return array;
        }

        function tagsForFilter(tags) {
            if (tags.length) {
                var array = [];

                for (var i = 0; i < tags.length; i++) {
                    array.push(tags[i].id);
                }

                return array.join(',');
            }
        }

        function tagsRemoveDuplicates(tags) {
            var object = {};
            var array = [];

            for (var i = 0; i < tags.length; i++) {
                object[tags[i].id] = tags[i];
            }

            for (var key in object) {
                array.push(object[key]);
            }

            return array;
        }

        function clearAll() {
            s.list = [];
            s.initiated = false;
        }
    }
})();
