(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('tagsFilter', tagsFilter);

    function tagsFilter() {
        return {
            restrict: 'E',
            templateUrl: 'app/documents/tagsFilter/tagsFilter.html',
            scope: {
                filter: '=', // array of objects
                action: '&'
            },
            controller: 'TagsFilterController',
            controllerAs: 'vm',
            bindToController: true
        };
    }
})();
