(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('sortTable', sortTable);

    function sortTable() {
        return {
            restrict: 'A',
            scope: {
                name: '@',
                heading: '@',
                filter: '=',
                action: '&'
            },
            template: template,
            link: function(scope, element, attrs) {
                if (attrs.hideInMobile) element.addClass('hidden-xs');

                element.addClass('cursor-pointer');

                element.on('click', function() {
                    scope.filter.orderBy = scope.name;
                    scope.filter.order = scope.filter.order == 'asc' ? 'desc' : 'asc';

                    scope.action();
                });
            }
        };
    }

    function template(tElement, tAttrs) {
        var mobile = '<a ng-class="{\'strong\': filter.orderBy == name}" href="">' +
            '<span translate-once="{{heading}}"></span> ' +
            '<i ng-show="filter.orderBy == name && filter.order == \'asc\'" class="sorter sort-asc"></i>' +
            '<i ng-show="filter.orderBy == name && filter.order == \'desc\'" class="sorter sort-desc"></i>' +
            '</a>';

        var desktop = '<span class="hidden-xs">' +
            '<span translate-once="{{heading}}"></span> ' +
            '<i ng-show="filter.orderBy == name && filter.order == \'asc\'" class="sorter sort-asc"></i>' +
            '<i ng-show="filter.orderBy == name && filter.order == \'desc\'" class="sorter sort-desc"></i>' +
            '<i ng-show="filter.orderBy != name" class="icons icon-sort"></i>' +
            '</span>' +
            '<span class="visible-xs" translate-once="{{heading}}"></span>';

        return tAttrs.mobile ? mobile : desktop;
    }
})();
