(function() {
    'use strict';

    angular
        .module('blocks.logger')
        .service('Status', Status);

    function Status() {
        var s = this;

        s.online = true;
    }
})();
