(function() {
    'use strict';

    angular
        .module('app.layout', [
            'app.core',
            'app.common',
            'app.auth',
            'oitozero.ngSweetAlert',
            'infinite-scroll'
        ]);

    angular
        .module('infinite-scroll')
        .value('THROTTLE_MILLISECONDS', 1000);
})();
