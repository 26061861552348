(function() {
    'use strict';

    angular
        .module('app.templates')
        .controller('TemplatesController', TemplatesController);

    TemplatesController.$inject = ['$filter', '$q', '$state', '$uibModal', 'Session', 'Templates', 'Documents', 'SweetAlert', 'logger', 'listStorage'];

    /* @ngInject */
    function TemplatesController($filter, $q, $state, $uibModal, Session, Templates, Documents, SweetAlert, logger, listStorage) {
        var request, params,
            vm = this;

        vm.jwt = Session.jwt;
        vm.permissions = Session.permissions;

        vm.filter = {orderBy: 'updated', order: 'desc'};

        vm.templates = [];
        vm.templatesMeta = [];

        vm.getTemplates = getTemplates;
        vm.moreTemplates = moreTemplates;
        vm.askTemplateType = askTemplateType;
        vm.createTemplate = createTemplate;
        vm.duplicateTemplate = duplicateTemplate;

        vm.deleteTemplate = deleteTemplate;
        vm.deleteTemplateConfirm = deleteTemplateConfirm;

        vm.createDocument = createDocument;

        vm.inProgress = false;
        vm.timeout = 500;

        activate();

        function activate() {
            listStorage.clearExcept('eezeTemplatesListSearch');
            var promises = [getTemplates()];

            return $q.all(promises);
        }

        function getTemplates(filter) {
            if (filter) {
                listStorage.setItem('eezeTemplatesListSearch', vm.filter);
            } else if (localStorage.getItem('eezeTemplatesListSearch')) {
                vm.filter = listStorage.getItem('eezeTemplatesListSearch');
            }

            vm.templates = [];
            vm.inProgress = true;

            params = {
                search: vm.filter.search ? vm.filter.search : null,
                order: vm.filter.order,
                orderBy: vm.filter.orderBy
            };

            if (request) request.$cancelRequest();
            request = Templates.api.get(params);

            return request.$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.templates = response.data;
                vm.templatesMeta = response.meta;

                vm.inProgress = false;

                return vm.templates;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function moreTemplates() {
            if (vm.templates.length && vm.templatesMeta.cursor.next && !vm.inProgress) {
                vm.inProgress = true;

                params = {
                    orderBy: vm.filter.orderBy,
                    order: vm.filter.orderBy == 'name' ? 'asc' : 'desc',
                    search: vm.filter.search ? vm.filter.search : null,
                    current: vm.templatesMeta.cursor.next,
                    prev: vm.templatesMeta.cursor.current
                };

                return Templates.api.get(params).$promise
                    .then(success);
            }

            function success(response) {
                vm.templates = vm.templates.concat(response.data);
                vm.templatesMeta = response.meta;
                vm.inProgress = false;
            }
        }

        function askTemplateType() {
            var modalInstance = $uibModal.open({
                animation: false,
                component: 'chooseType'
            });

            modalInstance.result
                .then(createTemplate);
        }

        function createTemplate(type) {
            logger.loading();

            var params = {
                name: $filter('translate')('TEMPLATES.UNTITLED') + ' ' + $filter('date')(new Date(), 'short'),
                type: type
            };

            return Templates.api.save(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                logger.clear();
                if (type === 'list') {
                    $state.go('mapper.list', {id: response.data.id, new: true});
                } else {
                    $state.go('mapper.pdf', {id: response.data.id, new: true});
                }
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function duplicateTemplate(template) {
            if (!vm.permissions.templates.create) {
                logger.error($filter('translate')('ROUTE_PERMISSIONS_ERROR'));
                return;
            }

            logger.loading();

            return Templates.duplicate({id: template.id})
                .then(success)
                .catch(fail);

            function success(response) {
                vm.templates.unshift(response.data.data);

                logger.clear();
                logger.success($filter('translate')('TEMPLATES.SAVED'));
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data.data);
            }
        }

        function deleteTemplate(template) {
            SweetAlert.swal({
                title: $filter('translate')('TEMPLATES.ARE_YOU_SURE'),
                text: $filter('translate')('TEMPLATES.ABOUT_TO_DELETE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('TEMPLATES.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteTemplateConfirm(template);
                }
            });
        }

        function deleteTemplateConfirm(template) {
            logger.loading();

            return Templates.api.delete({id: template.id}).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.templates.splice(vm.templates.indexOf(template), 1);

                logger.clear();
                logger.success($filter('translate')('TEMPLATES.DELETED'));
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function createDocument(templateId, templateName) {
            logger.loading();

            var params = {
                templateId: templateId,
                name: templateName + ' ' + $filter('date')(new Date(), 'short'),
                default: {time: new Date().getHours() * 3600 + new Date().getMinutes() * 60}
            };

            return Documents.api.save(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                logger.clear();
                $state.go('filler', {
                    new: true,
                    id: response.data.id,
                    templateType: response.data.template.data.type
                });
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }
    }
})();
