(function() {
    'use strict';

    angular
        .module('app.documents')
        .filter('tableRowTitle', tableRowTitle);

    tableRowTitle.$inject = ['$filter'];

    /* @ngInject */
    function tableRowTitle($filter) {
        return function(field, values, index) {
            var string,
                value = field ? (values[field.id] ? values[field.id].value : null) : null;

            if (value || typeof value === 'number') {
                if (field.type === 'time') {
                    string = $filter('timeDurationFormatter')({value: value}, field.options.duration ? 'duration' : 'time');
                } else if (field.type === 'date') {
                    string = $filter('date')(value, 'shortDate', 'UTC');
                } else if (field.type === 'number') {
                    string = $filter('number')(value);
                } else if (field.type === 'signature') {
                    string = $filter('translate')('DOCUMENTS.SIGNED');
                } else {
                    string = value;
                }
            } else {
                string = $filter('translate')('DOCUMENTS.ROW') + ' ' + (index + 1);
            }

            return string;
        };
    }
})();
