(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('PdfFillController', PdfFillController);

    PdfFillController.$inject = ['$scope', '$rootScope', '$filter', '$q', '$state', '$stateParams', '$locale', '$timeout', 'Session', 'Fields', 'Documents', 'Templates', 'logger', 'globals', 'pdf', 'msgBus', 'eezeDatepickerHelper', 'eezeTimepickerHelper', 'eezeDurationpickerHelper', 'SweetAlert', 'Zoom'];

    /* @ngInject */
    function PdfFillController($scope, $rootScope, $filter, $q, $state, $stateParams, $locale, $timeout, Session, Fields, Documents, Templates, logger, globals, pdf, msgBus, eezeDatepickerHelper, eezeTimepickerHelper, eezeDurationpickerHelper, SweetAlert, Zoom) {
        var popover, offset, scale,
            documentId = $stateParams.id,
            valueRequests = [],
            vm = this;

        vm.jwt = Session.jwt;
        vm.can = Session.can;
        vm.permissions = Session.permissions;
        vm.locale = $locale;
        vm.apiUrl = globals.apiUrl;

        vm.defaults = {
            canvas: {width: 800},
            popover: {width: 300},
            datePopover: {width: 285},
            timePopover: {width: vm.locale.id == 'en-us' ? 290 : 240},
            datepicker: {
                showWeeks: false,
                closeText: $filter('translate')('DOCUMENTS.CLOSE'),
                clearText: $filter('translate')('DOCUMENTS.CLEAR'),
                currentText: $filter('translate')('DOCUMENTS.TODAY'),
                fieldFormat: vm.locale.id == 'en-us' ? 'M/d/y' : 'dd.MM.y'
            }
        };

        vm.progress = {get: false, svg: false, saveDocument: false};
        vm.timeout = {short: 300, medium: 500};
        vm.calendar = {}; // for showing and hiding datepicker calendar

        vm.document = {};
        vm.template = {};

        vm.fields = [];
        vm.selectedField = {};
        vm.selectedFieldCache = {};

        vm.values = {};
        vm.dbValues = {};
        vm.valuesCache = {};

        vm.required = [];
        vm.errors = {};
        vm.pages = [];
        vm.page = 1;

        vm.getDocument = getDocument;
        vm.getFields = getFields;
        vm.editDocument = editDocument;
        vm.editValues = editValues;
        vm.updateLocalValues = updateLocalValues;
        vm.duplicateDocument = duplicateDocument;
        vm.addRow = addRow;
        vm.deleteRow = deleteRow;
        vm.setDocumentStatus = setDocumentStatus;
        vm.validateDocument = validateDocument;
        vm.constructErrors = constructErrors;
        vm.doSaveTime = doSaveTime;
        vm.assignUser = assignUser;
        vm.selectField = selectField;
        vm.clearField = clearField;
        vm.closeCalendar = closeCalendar;
        vm.showTagPopover = showTagPopover;
        vm.checkAutoFocus = checkAutoFocus;
        vm.changeState = changeState;
        vm.switchPage = switchPage;
        vm.svgLoaded = svgLoaded;
        vm.selectData = selectData;
        vm.clearSelect = clearSelect;
        vm.toggleCheckbox = toggleCheckbox;
        vm.showTimeDatePopover = showTimeDatePopover;
        vm.selectRadio = selectRadio;
        vm.doSignature = doSignature;
        vm.hideAllPopovers = hideAllPopovers;
        vm.findFieldByColumn = findFieldByColumn;
        vm.archiveDocument = archiveDocument;
        vm.deleteDocument = deleteDocument;
        vm.deleteDocumentConfirm = deleteDocumentConfirm;

        vm.print = pdf.print;
        vm.download = pdf.download;

        activate();

        function activate() {
            if ($rootScope.$$listeners['fieldUpdate']) $rootScope.$$listeners['fieldUpdate'] = [];
            $rootScope.$on('fieldUpdate', function(event, data) {
                vm.values[data.field]
                    ? vm.values[data.field].value = data.value
                    : vm.values[data.field] = {id: data.field, value: data.value};

                if (vm.showDocumentErrors) vm.errors[data.field] = false;

                vm.doSaveTime();
            });

            var promises = [getDocument()];

            return $q.all(promises)
                .then(success);

            function success() {
                msgBus.emit('breadcrumbs.setObject', {
                    object: vm.document,
                    meta: {new: $stateParams.new}
                });

                msgBus.on('breadcrumbs.action', function(event, data) {
                    vm.editDocument('name', {'name': data.name});
                }, $scope);

                Zoom.enable();
            }
        }

        function getDocument(switchPage /* if triggered from switchPage */) {
            vm.progress.get = true;

            return Documents.api.get({id: documentId, include: 'values,tags,template.images,settings'}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.progress.svg = true;

                vm.template = response.data.template.data;
                vm.pages = Templates.transform.pages(vm.template.images.data.thumbnails);

                angular.element('#svg').html('');

                Documents.getSvg(vm.template.images.data.vectors[vm.page].source)
                    .then(function(response) {
                        angular.element('#svg').append(response.data);
                        angular.element('#svg svg').attr('width', vm.template.images.data.vectors[vm.page].width);
                        angular.element('#svg svg').attr('height', vm.template.images.data.vectors[vm.page].height);

                        $timeout(function() {
                            vm.svgLoaded();
                        }, 100);
                    })
                    .catch(fail);

                // refactor template images signing so getDocument does not have to be called from switchPage
                if (!switchPage) {
                    vm.document = response.data;

                    if (!angular.isArray(vm.document.values.data)) {
                        vm.values = vm.document.values.data;
                        vm.valuesCache = angular.copy(vm.values);
                    }

                    vm.checkAutoFocus();
                }

                vm.getFields();
            }

            function fail(response) {
                vm.progress.get = false;

                logger.error(response.data);

                if (response.status == 403 || response.status == 404) {
                    $state.go('app.documentsError');
                }
            }
        }

        function getFields(cursor) {
            vm.progress.get = true;

            var params = {
                documentId: vm.document.id,
                page: vm.page,
                current: cursor,
                exclude: 'hidden',
                include: 'designer',
                orderBy: 'position',
                order: 'asc',
                number: 200
            };

            return Fields.document.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.fields = cursor
                    ? vm.fields.concat(Fields.transform.forDesigner.forPopulate(response.data, {'type': 'separator'}))
                    : Fields.transform.forDesigner.forPopulate(response.data, {'type': 'separator'});

                if (response.meta.cursor.next) {
                    getFields(response.meta.cursor.next);
                } else {
                    Fields.transform.forFill.forPopulate(vm.fields, vm.values);

                    vm.progress.get = false;
                }
            }

            function fail(response) {
                vm.progress.get = false;

                logger.error(response.data);
            }
        }

        function editDocument(input, args) {
            if (input != 'status' && input != 'assign' && (vm.document.status == 4 || vm.document.status == 6)) {
                logger.error($filter('translate')('DOCUMENTS.COMPLETED_CANT_MODIFY'));
                return false;
            }

            vm.progress.saveDocument = true;

            logger.saving();

            var params = {
                id: vm.document.id,
                include: 'tags,settings'
            };

            if (input == 'name') params.name = args.name;
            if (input == 'assign') params.assignedTo = args.userId || 0;
            if (input == 'status') params.status = args.status;

            return Documents.api.update(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.document = response.data;

                vm.progress.saveDocument = false;

                vm.doSaveTime();

                logger.clear();
                logger.success($filter('translate')('DOCUMENTS.SAVED'));

                if (input == 'assign' &&
                    ((Session.group && Session.group != args.groupId) ||
                    (!Session.permissions.documents['view.all'] && Session.id != args.userId))
                ) {
                    $state.go('app.documents');
                }

                return true;
            }

            function fail(response) {
                vm.progress.saveDocument = false;

                logger.clear();
                logger.error(response.data);

                return false;
            }
        }

        function editValues(field) {
            if (vm.document.status == 4 || vm.document.status == 6) {
                logger.error($filter('translate')('DOCUMENTS.COMPLETED_CANT_MODIFY'));
                return false;
            }

            if (angular.equals(vm.values, vm.valuesCache)) return false;

            if (angular.isDefined(field)) {
                var fieldId = angular.copy(field.id);
            } else {
                return false;
            }

            if (!vm.values[fieldId]) return false;

            vm.progress.saveDocument = true;

            logger.saving();

            var params = {
                id: vm.document.id,
                values: {},
                include: 'values,tags'
            };

            if (vm.showDocumentErrors) params.include += ',validation';

            var value = vm.values[fieldId].value,
                option = vm.values[fieldId].option;

            if (!value && typeof value !== 'number') {
                value = null;
            }

            params.values[fieldId] = {
                id: fieldId,
                value: value,
                option: option
            };

            if (valueRequests[fieldId]) valueRequests[fieldId].$cancelRequest();
            valueRequests[fieldId] = Documents.api.update(params);

            return valueRequests[fieldId].$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.document = response.data;
                vm.valuesCache = angular.copy(vm.values);
                vm.dbValues = angular.copy(response.data.values.data);

                msgBus.emit('breadcrumbs.setObject', {
                    object: vm.document,
                    meta: {new: false}
                });

                vm.updateLocalValues();
                vm.doSaveTime();
                vm.hideAllPopovers();

                if (vm.showDocumentErrors) {
                    vm.errors = {};
                    vm.required = response.data.validation.data;

                    for (var i = 0; i < vm.required.length; i++) {
                        vm.errors[vm.required[i].id] = true;
                    }
                }

                vm.progress.saveDocument = false;

                logger.clear();
            }

            function fail(response) {
                vm.progress.saveDocument = false;

                logger.clear();
                logger.error(response.data);
            }
        }

        function duplicateDocument(document) {
            logger.loading();

            var params = {
                id: document.id,
                default: {time: new Date().getHours() * 3600 + new Date().getMinutes() * 60}
            };

            return Documents.duplicate(params)
                .then(success)
                .catch(fail);

            function success(response) {
                logger.clear();
                logger.success($filter('translate')('DOCUMENTS.SAVED'));

                $state.go('filler.pdf', {id: response.data.data.id, duplicate: true});
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function addRow(table) {
            vm.progress.saveDocument = true;

            logger.saving();

            var params = {
                id: table.id,
                default: {time: new Date().getHours() * 3600 + new Date().getMinutes() * 60},
                exclude: 'hidden'
            };

            return Fields.tables.update(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                table.options.rows.push(Fields.transform.forDesigner.forPopulate(response.data));
                vm.dbValues = angular.copy(response.meta.values);

                vm.updateLocalValues();

                vm.progress.saveDocument = false;

                logger.clear();
            }

            function fail(response) {
                vm.progress.saveDocument = false;

                logger.clear();
                logger.error(response.data);
            }
        }

        function deleteRow(table, row) {
            vm.progress.saveDocument = true;

            logger.saving();

            for (var j = 0; j < table.options.rows[row].length; j++) {
                vm.values[table.options.rows[row][j].id] = {
                    id: table.options.rows[row][j].id,
                    value: ''
                };
            }

            return Fields.rows.delete({tableId: table.id, rowId: row}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.fields = Fields.transform.forFill.removeTableRows(vm.fields, table.id, row);
                vm.dbValues = angular.copy(response.meta.values);

                vm.updateLocalValues();

                vm.progress.saveDocument = false;

                logger.clear();
            }

            function fail(response) {
                vm.progress.saveDocument = false;

                logger.clear();
                logger.error(response.data);
            }
        }

        function updateLocalValues() {
            for (var key in vm.dbValues) {
                vm.values[vm.dbValues[key].id] = {
                    id: parseInt(key),
                    value: vm.dbValues[key].value
                };

                if (vm.dbValues[key].type) {
                    vm.values[vm.dbValues[key].id].type = vm.dbValues[key].type;
                }

                if (vm.dbValues[key].option) {
                    vm.values[vm.dbValues[key].id].option = vm.dbValues[key].option;
                }

                if (vm.showDocumentErrors && vm.dbValues[key].value) {
                    vm.errors[vm.dbValues[key].id] = false;
                }
            }

            Fields.transform.forFill.forPopulate(vm.fields, vm.values);
        }

        function setDocumentStatus(status) {
            // Status 4 = Complete
            if (status === 4) {
                vm.validateDocument(status);
            } else {
                vm.showDocumentErrors = false;
                vm.editDocument('status', {status: status});
            }
        }

        function validateDocument(status) {
            return Documents.validateRequiredFields({id: vm.document.id})
                .then(success)
                .catch(fail);

            function success(response) {
                vm.errors = {};
                vm.required = response.data.data;

                if (vm.required.length) {
                    vm.showDocumentErrors = true;

                    for (var i = 0; i < vm.required.length; i++) {
                        vm.errors[vm.required[i].id] = true;
                    }

                    vm.constructErrors();
                } else {
                    vm.showDocumentErrors = false;
                    vm.editDocument('status', {status: status});
                }
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function constructErrors() {
            var error = Fields.transform.forFill.constructErrorList(vm.required);

            logger.error(error.string);
        }

        function doSaveTime() {
            vm.document.updated = new Date();

            msgBus.emit('breadcrumbs.setObject', {object: vm.document});
        }

        function assignUser(userId, groupId) {
            vm.editDocument('assign', {userId: userId, groupId: groupId});
        }

        function selectField(field) {
            vm.selectedField = field;
            vm.selectedFieldCache = {};
        }

        function clearField($event) {
            var doNotClear = ['widget-content-type-text', 'widget-content-type-number', 'widget-content-type-time', 'widget-content-type-date', 'no-clear'];

            if (!$event.target.parentElement || doNotClear.indexOf($event.target.parentElement.className.split(' ')[1]) == -1) {
                vm.selectedFieldCache = angular.copy(vm.selectedField);
                vm.selectedField = {};
            }
        }

        function closeCalendar() {
            $timeout(function() {
                vm.clearField({target: {}});

                vm.hideAllPopovers();
            });
        }

        function showTagPopover(event) {
            popover = angular.element('#tags-popover');
            offset = getOffset(
                event.target.parentElement.localName == 'button'
                    ? event.target.parentElement : event.target
            );

            popover.css({
                left: offset.left + 20,
                top: offset.top - 20,
                right: 'auto',
                bottom: 'auto'
            });

            popover.removeClass('left right top bottom');
            popover.addClass('right');
        }

        function checkAutoFocus() {
            if ($stateParams.new || $stateParams.duplicate) msgBus.emit('breadcrumbs.focus');
        }

        function changeState(state) {
            if (state != $state.current.name) {
                localStorage.setItem('eezeFillerView', state);

                $state.go(state, {new: false});
            }
        }

        function switchPage(page) {
            if (page > 0 && page <= vm.pages.length && vm.page != page) {
                vm.page = parseInt(page);

                vm.hideAllPopovers();

                vm.getDocument(true);
            }
        }

        function svgLoaded() {
            $timeout(function() {
                vm.progress.svg = false;

                msgBus.emit('svgLoaded');
            });
        }

        function selectData(row, field) {
            if (!vm.values[field.id]) vm.values[field.id] = {};

            vm.values[field.id].option = row.values.option;
            vm.values[field.id].value = row.values.value || row.values.option;

            vm.editValues(field);
        }

        function clearSelect(field) {
            if (!vm.values[field.id]) vm.values[field.id] = {};

            vm.values[field.id].option = null;
            vm.values[field.id].value = null;

            vm.editValues(field);
        }

        function toggleCheckbox(field) {
            if (!vm.values[field.id]) vm.values[field.id] = {};

            vm.values[field.id].value = !vm.values[field.id].value;

            vm.editValues(field);
        }

        function showTimeDatePopover(which, field, event) {
            vm.selectedField = field;

            vm.hideAllPopovers();

            if (which === 'date' && $rootScope.device.small) {
                vm.calendar[field.id] = {opened: true};

                Zoom.disable();
            }

            popover = angular.element('#' + which + '-popover');
            scale = angular.element('#fill-form').length > 0
                ? parseFloat(angular.element('#fill-form').css('transform').split('matrix(')[1].split(',')[0])
                : 1;
            offset = getOffset(event.target, scale);

            popover.removeClass('hidden');

            popover.css({
                left: offset.left + event.target.clientWidth * scale / 2 - vm.defaults[which + 'Popover'].width / 2,
                top: offset.top + event.target.clientHeight * scale - 50,
                right: 'auto',
                bottom: 'auto'
            });

            popover.removeClass('left right top bottom');
            popover.addClass('bottom');

            eezeDatepickerHelper.invoker = null;
            eezeTimepickerHelper.invoker = null;
            eezeDurationpickerHelper.invoker = null;
        }

        function selectRadio(field, option) {
            if (vm.document.status == 4 || vm.document.status == 6) return false;

            if (!vm.values[field.id]) vm.values[field.id] = {};

            vm.values[field.id].option = option.id;

            vm.editValues(field);
        }

        function doSignature(field) {
            $state.go('filler.pdf.signature', {fieldId: field.id});
        }

        function hideAllPopovers() {
            for (var i = 0; i < Session.popoverTogglers.length; i++) {
                angular.element('#' + Session.popoverTogglers[i].split('-toggler')[0]).addClass('hidden');
            }

            vm.calendar = {};
            Zoom.enable();
        }

        function getOffset(element, scale) {
            var top = 0,
                left = 0,
                multiplier = scale || 1;

            while (element) {
                if (element.id === 'fill-form') multiplier = 1;

                top += parseInt(element.offsetTop) * multiplier;
                left += parseInt(element.offsetLeft) * multiplier;

                element = element.offsetParent;
            }

            return {
                top: top,
                left: left
            };
        }

        function findFieldByColumn(fields, columnIndex) {
            return _.find(fields, ['options.column', columnIndex]);
        }

        function archiveDocument(id) {
            return vm.editDocument('status', {id: id, status: 6})
                .then(function(success) {
                    if (success) $state.go('app.documents');
                    return success;
                });
        }

        function deleteDocument(id) {
            SweetAlert.swal({
                title: $filter('translate')('DOCUMENTS.ARE_YOU_SURE'),
                text: $filter('translate')('DOCUMENTS.ABOUT_TO_DELETE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('DOCUMENTS.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteDocumentConfirm(id);
                }
            });
        }

        function deleteDocumentConfirm(id) {
            logger.loading();

            return Documents.api.delete({id: id}).$promise
                .then(success)
                .catch(fail);

            function success() {
                $state.go('app.documents');

                logger.clear();
                logger.success($filter('translate')('DOCUMENTS.DELETED'));
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        $scope.$on('$destroy', function() {
            Zoom.disable();
        });
    }
})();
