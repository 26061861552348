(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('tags', tags);

    function tags() {
        return {
            restrict: 'E',
            templateUrl: 'app/documents/tags/tags.html',
            scope: {
                tags: '=',
                document: '='
            },
            controller: 'TagsController',
            controllerAs: 'vm',
            bindToController: true
        };
    }
})();
