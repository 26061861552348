(function() {
    'use strict';

    angular
        .module('app.common')
        .filter('booleanToEmptyString', booleanToEmptyString);

    function booleanToEmptyString() {
        return function(input) {
            return typeof input == 'boolean' ? '' : input;
        };
    }
})();
