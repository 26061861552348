(function() {
    'use strict';

    angular
        .module('app.core')
        .run(appRun);

    /* @ngInject */
    function appRun(routerHelper) {
        var otherwise = '/404';

        routerHelper.configureStates(getStates(), otherwise);
    }

    function getStates() {
        return [
            {
                state: 'error',
                config: {
                    templateUrl: 'app/auth/auth.html',
                    settings: {nav: 0}
                }
            },
            {
                state: 'error.404',
                parent: 'error',
                config: {
                    url: '/404',
                    templateUrl: 'app/core/404.html',
                    title: '404',
                    settings: {nav: 0}
                }
            }
        ];
    }
})();
