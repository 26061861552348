(function() {
    'use strict';

    angular
        .module('app.common')
        .filter('timeDurationFormatter', timeDurationFormatter);

    timeDurationFormatter.$inject = ['$filter'];

    /* @ngInject */
    function timeDurationFormatter($filter) {
        return function(value, type) {
            var time, string;

            if (value && typeof value.value !== 'undefined' && value.value !== '' && value.value !== null) {
                if (type === 'duration') {
                    time = moment.duration(value.value, 'seconds');

                    string = (time < 0 ? '-' : '') +
                        pad(Math.abs(parseInt(time.asHours()))) + ':' +
                        pad(Math.abs(time.asMinutes() - parseInt(time.asHours()) * 60));
                } else {
                    time = new Date(0);
                    string = $filter('date')(time.setUTCSeconds(value.value), 'shortTime', 'UTC');
                }
            }

            return string;
        };
    }

    function pad(input) {
        if (input || typeof input === 'number') return input.toString().length == 1 ? ('0' + input).slice(-2) : input;
    }
})();
