(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('AssignToController', AssignToController);

    AssignToController.$inject = ['$scope', '$uibModal', 'Users', 'logger'];

    /* @ngInject */
    function AssignToController($scope, $uibModal, Users, logger) {
        var modal,
            params,
            vm = this;

        vm.openModal = openModal;
        vm.getUsers = getUsers;
        vm.moreUsers = moreUsers;
        vm.assignUser = assignUser;

        vm.cancel = cancel;
        vm.clear = clear;

        vm.$onInit = initValues();

        function initValues() {
            vm.filter = {search: ''};
            vm.inProgress = false;
            vm.timeout = 300;

            vm.users = [];
            vm.usersMeta = [];
        }

        function openModal() {
            modal = $uibModal.open({
                animation: false,
                templateUrl: 'app/documents/assign-to/assign-to.html',
                scope: $scope
            });

            modal.opened
                .then(getUsers);

            modal.closed
                .then(initValues);
        }

        function getUsers() {
            vm.inProgress = true;

            params = {
                include: 'groups',
                search: vm.filter.search,
                status: 1,
                orderBy: 'firstName',
                order: 'asc'
            };

            return Users.api.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.users = response.data;
                vm.usersMeta = response.meta;

                vm.inProgress = false;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function moreUsers() {
            if (vm.users.length && vm.usersMeta.cursor.next && !vm.inProgress) {
                vm.inProgress = true;

                params = {
                    search: vm.filter.search,
                    current: vm.usersMeta.cursor.next,
                    prev: vm.usersMeta.cursor.current,
                    orderBy: 'firstName',
                    order: 'asc'
                };

                return Users.api.get(params).$promise
                    .then(success)
                    .catch(fail);
            }

            function success(response) {
                vm.users = vm.users.concat(response.data);
                vm.usersMeta = response.meta;
                vm.inProgress = false;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function assignUser(user) {
            if (user.fullName === vm.to.assignedTo) return false;

            modal.close({userId: user.id, groupId: angular.isDefined(user.groups) ? user.groups.data.id : null});
            vm.action({userId: user.id, groupId: angular.isDefined(user.groups) ? user.groups.data.id : null});
        }

        function cancel() {
            modal.dismiss('cancel');
        }

        function clear() {
            modal.close();
            vm.action(0);
        }
    }
})();
