(function() {
    'use strict';

    angular
        .module('app.auth')
        .controller('AuthController', AuthController);

    AuthController.$inject = ['$state', '$window', 'Auth', 'Session'];

    /* @ngInject */
    function AuthController($state, $window, Auth, Session) {
        var vm = this;

        vm.logIn = logIn;
        vm.remindPassword = remindPassword;

        function logIn(email, password) {
            vm.inProgress = true;

            return Auth.logIn(email, password)
                .then(function(response) {
                    if (response === true) {
                        $window.scrollTo(0, 0);
                        $state.go(Session.lastState.name || 'app.documents', Session.lastState.params || {});
                        Session.lastState = '';
                    } else {
                        vm.password = '';
                    }

                    vm.inProgress = false;
                });
        }

        function remindPassword(email) {
            vm.inProgress = true;

            if (typeof email == 'undefined') {
                vm.emailError = 'invalidEmail';
                return false;
            }

            return Auth.remindPassword(email).then(function(response) {
                if (response === true) {
                    vm.emailError = false;
                    $state.go('auth.emailSent');
                } else {
                    vm.emailError = 'notFound';
                    vm.emailEntered = email;
                }
                vm.email = '';
                vm.inProgress = false;
            });
        }

    }
})();
