(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('SendPdfController', SendPdfController);

    SendPdfController.$inject = ['$scope', '$element', '$uibModal', '$filter', 'SweetAlert', 'Documents', 'logger'];

    /* @ngInject */
    function SendPdfController($scope, $element, $uibModal, $filter, SweetAlert, Documents, logger) {
        var params,
            modal,
            vm = this;

        vm.emails = [];
        vm.invalidEmails = [];
        vm.message = '';
        vm.fileName = '';
        vm.error = {emails: false};
        vm.inProgress = false;

        vm.send = send;
        vm.action = action;

        vm.generateFileName = generateFileName;

        vm.cancel = cancel;

        $element.bind('click', function() {
            modal = $uibModal.open({
                animation: false,
                templateUrl: 'app/documents/send-pdf/send-pdf.html',
                scope: $scope
            });

            modal.rendered
                .then(activate());
        });

        function activate() {
            vm.fileName = generateFileName();
        }

        function send() {
            if (!vm.emails.length) {
                vm.error.emails = true;
            } else {
                vm.error.emails = false;

                if (vm.invalidEmails.length) {
                    SweetAlert.swal({
                        title: $filter('translate')('SEND_PDF.WARNING'),
                        text: $filter('translate')('SEND_PDF.INVALID_EMAILS_FOUND'),
                        type: 'warning',
                        showCancelButton: true,
                        cancelButtonText: $filter('translate')('SEND_PDF.CANCEL'),
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: $filter('translate')('SEND_PDF.SEND_ANYWAY'),
                        closeOnConfirm: true
                    }, function(isConfirm) {
                        if (isConfirm) vm.action();
                    });
                } else {
                    vm.action();
                }
            }
        }

        function action() {
            vm.inProgress = true;

            params = {
                id: vm.document.id,
                emails: vm.emails.map(function(email) {
                    return email.text;
                }),
                message: vm.message,
                fileName: vm.fileName
            };

            if (!params.fileName) {
                params.fileName = generateFileName();
            }

            return Documents.email(params)
                .then(success)
                .catch(fail);

            function success() {
                vm.emails = [];
                vm.invalidEmails = [];
                vm.message = '';
                vm.fileName = '';

                modal.close();
                logger.success($filter('translate')('SEND_PDF.EMAIL_SENT'));

                vm.inProgress = false;
            }

            function fail(response) {
                logger.error(response.data);

                vm.inProgress = false;
            }
        }

        function generateFileName() {
            return (vm.document.name || $filter('translate')('DOCUMENTS.UNTITLED')) + ' (' + $filter('date')(new Date(), 'shortDate') + ')';
        }

        function cancel() {
            modal.dismiss('cancel');
        }
    }
})();
