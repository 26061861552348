(function() {
    'use strict';

    angular
        .module('app.core')
        .run(Shell);

    Shell.$inject = ['$rootScope', '$state', '$filter', '$window', '$document', '$timeout', 'routerHelper', 'deviceDetector', 'Auth', 'Session', 'Status', 'Billing', 'logger', '$uibModalStack', 'listStorage', 'bugsnag'];

    /* @ngInject */
    function Shell($rootScope, $state, $filter, $window, $document, $timeout, routerHelper, deviceDetector, Auth, Session, Status, Billing, logger, $uibModalStack, listStorage, bugsnag) {
        var state,
            jwt = null,
            extraSmallWidth = 480,
            smallWidth = 768,
            mobileWidth = 992,
            largeWidth = 1200,
            widthCache = 0,
            hideSidebar = [],
            window = angular.element($window);

        activate();

        function activate() {
            Status.online = true;
            $rootScope.online = true;

            $timeout(detectDevice);

            window.bind('resize', detectDevice);
            window.bind('resize', checkSidebar);

            listStorage.clearAll();
        }

        function detectDevice() {
            var width = $document[0].body.clientWidth;

            $rootScope.device = {
                mobile: width < mobileWidth,
                desktop: width >= mobileWidth,
                small: width < smallWidth,
                extraSmall: width < extraSmallWidth,
                large: width >= largeWidth,
                android: deviceDetector.device == 'android' || deviceDetector.os == 'android',
                ios: deviceDetector.device == 'ios' || deviceDetector.os == 'ios'
            };

            if (!$rootScope.$$phase) $rootScope.$apply();
        }

        function checkSidebar(state) {
            if (!hideSidebar.length) {
                var states = routerHelper.getStates();

                for (var i = 0; i < states.length; i++) {
                    if (states[i].settings && states[i].settings.hideSidebar) {
                        hideSidebar.push(states[i].name);
                    }
                }
            }

            if (typeof state == 'object') { // if called from resize
                if (state.target.innerWidth == widthCache) return false;

                state = $state.current.name;
            }

            $timeout(function() {
                if ($rootScope.device.large) {
                    if (hideSidebar.indexOf(state) > -1) {
                        $rootScope.showSidebar = false;
                        $rootScope.showSidebarToggler = true;
                    } else {
                        $rootScope.showSidebar = true;
                        $rootScope.showSidebarToggler = false;
                    }
                } else {
                    $rootScope.showSidebar = false;
                    $rootScope.showSidebarToggler = true;
                }

                widthCache = $document[0].body.clientWidth;
            });
        }

        Status.online = navigator.onLine;
        $rootScope.online = navigator.onLine;

        $window.addEventListener('offline', function() {
            $rootScope.$apply(function() {
                Status.online = false;
                $rootScope.online = false;

                logger.offline();
            });
        }, false);

        $window.addEventListener('online', function() {
            $rootScope.$apply(function() {
                Status.online = true;
                $rootScope.online = true;

                logger.clear();
            });
        }, false);

        $rootScope.$on('$locationChangeStart', function() {
            angular.element('.sweet-alert').remove();
            angular.element('.sweet-overlay').remove();
            angular.element('#toast-container').remove();
            angular.element('.modal-backdrop').remove();
            $uibModalStack.dismissAll();
        });

        $rootScope.$state = $state;

        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState) {
            event.preventDefault(); // halt the default state resolve
            state = toState.name;

            checkSidebar(state);

            if (Session.pageLoaded || state == 'error.404') {
                checkState(); // if the page has been loaded before, skip jwt verification
            } else {
                if (state == 'auth.support') {
                    localStorage.setItem('eezeToken', toParams.jwt);
                }

                Auth.verifyJwt(jwt)
                    .then(function() {
                        if (Auth.allowedStates.indexOf(state) > -1 && state !== 'auth.support') Auth.logOut(); // user is logged in and goes to e.g. /login

                        checkState();
                    })
                    .catch(function() {
                        if (Auth.allowedStates.indexOf(state) == -1) { // user is not logged in or jwt is expired and goes to e.g. /users
                            Session.lastState = {name: toState.name, params: toParams};
                            $state.go('auth.login');
                        } else { // user is not logged in or jwt is expired and goes to e.g. /login
                            checkState();
                        }
                    });

                Session.pageLoaded = true;
            }

            function checkState() {
                bugsnag.beforeNotify = function(payload, metaData) {
                    metaData['State'] = {
                        'toState': state,
                        'fromState': fromState ? fromState.name : null
                    };
                };

                if (Auth.allowedStates.indexOf(state) > -1 || Auth.allowedTo(state)) {
                    var params = {notify: false};

                    // if the page is loaded for the first time, skip the initial state in browser history
                    if (!fromState.name) params.location = 'replace';

                    if (state === 'auth.support') state = 'app.documents';

                    if (Session.isLoggedIn() && Billing.inTrial() && Billing.trialOver()) {
                        state = 'app.settings.billing';
                    }

                    $state.go(state, toParams, params)
                        .then(function(newState) {
                            $rootScope.$broadcast('$stateChangeSuccess', newState, toParams, fromState, params);
                        });
                } else {
                    logger.error($filter('translate')('ROUTE_PERMISSIONS_ERROR'));

                    if (!fromState.name || fromState.name === 'auth.login') { // if the page is loaded for the first time, redirect to initial view
                        logger.warning($filter('translate')('REDIRECTING'));
                        $state.go('app.documents');
                    }
                }
            }
        });
    }

})();
