(function() {
    'use strict';

    angular
        .module('app.users')
        .controller('UsersBulkInviteController', UsersBulkInviteController);

    UsersBulkInviteController.$inject = ['$uibModalInstance', '$filter', 'Users', 'logger', 'SweetAlert'];

    /* @ngInject */
    function UsersBulkInviteController($uibModalInstance, $filter, Users, logger, SweetAlert) {
        var vm = this;

        vm.emails = [];
        vm.invalidEmails = [];
        vm.inviteError = null;
        vm.timeout = 300;

        vm.inviteConfirm = inviteConfirm;
        vm.invite = invite;
        vm.cancel = cancel;

        function inviteConfirm() {
            if (vm.invalidEmails.length) {
                SweetAlert.swal({
                    title: $filter('translate')('USERS.WARNING'),
                    text: $filter('translate')('USERS.INVALID_EMAILS_FOUND'),
                    type: 'warning',
                    showCancelButton: true,
                    cancelButtonText: $filter('translate')('USERS.CANCEL'),
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: $filter('translate')('USERS.INVITE_ANYWAY'),
                    closeOnConfirm: true
                }, function(isConfirm) {
                    if (isConfirm) invite();
                });
            } else {
                vm.invite();
            }
        }

        function invite() {
            return Users.invite(vm.emails.map(function(email) {
                return email.text;
            }))
                .then(success)
                .catch(fail);

            function success() {
                vm.inviteError = null;
                vm.emails = [];
                vm.invalidEmails = [];

                $uibModalInstance.close();
                logger.success($filter('translate')('USERS.USERS_INVITED'));
            }

            function fail(response) {
                vm.inviteError = response.data.error.message;
            }
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
