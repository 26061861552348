(function() {
    'use strict';

    angular
        .module('app.auth')
        .run(appRun);

    appRun.$inject = ['routerHelper'];

    /* @ngInject */
    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'auth',
                config: {
                    templateUrl: 'app/auth/auth.html',
                    settings: {nav: 0}
                }
            },
            {
                state: 'auth.login',
                parent: 'auth',
                config: {
                    url: '/login',
                    templateUrl: 'app/auth/login.html',
                    controller: 'AuthController',
                    controllerAs: 'vm',
                    title: 'Log in',
                    settings: {nav: 0}
                }
            },
            {
                state: 'auth.forgot',
                parent: 'auth',
                config: {
                    url: '/forgot',
                    templateUrl: 'app/auth/forgot.html',
                    controller: 'AuthController',
                    controllerAs: 'vm',
                    title: 'Forgot password',
                    settings: {nav: 0}
                }
            },
            {
                state: 'auth.setFull',
                parent: 'auth',
                config: {
                    url: '/set/:token/full',
                    templateUrl: 'app/auth/set_full.html',
                    controller: 'ResetController',
                    controllerAs: 'vm',
                    title: 'Setup account',
                    settings: {nav: 0}
                }
            },
            {
                state: 'auth.set',
                parent: 'auth',
                config: {
                    url: '/set/:token',
                    templateUrl: 'app/auth/set.html',
                    controller: 'ResetController',
                    controllerAs: 'vm',
                    title: 'Setup account',
                    settings: {nav: 0}
                }
            },
            {
                state: 'auth.reset',
                parent: 'auth',
                config: {
                    url: '/reset/:token',
                    templateUrl: 'app/auth/reset.html',
                    controller: 'ResetController',
                    controllerAs: 'vm',
                    title: 'Reset password',
                    settings: {nav: 0}
                }
            },
            {
                state: 'auth.emailSent',
                parent: 'auth',
                config: {
                    url: '/email-sent',
                    templateUrl: 'app/auth/email-sent.html',
                    controller: 'AuthController',
                    controllerAs: 'vm',
                    title: 'Email sent!',
                    settings: {nav: 0}
                }
            },
            {
                state: 'auth.support',
                parent: 'auth',
                config: {
                    url: '/token/:jwt',
                    templateUrl: 'app/auth/support.html',
                    title: 'Support user login',
                    settings: {nav: 0}
                }
            }
        ];
    }
})();
