(function() {
    'use strict';

    angular
        .module('app.templates')
        .service('Sources', Sources);

    Sources.$inject = ['$resource', 'globals'];

    /* @ngInject */
    function Sources($resource, globals) {
        var s = this;

        s.api = $resource(globals.apiUrl + '/sources/:id', {id: '@id'}, {update: {method: 'PUT'}});

        s.rows = $resource(globals.apiUrl + '/sources/:id/rows', {id: '@id'});

        s.transform = {
            rows: {
                forShow: transformRowsForShow,
                forSend: transformRowsForSend
            }
        };

        function transformRowsForShow(rows) {
            var array = [];

            for (var i = 0; i < rows.length; i++) {
                array.push([
                    rows[i].values.option,
                    rows[i].values.value
                ]);
            }

            return array;
        }

        function transformRowsForSend(rows) {
            if ((rows[rows.length - 1][0] === null || rows[rows.length - 1][0] == '') &&
                (rows[rows.length - 1][1] === null || rows[rows.length - 1][1] == '')) {
                rows.pop();
            }

            return rows;
        }
    }
})();
