(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('icon', icon);

    function icon() {
        return {
            restrict: 'E',
            replace: true,
            template: template
        };
    }

    function template(element, attrs) {
        return '<i class="icons ' + attrs.icon + '"></i>';
    }
})();
