(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('callMethod', callMethod);

    function callMethod() {
        return {
            restrict: 'A',
            link: function(scope, elem, attrs) {
                elem.bind('change', function() {
                    if (!scope.$$phase) {
                        scope.$apply(function() {
                            scope.$eval(attrs.callMethod);
                        });
                    }
                });
            }
        };
    }
})();
