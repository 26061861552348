(function() {
    'use strict';

    angular.module('app.settings', [
        'app.core',
        'app.layout',
        'app.customers',
        'app.documents'
    ]);
})();
