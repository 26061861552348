(function() {
    'use strict';

    angular
        .module('app.templates')
        .component('chooseType', {
            templateUrl: 'app/templates/choose-type/choose-type.html',
            bindings: {
                resolve: '<',
                close: '&',
                dismiss: '&'
            },
            controller: function() {
                var $ctrl = this;

                $ctrl.choose = function(type) {
                    $ctrl.close({$value: type});
                };

                $ctrl.cancel = function() {
                    $ctrl.dismiss({$value: 'cancel'});
                };
            }
        });
})();
