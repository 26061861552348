(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('focusMe', focusMe);

    focusMe.$inject = ['$timeout'];

    /* @ngInject */
    function focusMe($timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {focusMe: '='},
            link: function($scope, $element, $attrs, $ngModel) {
                if (typeof $scope.focusMe === 'undefined' || $scope.focusMe.enabled) {
                    if (!$element.is(':focus')) {
                        $timeout(function() {
                            $element[0].focus();
                        });
                    }
                    if (typeof $scope.focusMe === 'object' && $scope.focusMe.clearOn) {
                        $ngModel.$render = function() {
                            return true;
                        };
                    }
                }
            }
        };
    }
})();
