(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('editableTitle', editableTitle);

    function editableTitle() {
        return {
            link: function(scope, elem) {
                elem.bind('keyup', function() {
                    var length = elem.val().length;

                    elem.attr({size: length > 0 ? length + 2 : 15});
                });
                elem.bind('click', function() {
                    elem.select();
                });
            }
        };
    }
})();
