(function() {
    'use strict';

    angular
        .module('app.settings')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['$scope', '$rootScope', '$q', '$filter', '$timeout', '$state', 'Customers', 'Users', 'Tags', 'SweetAlert', 'Session', 'logger', 'listStorage'];

    /* @ngInject */
    function SettingsController($scope, $rootScope, $q, $filter, $timeout, $state, Customers, Users, Tags, SweetAlert, Session, logger, listStorage) {
        var timeout, deregister,
            vm = this;

        vm.permissions = Session.permissions;
        vm.state = $state;
        vm.progress = {mailSettings: false, tags: false, getMailSettings: false, groups: {get: false, load: false}};

        vm.mail = {};
        vm.mailError = {};
        vm.groups = [];
        vm.tags = [];

        vm.checkState = checkState;

        vm.getMail = getMail;
        vm.mailSave = mailSave;

        vm.getGroups = getGroups;
        vm.createGroup = createGroup;
        vm.createGroupConfirm = createGroupConfirm;
        vm.updateGroup = updateGroup;
        vm.updateGroupConfirm = updateGroupConfirm;
        vm.deleteGroup = deleteGroup;
        vm.deleteGroupConfirm = deleteGroupConfirm;

        vm.deleteTag = deleteTag;
        vm.deleteTagConfirm = deleteTagConfirm;

        activate();

        function activate() {
            checkState($state.current.name);
            deregister = $rootScope.$on('$stateChangeStart', function(e, to) {
                checkState(to.name);
            });
        }

        function checkState(state) {
            var promises = [];

            if (state == 'app.settings') {
                $state.go('app.settings.account');
            } else if (state == 'app.settings.mail') {
                promises = [getMail()];
            } else if (state == 'app.settings.tags') {
                if (!Tags.list.length) {
                    vm.progress.tags = true;

                    if (Tags.get()) {
                        $rootScope.$on('gotTags', function() {
                            vm.tags = Tags.list;
                            vm.progress.tags = false;
                        });
                    } else {
                        vm.progress.tags = false;
                    }
                } else {
                    vm.tags = Tags.list;
                }
            } else if (state == 'app.settings.groups') {
                promises = [getGroups()];
            }

            if (promises.length) return $q.all(promises);
        }

        function getMail() {
            vm.progress.getMailSettings = true;

            return Customers.api.get({include: 'settings'}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.mail = response.data.settings.data.smtp;

                vm.progress.getMailSettings = false;
            }

            function fail(response) {
                logger.error(response.data);

                vm.progress.getMailSettings = false;
            }
        }

        function mailSave() {
            logger.saving();

            vm.mailError = {};

            timeout = $timeout(function() {
                vm.progress.mailSettings = true;
            }, 2000);

            return Customers.api.update({smtp: vm.mail}).$promise
                .then(success)
                .catch(fail);

            function success() {
                $timeout.cancel(timeout);
                vm.progress.mailSettings = false;

                logger.clear();
                logger.success($filter('translate')('SETTINGS.SAVED'));
            }

            function fail(response) {
                $timeout.cancel(timeout);
                vm.progress.mailSettings = false;

                logger.clear();
                vm.mailError.message = response.data.error.message;
            }
        }

        function getGroups(cursor) {
            if (!cursor) vm.groups = [];

            vm.progress.groups.get = true;

            var params = {
                current: cursor,
                number: 200
            };

            return Users.groups.get(params).$promise
                .then(success)
                .catch(fail);


            function success(response) {
                vm.groups = vm.groups.concat(response.data);

                if (response.meta.cursor.next) {
                    vm.getGroups(response.meta.cursor.next);
                } else {
                    vm.progress.groups.get = false;
                }
            }

            function fail(response) {
                logger.error(response.data);

                vm.progress.groups.get = false;
            }
        }

        function createGroup() {
            SweetAlert.swal({
                title: $filter('translate')('GROUPS.CREATE_GROUP'),
                text: $filter('translate')('GROUPS.WRITE_GROUP_NAME'),
                type: 'input',
                showCancelButton: true,
                confirmButtonText: $filter('translate')('GROUPS.CREATE'),
                closeOnConfirm: true
            }, function(name) {
                if (!name) return false;

                createGroupConfirm(name);
            });
        }

        function createGroupConfirm(name) {
            vm.progress.groups.load = true;

            return Users.groups.save({name: name}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.groups.unshift(response.data);

                logger.success($filter('translate')('GROUPS.SAVED'));

                vm.progress.groups.load = false;
            }

            function fail(response) {
                logger.error(response.data);

                vm.progress.groups.load = false;
            }
        }

        function updateGroup(group) {
            SweetAlert.swal({
                title: $filter('translate')('GROUPS.UPDATE_GROUP'),
                text: $filter('translate')('GROUPS.WRITE_GROUP_NAME'),
                type: 'input',
                inputValue: group.name,
                showCancelButton: true,
                confirmButtonText: $filter('translate')('GROUPS.UPDATE'),
                closeOnConfirm: true
            }, function(name) {
                if (!name) return false;

                updateGroupConfirm(group, name);
            });
        }

        function updateGroupConfirm(group, name) {
            vm.progress.groups.load = true;

            return Users.groups.update({id: group.id, name: name}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.groups[vm.groups.indexOf(group)].name = response.data.name;

                logger.success($filter('translate')('GROUPS.SAVED'));

                vm.progress.groups.load = false;
            }

            function fail(response) {
                logger.error(response.data);

                vm.progress.groups.load = false;
            }
        }

        function deleteGroup(group) {
            SweetAlert.swal({
                title: $filter('translate')('GROUPS.ARE_YOU_SURE'),
                text: $filter('translate')('GROUPS.ABOUT_TO_DELETE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('GROUPS.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteGroupConfirm(group);
                }
            });
        }

        function deleteGroupConfirm(group) {
            vm.progress.groups.load = true;

            return Users.groups.delete({id: group.id}).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.groups.splice(vm.groups.indexOf(group), 1);

                logger.success($filter('translate')('GROUPS.DELETED'));

                vm.progress.groups.load = false;
            }

            function fail(response) {
                logger.error(response.data);

                vm.progress.groups.load = false;
            }
        }

        function deleteTag(tag) {
            SweetAlert.swal({
                title: $filter('translate')('SETTINGS.ARE_YOU_SURE'),
                text: $filter('translate')('SETTINGS.ABOUT_TO_DELETE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('SETTINGS.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteTagConfirm(tag);
                }
            });
        }

        function deleteTagConfirm(tag) {
            return Tags.api.delete({id: tag.id}).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.tags.splice(vm.tags.indexOf(tag), 1);

                clearTagsFilter(tag);

                logger.success($filter('translate')('SETTINGS.DELETED'));
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function clearTagsFilter(tag) {
            var filter = listStorage.getItem('eezeDocumentsListSearch');

            if (filter) {
                filter.tags.splice(filter.tags.indexOf(tag), 1);
                listStorage.setItem('eezeDocumentsListSearch', filter);
            }
        }

        $scope.$on('$destroy', deregister);
    }
})();
