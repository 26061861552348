(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('chooseTemplate', chooseTemplate);

    function chooseTemplate() {
        return {
            restrict: 'A',
            scope: {action: '&'},
            controller: 'ChooseTemplateController',
            controllerAs: 'vm',
            bindToController: true
        };
    }
})();
