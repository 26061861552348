(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('ListFillController', ListFillController);

    ListFillController.$inject = ['$scope', '$rootScope', '$filter', '$q', '$state', '$stateParams', '$locale', '$timeout', '$anchorScroll', '$uibPosition', '$window', 'Session', 'Fields', 'Documents', 'Images', 'Files', 'logger', 'globals', 'pdf', 'msgBus', 'SweetAlert', 'urlSigner', 'eezeDatepickerHelper'];

    /* @ngInject */
    function ListFillController($scope, $rootScope, $filter, $q, $state, $stateParams, $locale, $timeout, $anchorScroll, $uibPosition, $window, Session, Fields, Documents, Images, Files, logger, globals, pdf, msgBus, SweetAlert, urlSigner, eezeDatepickerHelper) {
        var popover, position,
            documentId = $stateParams.id,
            valueRequests = [],
            vm = this;

        vm.jwt = Session.jwt;
        vm.can = Session.can;
        vm.permissions = Session.permissions;
        vm.locale = $locale;
        vm.apiUrl = globals.apiUrl;

        vm.defaults = {
            popover: {width: 300},
            datepicker: {
                showWeeks: false,
                closeText: $filter('translate')('DOCUMENTS.CLOSE'),
                clearText: $filter('translate')('DOCUMENTS.CLEAR'),
                currentText: $filter('translate')('DOCUMENTS.TODAY'),
                fieldFormat: vm.locale.id == 'en-us' ? 'M/d/y' : 'dd.MM.y'
            }
        };

        vm.progress = {getDocument: false, saveDocument: false, getSections: false, loadingSectionField: false};
        vm.timeout = {short: 300, medium: 500};
        vm.calendar = {}; // for showing and hiding datepicker calendar

        vm.document = {};
        vm.showDocumentErrors = false;
        vm.template = {};
        vm.fields = [];
        vm.sections = [];
        vm.values = {};
        vm.dbValues = {};
        vm.valuesCache = {};
        vm.required = [];
        vm.selectedRow = {};
        vm.selectedTable = {};

        vm.getDocument = getDocument;
        vm.getFields = getFields;
        vm.getSections = getSections;
        vm.moreFields = moreFields;
        vm.editDocument = editDocument;
        vm.editValues = editValues;
        vm.duplicateDocument = duplicateDocument;
        vm.selectTableRow = selectTableRow;
        vm.selectTable = selectTable;
        vm.addRow = addRow;
        vm.deleteRow = deleteRow;
        vm.setDocumentStatus = setDocumentStatus;
        vm.validateDocument = validateDocument;
        vm.constructErrors = constructErrors;
        vm.openErroredTableRows = openErroredTableRows;
        vm.doSignature = doSignature;
        vm.clearSignature = clearSignature;
        vm.doSaveTime = doSaveTime;
        vm.assignUser = assignUser;
        vm.showTagPopover = showTagPopover;
        vm.checkAutoFocus = checkAutoFocus;
        vm.selectRow = selectRow;
        vm.clearSelect = clearSelect;
        vm.changeState = changeState;
        vm.archiveDocument = archiveDocument;
        vm.deleteDocument = deleteDocument;
        vm.deleteDocumentConfirm = deleteDocumentConfirm;
        vm.showDatepicker = showDatepicker;
        vm.scrollTo = scrollTo;
        vm.uploadImage = uploadImage;
        vm.deleteImage = deleteImage;
        vm.showImage = showImage;
        vm.uploadFile = uploadFile;
        vm.showFile = showFile;
        vm.deleteFile = deleteFile;

        vm.print = pdf.print;
        vm.download = pdf.download;

        activate();

        function activate() {
            if ($rootScope.$$listeners['fieldUpdate']) $rootScope.$$listeners['fieldUpdate'] = [];
            $rootScope.$on('fieldUpdate', function(event, data) {
                vm.values[data.field]
                    ? vm.values[data.field].value = data.value
                    : vm.values[data.field] = {id: data.field, value: data.value};

                vm.doSaveTime();
            });

            $anchorScroll.yOffset = 65;

            var promises = [getDocument()];

            return $q.all(promises)
                .then(success);

            function success() {
                msgBus.emit('breadcrumbs.setObject', {
                    object: vm.document,
                    meta: {new: $stateParams.new}
                });

                msgBus.on('breadcrumbs.action', function(event, data) {
                    vm.editDocument('name', {'name': data.name});
                }, $scope);
            }
        }

        function getDocument() {
            vm.progress.getDocument = true;

            return Documents.api.get({id: documentId, include: 'values,tags,settings'}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.document = response.data;

                if (!angular.isArray(vm.document.values.data)) {
                    vm.values = vm.document.values.data;
                    vm.dbValues = angular.copy(vm.document.values.data);
                }

                vm.getFields();
                vm.getSections();
                vm.checkAutoFocus();
            }

            function fail(response) {
                vm.progress.getDocument = false;
                logger.error(response.data);

                if (response.status == 403 || response.status == 404) {
                    $state.go('app.documentsError');
                }
            }
        }

        function getFields() {
            var params = {
                documentId: vm.document.id,
                exclude: 'hidden',
                include: 'files,images,list',
                orderBy: 'position',
                order: 'asc',
                number: 20
            };

            return Fields.document.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.fields = Fields.transform.forList.forPopulate(response.data);
                vm.fieldsMeta = response.meta;

                Fields.transform.forFill.forPopulate(vm.fields, vm.values);

                vm.progress.getDocument = false;
            }

            function fail(response) {
                logger.error(response.data);
                vm.progress.getDocument = false;
            }
        }

        function getSections() {
            vm.progress.getSections = true;

            var params = {
                documentId: vm.document.id,
                type: 'separator',
                orderBy: 'position',
                order: 'asc',
                number: 200
            };

            return Fields.document.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.sections = Fields.transform.forList.forPopulate(response.data);

                vm.progress.getSections = false;
            }

            function fail(response) {
                logger.error(response.data);

                vm.progress.getSections = false;
            }
        }

        function moreFields() {
            if (vm.fields.length && vm.fieldsMeta.cursor.next && !vm.progress.getDocument) {
                vm.progress.getDocument = true;

                var params = {
                    documentId: vm.document.id,
                    exclude: 'hidden',
                    include: 'images,list',
                    orderBy: 'position',
                    order: 'asc',
                    current: vm.fieldsMeta.cursor.next,
                    prev: vm.fieldsMeta.cursor.current
                };

                return Fields.document.get(params).$promise
                    .then(success);
            }

            function success(response) {
                var newFields = Fields.transform.forList.forPopulate(response.data);

                Fields.transform.forFill.forPopulate(newFields, vm.values);

                vm.fields = vm.fields.concat(newFields);
                vm.fieldsMeta = response.meta;

                vm.progress.getDocument = false;
            }
        }

        function editDocument(input, args) {
            if (input != 'status' && input != 'assign' && (vm.document.status == 4 || vm.document.status == 6)) {
                logger.error($filter('translate')('DOCUMENTS.COMPLETED_CANT_MODIFY'));
                return false;
            }

            vm.progress.saveDocument = true;

            logger.saving();

            var params = {
                id: vm.document.id,
                include: 'tags,settings'
            };

            if (input == 'name') params.name = args.name;
            if (input == 'assign') params.assignedTo = args.userId || 0;
            if (input == 'status') params.status = args.status;

            return Documents.api.update(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.document = response.data;

                vm.progress.saveDocument = false;

                vm.doSaveTime();

                logger.clear();
                logger.success($filter('translate')('DOCUMENTS.SAVED'));

                if (input == 'assign' &&
                    ((Session.group && Session.group != args.groupId) ||
                    (!Session.permissions.documents['view.all'] && Session.id != args.userId))
                ) {
                    $state.go('app.documents');
                }

                return true;
            }

            function fail(response) {
                vm.progress.saveDocument = false;

                logger.clear();
                logger.error(response.data);

                return false;
            }
        }

        function editValues(field) {
            if (vm.document.status == 4 || vm.document.status == 6) {
                logger.error($filter('translate')('DOCUMENTS.COMPLETED_CANT_MODIFY'));
                return false;
            }

            if (angular.equals(vm.values, vm.valuesCache)) return false;

            if (angular.isDefined(field)) {
                var fieldId = angular.copy(field.id);
            } else {
                return false;
            }

            if (angular.isDefined(vm.listForm[fieldId]) && vm.listForm[fieldId].$validate() && vm.listForm[fieldId].$invalid) return false;

            vm.progress.saveDocument = true;

            logger.saving();

            var params = {
                id: vm.document.id,
                values: {},
                include: 'values,tags'
            };

            var value = vm.values[fieldId].value,
                option = vm.values[fieldId].option;

            if (!value && typeof value !== 'number') {
                value = null;
            }

            params.values[fieldId] = {
                id: fieldId,
                value: value,
                option: option
            };

            if (valueRequests[fieldId]) valueRequests[fieldId].$cancelRequest();
            valueRequests[fieldId] = Documents.api.update(params);

            return valueRequests[fieldId].$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.document = response.data;
                vm.valuesCache = angular.copy(vm.values);
                vm.dbValues = angular.copy(response.data.values.data);

                msgBus.emit('breadcrumbs.setObject', {
                    object: vm.document,
                    meta: {new: false}
                });

                updateLocalValuesFromServer();

                vm.calendar = {};
                vm.progress.saveDocument = false;

                logger.clear();
                vm.doSaveTime();
            }

            function fail(response) {
                vm.progress.saveDocument = false;

                logger.clear();
                logger.error(response.data);
            }
        }

        function duplicateDocument(document) {
            logger.loading();

            var params = {
                id: document.id,
                default: {time: new Date().getHours() * 3600 + new Date().getMinutes() * 60}
            };

            return Documents.duplicate(params)
                .then(success)
                .catch(fail);

            function success(response) {
                logger.clear();
                logger.success($filter('translate')('DOCUMENTS.SAVED'));

                $state.go('filler.list', {id: response.data.data.id, duplicate: true});
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function updateLocalValuesFromServer() {
            for (var key in vm.dbValues) {
                vm.values[vm.dbValues[key].id] = {
                    id: key,
                    value: vm.dbValues[key].value,
                    type: vm.dbValues[key].type
                };

                if (vm.dbValues[key].option) {
                    vm.values[vm.dbValues[key].id].option = vm.dbValues[key].option;
                }
            }

            Fields.transform.forFill.forPopulate(vm.fields, vm.values);
        }

        function selectTable(table) {
            if (!vm.selectedTable.hasOwnProperty(table)) vm.selectedTable[table] = false;

            vm.selectedTable[table] = !vm.selectedTable[table];
        }

        function selectTableRow(table, row) {
            if (!vm.selectedRow.hasOwnProperty(table)) vm.selectedRow[table] = {};
            if (!vm.selectedRow[table].hasOwnProperty(row)) vm.selectedRow[table][row] = false;

            vm.selectedRow[table][row] = !vm.selectedRow[table][row];
        }

        function addRow(table) {
            vm.progress.saveDocument = true;

            logger.saving();

            var params = {
                id: table.id,
                default: {time: new Date().getHours() * 3600 + new Date().getMinutes() * 60},
                exclude: 'hidden'
            };

            return Fields.tables.update(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                table.options.rows.push(Fields.transform.forList.forPopulate(response.data));
                vm.dbValues = angular.copy(response.meta.values);

                updateLocalValuesFromServer();

                vm.selectedRow[table.id] = {};
                vm.selectedRow[table.id][table.options.rows.length - 1] = true;

                vm.progress.saveDocument = false;

                logger.clear();
            }

            function fail(response) {
                vm.progress.saveDocument = false;

                logger.clear();
                logger.error(response.data);
            }
        }

        function deleteRow(table, row) {
            vm.progress.saveDocument = true;

            logger.saving();

            for (var j = 0; j < table.options.rows[row].length; j++) {
                vm.values[table.options.rows[row][j].id] = {
                    id: table.options.rows[row][j].id,
                    value: ''
                };
            }

            return Fields.rows.delete({tableId: table.id, rowId: row}).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.fields = Fields.transform.forFill.removeTableRows(vm.fields, table.id, row);
                vm.dbValues = angular.copy(response.meta.values);

                updateLocalValuesFromServer();

                // adjust opened rows
                var rows = Object.keys(vm.selectedRow[table.id]);

                for (var i = 0; i < rows.length; i++) {
                    if (rows[i] == row) {
                        // close the row that was deleted
                        vm.selectedRow[table.id][rows[i]] = false;
                    } else if (i == rows.length - 1) {
                        // adjust the row before the last and close the last row so it won't magically open when a new row is added
                        vm.selectedRow[table.id][rows[i] - 1] = vm.selectedRow[table.id][rows[i]];
                        vm.selectedRow[table.id][rows[i]] = false;
                    } else if (rows[i] > row) {
                        // adjust the other rows
                        vm.selectedRow[table.id][rows[i] - 1] = vm.selectedRow[table.id][rows[i]];
                    }
                }

                vm.progress.saveDocument = false;

                logger.clear();
            }

            function fail(response) {
                vm.progress.saveDocument = false;

                logger.clear();
                logger.error(response.data);
            }
        }

        function setDocumentStatus(status) {
            // Status 4 = Complete
            if (status === 4) {
                vm.validateDocument(status);
            } else {
                vm.showDocumentErrors = false;
                vm.editDocument('status', {status: status});
            }
        }

        function validateDocument(status) {
            return Documents.validateRequiredFields({id: vm.document.id})
                    .then(success)
                    .catch(fail);

            function success(response) {
                vm.required = response.data.data;

                if (vm.required.length) {
                    vm.showDocumentErrors = true;
                    vm.constructErrors();
                } else if (vm.listForm.$invalid) {
                    vm.showDocumentErrors = true;
                    logger.error($filter('translate')('DOCUMENTS.DOCUMENT_ERROR'));
                } else {
                    vm.showDocumentErrors = false;
                    vm.editDocument('status', {status: status});
                }
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function constructErrors() {
            var error = Fields.transform.forFill.constructErrorList(vm.required);

            vm.openErroredTableRows(error.fields);
            logger.error(error.string);
        }

        function openErroredTableRows(fields) {
            vm.selectedRow = {};

            for (var i = 0; i < fields.length; i++) {
                var table = fields[i].options.data.table;

                if (table) {
                    if (!vm.selectedRow.hasOwnProperty(table)) vm.selectedRow[table] = {};

                    vm.selectedRow[table][fields[i].options.data.row] = true;
                }
            }
        }

        function doSignature(id) {
            if (vm.document.status == 4 || vm.document.status == 6) return false;

            $state.go('filler.list.signature', {fieldId: id});
        }

        function clearSignature(field) {
            vm.values[field.id].value = '';
            vm.editValues(field);
        }

        function doSaveTime() {
            vm.document.updated = new Date();

            msgBus.emit('breadcrumbs.setObject', {object: vm.document});

        }

        function assignUser(userId, groupId) {
            vm.editDocument('assign', {userId: userId, groupId: groupId});
        }

        function showTagPopover(event) {
            popover = angular.element('#tags-popover');
            position = $uibPosition.offset(
                event.target.parentElement.localName == 'button'
                    ? event.target.parentElement : event.target
            );

            popover.css({
                left: position.left + 20,
                top: position.top - 20,
                right: 'auto',
                bottom: 'auto'
            });

            popover.removeClass('left right top bottom');
            popover.addClass('right');
        }

        function checkAutoFocus() {
            if ($stateParams.new || $stateParams.duplicate) msgBus.emit('breadcrumbs.focus');
        }

        function selectRow(row, field) {
            if (!vm.values[field.id]) vm.values[field.id] = {};

            vm.values[field.id].option = row.values.option;
            vm.values[field.id].value = row.values.value || row.values.option;

            vm.editValues(field);
        }

        function clearSelect(field) {
            if (!vm.values[field.id]) vm.values[field.id] = {};

            vm.values[field.id].option = null;
            vm.values[field.id].value = null;

            vm.editValues(field);
        }

        function changeState(state) {
            if (state != $state.current.name) {
                localStorage.setItem('eezeFillerView', state);

                $state.go(state, {new: false});
            }
        }

        function archiveDocument(id) {
            return vm.editDocument('status', {id: id, status: 6})
                .then(function(success) {
                    if (success) $state.go('app.documents');
                    return success;
                });
        }

        function deleteDocument(id) {
            SweetAlert.swal({
                title: $filter('translate')('DOCUMENTS.ARE_YOU_SURE'),
                text: $filter('translate')('DOCUMENTS.ABOUT_TO_DELETE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('DOCUMENTS.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteDocumentConfirm(id);
                }
            });
        }

        function deleteDocumentConfirm(id) {
            logger.loading();

            return Documents.api.delete({id: id}).$promise
                .then(success)
                .catch(fail);

            function success() {
                $state.go('app.documents');

                logger.clear();
                logger.success($filter('translate')('DOCUMENTS.DELETED'));
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function showDatepicker(id) {
            vm.calendar = {};
            vm.calendar[id] = {opened: true};

            eezeDatepickerHelper.invoker = null;
        }

        function uploadImage(fieldId, tableId, rowIndex) {
            var fieldIndex,
                images;

            if (tableId) {
                var tableIndex = _.findIndex(vm.fields, {id: tableId});

                fieldIndex = _.findIndex(vm.fields[tableIndex].options.rows[rowIndex], {id: fieldId});

                if (!vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].images) {
                    vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].images = [];
                }

                images = vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].images;
            } else {
                fieldIndex = _.findIndex(vm.fields, {id: fieldId});
                images = vm.fields[fieldIndex].images;
            }

            vm.progress.saveDocument = true;

            images.push({});

            angular.element('.image-' + fieldId).fileinput('clear'); // clear the input so a file with the same name can be uploaded again

            return Images.upload(vm.newFile, vm.document.id, fieldId)
                .then(success)
                .catch(fail);

            function success(response) {
                images.splice(images.length - 1, 1, response.data.data);

                vm.progress.saveDocument = false;
            }

            function fail(response) {
                images.splice(images.length - 1, 1);

                logger.error(response.data);

                vm.progress.saveDocument = false;
            }
        }

        function deleteImage(fieldId, imageId, tableId, rowId) {
            SweetAlert.swal({
                title: $filter('translate')('DOCUMENTS.ARE_YOU_SURE'),
                text: $filter('translate')('DOCUMENTS.ABOUT_TO_DELETE_IMAGE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('DOCUMENTS.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteImageConfirm(fieldId, imageId, tableId, rowId);
                }
            });
        }

        function deleteImageConfirm(fieldId, imageId, tableId, rowIndex) {
            return Images.remove(vm.document.id, fieldId, imageId)
                .then(success)
                .catch(fail);

            function success() {
                var fieldIndex;

                if (tableId) {
                    var tableIndex = _.findIndex(vm.fields, {id: tableId});

                    fieldIndex = _.findIndex(vm.fields[tableIndex].options.rows[rowIndex], {id: fieldId});
                    vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].images.splice(_.findIndex(vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].images, {id: imageId}), 1);
                } else {
                    fieldIndex = _.findIndex(vm.fields, {id: fieldId});

                    vm.fields[fieldIndex].images.splice(_.findIndex(vm.fields[fieldIndex].images, {id: imageId}), 1);
                }
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function showImage(fieldId, imageId) {
            var win = $window.open('', '_blank');

            var url = '/documents/' + vm.document.id + '/fields/' + fieldId + '/images/' + imageId;

            return urlSigner.sign(url)
                .then(success)
                .catch(fail);

            function success(response) {
                if (win) {
                    win.location = response.data.data.signedUrl;
                    win.focus();
                }
            }

            function fail(response) {
                logger.error(response.data);
                if (win) {
                    win.close();
                }
            }
        }

        function uploadFile(fieldId, tableId, rowIndex) {
            var fieldIndex,
                files;

            if (tableId) {
                var tableIndex = _.findIndex(vm.fields, {id: tableId});

                fieldIndex = _.findIndex(vm.fields[tableIndex].options.rows[rowIndex], {id: fieldId});

                if (!vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].images) {
                    vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].images = [];
                }

                files = vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].files;
            } else {
                fieldIndex = _.findIndex(vm.fields, {id: fieldId});
                files = vm.fields[fieldIndex].files;
            }

            vm.progress.saveDocument = true;

            files.push({});

            angular.element('.file-' + fieldId).fileinput('clear'); // clear the input so a file with the same name can be uploaded again

            return Files.upload(vm.newFile, vm.document.id, fieldId)
                .then(success)
                .catch(fail);

            function success(response) {
                files.splice(files.length - 1, 1, response.data.data);

                vm.progress.saveDocument = false;
            }

            function fail(response) {
                files.splice(files.length - 1, 1);

                logger.error(response.data);

                vm.progress.saveDocument = false;
            }
        }

        function showFile(fieldId, fileId) {
            var url = '/documents/' + vm.document.id + '/fields/' + fieldId + '/files/' + fileId;

            pdf.fileDownload(url);
        }

        function deleteFile(fieldId, fileId, tableId, rowId) {
            SweetAlert.swal({
                title: $filter('translate')('DOCUMENTS.ARE_YOU_SURE'),
                text: $filter('translate')('DOCUMENTS.ABOUT_TO_DELETE_FILE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('DOCUMENTS.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteFileConfirm(fieldId, fileId, tableId, rowId);
                }
            });
        }

        function deleteFileConfirm(fieldId, fileId, tableId, rowIndex) {
            return Files.remove(vm.document.id, fieldId, fileId)
                .then(success)
                .catch(fail);

            function success() {
                var fieldIndex;

                if (tableId) {
                    var tableIndex = _.findIndex(vm.fields, {id: tableId});

                    fieldIndex = _.findIndex(vm.fields[tableIndex].options.rows[rowIndex], {id: fieldId});
                    vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].files.splice(_.findIndex(vm.fields[tableIndex].options.rows[rowIndex][fieldIndex].files, {id: fileId}), 1);
                } else {
                    fieldIndex = _.findIndex(vm.fields, {id: fieldId});

                    vm.fields[fieldIndex].files.splice(_.findIndex(vm.fields[fieldIndex].files, {id: fileId}), 1);
                }
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function scrollTo(sectionId) {
            var fieldLoaded = _.find(vm.fields, {id: sectionId});

            if (fieldLoaded) {
                $timeout(function() {
                    $anchorScroll((sectionId).toString());
                });

                return true;
            }

            vm.progress.loadingSectionField = true;

            vm.moreFields()
                .then(function() {
                    scrollTo(sectionId);

                    vm.progress.loadingSectionField = false;
                });
        }
    }
})();
