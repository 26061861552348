(function() {
    'use strict';

    angular
        .module('app.reports')
        .directive('columnPopover', columnPopover);

    function columnPopover() {
        return {
            restrict: 'E',
            templateUrl: 'app/reports/report/columnpopover/popover.html'
        };
    }
})();
