(function() {
    'use strict';

    angular.module('app', [
        'app.core',
        'app.layout',
        'app.common',
        'app.auth',
        'app.settings',
        'app.account',
        'app.documents',
        'app.templates',
        'app.users',
        'app.reports'
    ]);
})();
