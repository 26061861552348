(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('topNav', topNav);

    function topNav() {
        return {
            restrict: 'EA',
            templateUrl: 'app/layout/top-nav.html',
            bindToController: true,
            controller: 'TopNavController',
            controllerAs: 'vm',
            scope: {}
        };
    }
})();
