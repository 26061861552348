(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('selectModal', selectModal);

    function selectModal() {
        return {
            restrict: 'A',
            scope: {
                source: '@',
                action: '&',
                clearaction: '&',
                preventZoom: '@'
            },
            controller: 'SelectModalController',
            controllerAs: 'vm',
            bindToController: true
        };
    }
})();
