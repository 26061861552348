(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('eezePopover', eezePopover);

    eezePopover.$inject = ['$document', 'Session'];

    /* @ngInject */
    function eezePopover($document, Session) {
        return {
            compile: function(elem, attrs) {
                elem.data('eezePopover', true);

                if (Session.popoverTogglers.indexOf(attrs.eezePopover) == -1) {
                    Session.popoverTogglers.push(attrs.eezePopover);

                    angular.element($document[0].body).on(document.ontouchend !== null ? 'click' : 'touchend', function(e) {
                        var element = elem[0].id,
                            parentElement = e.target.parentElement,
                            parentLocalName = parentElement
                                ? (parentElement.parentElement ? parentElement.parentElement.localName : '') : '',
                            classes = e.target.className.split(' ')
                                .concat(parentElement ? parentElement.className.split(' ') : []),
                            toggler = classes[classes.indexOf(attrs.eezePopover)] || null,
                            target = angular.element(e.target),
                            $element = angular.element('#' + element);

                        if (toggler) {
                            $element.toggleClass('hidden');
                        } else if (
                            !target.inheritedData('eezePopover') && !angular.element('body').hasClass('modal-open') &&
                            classes.indexOf('suggestion-item') == -1 &&
                            classes.indexOf('ti-autoresize-input') == -1 &&
                            classes.indexOf('tags-popover-opener') == -1 &&
                            classes.indexOf('date-popover-opener') == -1 &&
                            classes.indexOf('time-popover-opener') == -1 &&
                            classes.indexOf('remove-button') == -1 &&
                            classes.indexOf('sa-button-container') == -1 &&
                            classes.indexOf('sa-confirm-button-container') == -1 &&
                            classes.indexOf('page-switcher-item') == -1 &&
                            parentLocalName != 'forms-ti-autocomplete-match' &&
                            parentLocalName != 'ti-autocomplete-match'
                        ) {
                            $element.addClass('hidden');
                        }
                    });
                }
            }
        };
    }
})();
