(function() {
    'use strict';

    angular
        .module('app.core')
        .service('Version', Version);

    Version.$inject = ['$rootScope', 'Session'];

    /* @ngInject */
    function Version($rootScope, Session) {

        var s = this;

        s.ui = null;
        s.checkUiVersion = checkUiVersion;

        function checkUiVersion(uiVersion) {
            setUiVersion();

            if (s.ui && uiVersion && s.ui !== uiVersion) {
                $rootScope.$broadcast('persistent-notification.show', {
                    message: 'PERSISTENT_NOTIFICATION.VERSION_UPDATE',
                    action: '<a onclick="window.location.reload()" translate>PERSISTENT_NOTIFICATION.REFRESH</a>'
                });
            }
        }

        function setUiVersion() {
            if (Session.data && Session.data.version && Session.data.version.ui && s.ui === null) {
                s.ui = Session.data.version.ui;
            }
        }
    }
})();
