(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$state', 'routerHelper', 'Session'];

    /* @ngInject */
    function SidebarController($state, routerHelper, Session) {
        var routes, routeName,
            states = routerHelper.getStates(),
            vm = this;

        vm.state = $state;
        vm.permissions = Session.permissions;

        vm.settings = {
            toggled: false,
            states: [
                'app.settings.account',
                'app.settings.mail',
                'app.settings.tags',
                'app.settings.groups',
                'app.settings.users'
            ]
        };

        vm.navRoutes = [];
        vm.getNavRoutes = getNavRoutes;

        activate();

        function activate() {
            getNavRoutes();
        }

        function getNavRoutes() {
            routes = states.filter(function(r) {
                return r.settings && r.settings.nav;
            }).sort(function(r1, r2) {
                return r1.settings.nav - r2.settings.nav;
            });

            for (var i = 0; i < routes.length; i++) {
                routeName = routes[i].name.split('.')[1];

                // check if the user is allowed to view the route
                if (Session.permissions[routeName]) {
                    if (Session.permissions[routeName].view) {
                        vm.navRoutes.push(routes[i]);
                    }
                }
            }
        }
    }
})();
