(function() {
    'use strict';

    angular
        .module('app.documents')
        .run(appRun);

    appRun.$inject = ['$filter', 'routerHelper'];

    /* @ngInject */
    function appRun($filter, routerHelper) {
        routerHelper.configureStates(getStates());

        function getStates() {
            return [
                {
                    state: 'app.documents',
                    parent: 'app',
                    config: {
                        url: '/',
                        templateUrl: 'app/documents/documents.html',
                        controller: 'DocumentsController',
                        controllerAs: 'vm',
                        title: $filter('translate')('NAV.DOCUMENTS'),
                        settings: {
                            nav: 1,
                            icon: 'icon-documents',
                            label: 'NAV.DOCUMENTS',
                            breadcrumbs: {self: true}
                        }
                    }
                }
            ];
        }
    }
})();
