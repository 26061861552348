(function() {
    'use strict';

    angular
        .module('app.common')
        .service('urlSigner', urlSigner);

    urlSigner.$inject = ['$http', 'globals'];

    /* @ngInject */
    function urlSigner($http, globals) {
        var s = this;

        s.sign = sign;

        function sign(url) {
            return $http({
                url: globals.apiUrl + '/sign',
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                data: {url: url || null}
            });
        }
    }
})();
