(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('persistentNotification', persistentNotification);

    persistentNotification.$inject = ['$rootScope', '$filter'];

    /* @ngInject */
    function persistentNotification($rootScope, $filter) {
        return {
            restrict: 'E',
            templateUrl: 'app/common/persistentNotification/persistent-notification.html',
            controller: controller,
            controllerAs: 'vm',
            bindToController: true
        };

        function controller($scope) {

            var vm = this;

            vm.messages = [];
            vm.closedMessages = [];

            vm.show = show;
            vm.close = close;
            vm.add = add;

            activate();

            function activate() {
                $scope.$on('persistent-notification.show', show);
                $scope.$on('persistent-notification.close', close);
                toggleNotificationElement();
            }

            function show(event, data) {
                var message = $filter('translate')(data.message),
                    important = data.important,
                    action = data.action;

                add({text: message, important: important, action: action});
                toggleNotificationElement();
            }

            function close() {
                vm.closedMessages = angular.copy(vm.messages);
                vm.messages = [];
                toggleNotificationElement();
            }

            function add(message) {
                function exists(item) {
                    return item['text'] === message.text;
                }

                if (vm.messages.some(exists) || vm.closedMessages.some(exists)) return;
                vm.messages.push(message);
            }

            function toggleNotificationElement() {
                $rootScope.hasPersistentNotification = vm.messages.length > 0;
            }

        }
    }
})();
