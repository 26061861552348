(function() {
    'use strict';

    angular
        .module('app.templates')
        .directive('juiDroppable', juiDroppable);

    function juiDroppable() {
        return {
            restrict: 'A',
            link: link,
            scope: {
                juiDroppable: '@',
                activate: '&onActivate',
                deactivate: '&onDeactivate',
                create: '&onCreate',
                drop: '&onDrop',
                over: '&onOver',
                out: '&onOut',
                juiAccept: '@',
                juiAcceptCheck: '&'
            }
        };

        function link(scope, elem) {

            if (scope.juiDroppable == 'false') return;

            elem.droppable({
                accept: scope.juiAccept ? scope.juiAccept : acceptCheck,
                greedy: true,
                hoverClass: 'drag-over',
                activeClass: 'drag-started',
                tolerance: 'pointer'
            });

            function acceptCheck(draggable) {
                return scope.juiAcceptCheck({draggable: angular.element(draggable[0]).scope()});
            }

            elem.on('dropactivate', function() {
                if (scope.activate) {
                    scope.activate();
                }
            });

            elem.on('dropdeactivate', function() {
                if (scope.deactivate) {
                    scope.deactivate();
                }
            });

            elem.on('dropcreate', function() {
                if (scope.create) {
                    scope.create();
                }
            });

            elem.on('drop', function(event, ui) {
                if (scope.drop) {
                    scope.drop({
                        event: event,
                        ui: ui
                    });
                }
            });

            elem.on('dropover', function(event, ui) {
                if (scope.over) {
                    scope.over({
                        event: event,
                        ui: ui
                    });
                }
            });

            elem.on('dropout', function(event, ui) {
                if (scope.out) {
                    scope.out({
                        event: event,
                        ui: ui
                    });
                }
            });
        }
    }
})();
