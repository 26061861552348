(function() {
    'use strict';

    angular
        .module('blocks.fileinput')
        .directive('fileInput', fileInput);

    fileInput.$inject = ['$parse'];

    /* @ngInject */
    function fileInput($parse) {
        return {
            restrict: 'A',
            link: function(scope, elem, attrs) {
                elem.bind('change', function() {
                    $parse(attrs.fileInput)
                        .assign(scope, elem[0].files[0]);
                    if (!scope.$$phase) scope.$apply();
                });
            }
        };
    }
})();
