(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('tooltip', tooltip);

    function tooltip() {
        return {
            link: function(scope, element) {
                var target = angular.element(element);

                target.hover(function() {
                    target.tooltip('show');
                }, function() {
                    target.tooltip('hide');
                });
            }
        };
    }
})();
