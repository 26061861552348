(function() {
    'use strict';

    angular
        .module('app.settings')
        .service('Billing', Billing);

    Billing.$inject = ['Session'];

    /* @ngInject */
    function Billing(Session) {
        var s = this;

        s.inTrial = inTrial;
        s.trialOver = trialOver;
        s.trialDaysLeft = trialDaysLeft;

        function inTrial() {
            return Session.data.billing.data.in_trial;
        }

        function trialOver() {
            return Session.data.billing.data.trial_over;
        }

        function trialDaysLeft() {
            return Session.data.billing.data.days_left;
        }
    }
})();
