(function() {
    'use strict';

    angular
        .module('app.common')
        .service('Zoom', Zoom);

    function Zoom() {
        var s = this;

        s.enable = enable;
        s.disable = disable;

        function enable() {
            document.querySelector('meta[name="viewport"]').content = 'width=device-width, initial-scale=1';
        }

        function disable() {
            document.querySelector('meta[name="viewport"]').content = 'width=device-width, minimum-scale=1.0001, maximum-scale=1.0001, initial-scale=1.0001, user-scalable=no';
        }
    }
})();
