(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('eezeTimepicker', eezeTimepicker);

    eezeTimepicker.$inject = ['$compile', '$locale', '$timeout', 'eezeTimepickerHelper'];

    /* @ngInject */
    function eezeTimepicker($compile, $locale, $timeout, eezeTimepickerHelper) {
        return {
            require: 'ngModel',
            link: link,
            scope: {
                action: '&',
                ngModel: '=',
                fieldId: '='
            }
        };

        function link(scope, element, attrs, ngModel) {
            var actionTimeout,
                noReset = false,
                timeout = 0;

            scope.twelveHours = $locale.DATETIME_FORMATS.shortTime.split(' ')[1] == 'a';

            var hoursInput = angular.element(getHoursInput());

            $compile(hoursInput)(scope);
            element.append(hoursInput);
            var separator = angular.element(getSeparator());

            $compile(separator)(scope);
            element.append(separator);
            var minutesInput = angular.element(getMinutesInput());

            $compile(minutesInput)(scope);
            element.append(minutesInput);
            var ampmSwitcher = angular.element(getAmpm());

            if (scope.twelveHours) {
                $compile(ampmSwitcher)(scope);
                element.append(ampmSwitcher);
            }
            var clearButton = angular.element(getClear());

            $compile(clearButton)(scope);
            element.append(clearButton);

            scope.am = $locale.DATETIME_FORMATS.AMPMS[0];
            scope.pm = $locale.DATETIME_FORMATS.AMPMS[1];
            scope.ampm = 'am';

            scope.hoursChange = hoursChange;
            scope.minutesChange = minutesChange;
            scope.toggleAmpm = toggleAmpm;
            scope.toggleAmpmOnKeyup = toggleAmpmOnKeyup;
            scope.clear = clear;
            scope.pad = pad;

            activate();

            function activate() {
                eezeTimepickerHelper.invoker = null;

                if (scope.ngModel) doTime(scope.ngModel);
                scope.$watch('ngModel', doTime);
            }

            function doTime(time) {
                if (eezeTimepickerHelper.invoker === scope.fieldId) return false;

                if (time === '' || time === null) {
                    scope.hours = null;
                    scope.minutes = null;
                    scope.ampm = 'am';

                    return true;
                } else if (time === undefined) {
                    if (!noReset) {
                        scope.hours = null;
                        scope.minutes = null;
                        scope.ampm = 'am';
                    }

                    noReset = false;

                    eezeTimepickerHelper.invoker = null;

                    return false;
                }

                if (time === 'Invalid Date') return false;

                time = moment.duration(time, 'seconds');

                scope.hours = pad(parseInt(time.asHours()));
                scope.minutes = pad(time.asMinutes() - parseInt(time.asHours()) * 60);

                if (scope.twelveHours) {
                    if (scope.hours == 12) {
                        scope.ampm = 'pm';
                    }

                    if (scope.hours == '00') {
                        scope.hours = 12;
                        scope.ampm = 'am';
                    }

                    if (scope.hours > 12) {
                        scope.hours = pad(scope.hours - 12);
                        scope.ampm = 'pm';
                    }
                }
            }

            function hoursChange(operation) {
                scope.hours = scope.hours ? (scope.hours.toString().match(/\d+/) ? scope.hours.toString().match(/\d+/)[0] : null) : null;

                if (operation) {
                    if (isNaN(parseInt(scope.hours))) {
                        if (operation == 'add') scope.hours = scope.twelveHours ? 12 : 0;
                        if (operation == 'substract') scope.hours = scope.twelveHours ? 11 : 23;
                    } else {
                        scope.hours = parseInt(scope.hours);

                        if (operation == 'add' && ((scope.twelveHours && scope.hours < 12) || (!scope.twelveHours && scope.hours < 23))) scope.hours++;
                        if (operation == 'substract' && ((scope.twelveHours && scope.hours > 1) || (!scope.twelveHours && scope.hours > 0))) scope.hours--;
                    }

                    scope.hours = pad(scope.hours);
                }

                if (scope.twelveHours) {
                    if (scope.hours > 12 || scope.hours == '00') scope.hours = 12;
                    if (scope.hours > 1 || (scope.hours && scope.hours.length == 2)) {
                        minutesInput.find('input').focus();
                        minutesInput.find('input').select();
                    }
                } else {
                    if (scope.hours > 23) scope.hours = 23;
                    if (scope.hours > 2 || (scope.hours && scope.hours.length == 2)) {
                        minutesInput.find('input').focus();
                        minutesInput.find('input').select();
                    }
                }

                checkValue();
            }

            function minutesChange(operation) {
                scope.minutes = scope.minutes ? (scope.minutes.toString().match(/\d+/) ? scope.minutes.toString().match(/\d+/)[0] : null) : null;

                if (operation) {
                    if (isNaN(parseInt(scope.minutes))) {
                        if (operation == 'add') scope.minutes = 0;
                        if (operation == 'substract') scope.minutes = 59;
                    } else {
                        scope.minutes = parseInt(scope.minutes);

                        if (operation == 'add' && scope.minutes < 59) scope.minutes++;
                        if (operation == 'substract' && scope.minutes > 0) scope.minutes--;
                    }

                    scope.minutes = pad(scope.minutes);
                }

                if (scope.minutes > 59) scope.minutes = 59;
                if (scope.minutes > 9 && !scope.hours) {
                    hoursInput.find('input').focus();
                    hoursInput.find('input').select();
                }

                checkValue();
            }

            function toggleAmpm() {
                scope.ampm = scope.ampm == 'am' ? 'pm' : 'am';

                checkValue();
            }

            function toggleAmpmOnKeyup(event) {
                if (event.keyCode == 38 || event.keyCode == 40) toggleAmpm();
            }

            function clear() {
                scope.hours = null;
                scope.minutes = null;
                scope.ampm = 'am';

                checkValue();
            }

            function checkValue() {
                var proceed = false,
                    isValid = false,
                    value = null;

                ngModel.$setTouched();

                if (isNaN(parseInt(scope.hours)) || isNaN(parseInt(scope.minutes)) || String(scope.minutes).length !== 2 || String(scope.hours).length !== 2) {
                    if (scope.hours || scope.hours === 0 || scope.minutes || scope.minutes === 0) {
                        noReset = true;
                        isValid = false;
                    } else {
                        if (isChanged(null)) proceed = true;

                        ngModel.$setViewValue(null);
                        isValid = true;
                    }
                } else {
                    var hours = parseInt(pad((scope.twelveHours && scope.ampm == 'pm') ? parseInt(scope.hours) + 12 : parseInt(scope.hours))),
                        minutes = parseInt(pad(scope.minutes));

                    if (hours == 24) hours = '00';
                    if (hours == 12 && scope.twelveHours && scope.ampm == 'am') hours = '00';
                    if (hours == 0 && scope.twelveHours && scope.ampm == 'pm') hours = 12;

                    value = hours * 3600 + minutes * 60;

                    isValid = true;

                    if (isValid && isChanged(value)) proceed = true;

                    ngModel.$setViewValue(value);
                }

                if (proceed) {
                    eezeTimepickerHelper.invoker = scope.fieldId;

                    if (actionTimeout) $timeout.cancel(actionTimeout);
                    actionTimeout = $timeout(function() {
                        ngModel.$commitViewValue();
                        scope.action();
                    }, timeout);

                    noReset = false;
                }

                ngModel.$setValidity('time-format', isValid);
            }

            function isChanged(newValue) {
                var oldValue = angular.isDefined(ngModel.$modelValue) ? ngModel.$modelValue : null;

                return !angular.equals(oldValue, newValue);
            }

            function pad(input) {
                if (input || typeof input == 'number') return ('0' + parseInt(input)).slice(-2);
            }

            hoursInput.on('click', function() {
                hoursInput.find('input').select();
            });

            minutesInput.on('click', function() {
                minutesInput.find('input').select();
            });

            function getHoursInput() {
                return '<div class="field-time-container">' +
                    '<input type="tel" class="form-control field-time no-right-border" translate-once-placeholder="DOCUMENTS.PICKER.HOURS" ' +
                    'ng-model="hours" ng-change="hoursChange()" ng-blur="hours = pad(hours); hoursChange()">' +
                    '</div>';
            }

            function getSeparator() {
                return '<span class="form-control field-time-separator no-left-border no-right-border">:</span>';
            }

            function getMinutesInput() {
                return '<div class="field-time-container">' +
                    '<input type="tel" class="form-control field-time no-left-border" translate-once-placeholder="DOCUMENTS.PICKER.MINUTES" ' +
                    'ng-model="minutes" ng-change="minutesChange()" ng-blur="minutes = pad(minutes); minutesChange()">' +
                    '</div>';
            }

            function getAmpm() {
                return '<button type="button" class="btn btn-default clear-button" ' +
                    'ng-click="toggleAmpm($event)" ng-keyup="toggleAmpmOnKeyup($event)">' +
                    '{{ampm == \'am\' ? am : pm}}' +
                    '</button>';
            }

            function getClear() {
                return '<button type="button" class="btn btn-link clear-button m-l-0" ng-click="clear()" translate-once="DOCUMENTS.CLEAR"></button>';
            }
        }
    }

    angular
        .module('app.documents')
        .service('eezeTimepickerHelper', eezeTimepickerHelper);

    function eezeTimepickerHelper() {
        this.invoker = null;
    }
})();
