(function() {
    'use strict';

    angular
        .module('app.documents')
        .component('assignTo', {
            bindings: {
                action: '&',
                to: '<'
            },
            transclude: true,
            controller: 'AssignToController as vm',
            template: '<a href="" ng-click="vm.openModal()" ng-transclude></a>'
        });
})();
