(function() {
    'use strict';

    angular
        .module('app.customers')
        .service('Customers', Customers);

    Customers.$inject = ['$resource', 'globals'];

    /* @ngInject */
    function Customers($resource, globals) {
        var s = this;

        s.api = $resource(globals.apiUrl + '/customer/:id', {id: '@id'}, {update: {method: 'PUT'}});
    }
})();
