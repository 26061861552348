(function() {
    'use strict';

    angular
        .module('app.templates')
        .directive('autoFont', autoFont);

    autoFont.$inject = [];

    function autoFont() {
        return {
            restrict: 'AE',
            link: link,
            scope: {field: '=autoFont'}
        };

        function link(scope, elem) {
            if (scope.field && scope.field.options) {
                if (!scope.field.options.hasOwnProperty('fontSize')) return;

                scope.$watch(function() {
                    return scope.field.options.fontSize;
                }, changeElementCss);
            }

            function changeElementCss(fontSize) {
                elem.css('fontSize', fontSize ? fontSize : '');
            }
        }
    }

})();
