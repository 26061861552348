(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('DocumentsController', DocumentsController);

    DocumentsController.$inject = ['$filter', '$q', '$state', 'Documents', 'Tags', 'Session', 'logger', 'SweetAlert', 'globals', 'pdf', 'listStorage'];

    /* @ngInject */
    function DocumentsController($filter, $q, $state, Documents, Tags, Session, logger, SweetAlert, globals, pdf, listStorage) {
        var request, params, popover,
            vm = this;

        vm.jwt = Session.jwt;
        vm.permissions = Session.permissions;
        vm.can = Session.can;
        vm.apiUrl = globals.apiUrl;

        vm.defaults = {popover: {width: 300}};
        vm.filter = {orderBy: 'updated', order: 'desc', status: '', tags: []};

        vm.document = {};
        vm.documents = [];
        vm.documentsMeta = [];
        vm.documentEdit = [];

        vm.getDocuments = getDocuments;
        vm.moreDocuments = moreDocuments;

        vm.duplicateDocument = duplicateDocument;
        vm.editDocument = editDocument;

        vm.archiveDocument = archiveDocument;
        vm.restoreDocument = restoreDocument;
        vm.deleteDocument = deleteDocument;
        vm.deleteDocumentConfirm = deleteDocumentConfirm;

        vm.assignUser = assignUser;

        vm.editTags = editTags;
        vm.showTagPopover = showTagPopover;
        vm.showTagsFilterPopover = showTagsFilterPopover;

        vm.createDocument = createDocument;
        vm.startFill = startFill;

        vm.print = pdf.print;
        vm.download = pdf.download;

        vm.inProgress = false;
        vm.timeout = 500;

        activate();

        function activate() {
            listStorage.clearExcept('eezeDocumentsListSearch');
            var promises = [getDocuments()];

            return $q.all(promises);
        }

        function getDocuments(filter) {
            if (filter) {
                listStorage.setItem('eezeDocumentsListSearch', vm.filter);
            } else if (localStorage.getItem('eezeDocumentsListSearch')) {
                vm.filter = listStorage.getItem('eezeDocumentsListSearch');
            }

            vm.documents = [];
            vm.inProgress = true;

            params = {
                include: 'tags',
                search: vm.filter.search || null,
                status: vm.filter.status || null,
                order: vm.filter.order,
                orderBy: vm.filter.orderBy,
                tags: vm.filter.tags.length ? Tags.transform.forFilter(vm.filter.tags) : null
            };

            if (request) request.$cancelRequest();
            request = Documents.api.get(params);

            return request.$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.documents = response.data;
                vm.documentsMeta = response.meta;

                vm.inProgress = false;

                return vm.documents;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function moreDocuments() {
            if (vm.documents.length && vm.documentsMeta.cursor.next && !vm.inProgress) {
                vm.inProgress = true;

                params = {
                    include: 'tags',
                    search: vm.filter.search || null,
                    status: vm.filter.status || null,
                    order: vm.filter.order,
                    orderBy: vm.filter.orderBy,
                    tags: vm.filter.tags.length ? Tags.transform.forFilter(vm.filter.tags) : null,
                    current: vm.documentsMeta.cursor.next,
                    prev: vm.documentsMeta.cursor.current
                };

                return Documents.api.get(params).$promise
                    .then(success);
            }

            function success(response) {
                vm.documents = vm.documents.concat(response.data);
                vm.documentsMeta = response.meta;

                vm.inProgress = false;
            }
        }

        function duplicateDocument(document) {
            logger.loading();

            var params = {
                id: document.id,
                default: {time: new Date().getHours() * 3600 + new Date().getMinutes() * 60}
            };

            return Documents.duplicate(params)
                .then(success)
                .catch(fail);

            function success(response) {
                // ADD WHEN ALL OR DRAFT FILTER IS SELECTED. DUPLICATED DOC HAS ALWAYS DRAFT STATUS
                if (vm.filter.status === '' || vm.filter.status === '3') {
                    vm.documents.unshift(response.data.data);
                }

                logger.clear();
                logger.success($filter('translate')('DOCUMENTS.SAVED'));
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function editDocument(input, args) {
            logger.saving();

            var params = {
                id: args ? args.id : null,
                include: 'tags'
            };

            if (input == 'assign') params.assignedTo = args.userId || 0;
            if (input == 'status') params.status = args.status;

            return Documents.api.update(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                var index = _.findIndex(vm.documents, {id: response.data.id});

                vm.documents[index] = response.data;

                if (
                    (vm.permissions['documents']['view.all'] === false && args.userId !== Session.id) ||
                    (Session.group && (Session.group != args.groupId)) ||
                    (input == 'status' && (args.status === 5 || args.status === 6))
                ) {
                    _.remove(vm.documents, {id: response.data.id});
                }

                logger.clear();
                logger.success($filter('translate')('DOCUMENTS.SAVED'));

                return true;
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);

                return false;
            }
        }

        function archiveDocument(id) {
            vm.editDocument('status', {id: id, status: 6});
        }

        function restoreDocument(id) {
            vm.editDocument('status', {id: id, status: 5});
        }

        function deleteDocument(document) {
            SweetAlert.swal({
                title: $filter('translate')('DOCUMENTS.ARE_YOU_SURE'),
                text: $filter('translate')('DOCUMENTS.ABOUT_TO_DELETE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('DOCUMENTS.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteDocumentConfirm(document);
                }
            });
        }

        function deleteDocumentConfirm(document) {
            logger.loading();

            return Documents.api.delete({id: document.id}).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.documents.splice(vm.documents.indexOf(document), 1);

                logger.clear();
                logger.success($filter('translate')('DOCUMENTS.DELETED'));
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function assignUser(id, userId, groupId) {
            vm.editDocument('assign', {id: id, userId: userId, groupId: groupId});
        }

        function editTags(document, event) {
            vm.document = document;
            vm.tags = angular.copy(document.tags.data);

            vm.showTagPopover(event);
        }

        function showTagPopover(event) {
            popover = angular.element('#tags-popover');

            var elementOffsetLeft = event.target.parentElement.localName == 'button' ? event.target.parentElement.offsetLeft : event.target.offsetLeft;

            popover.removeClass('hidden');

            popover.css({
                left: event.pageX - event.offsetX - elementOffsetLeft + 5,
                top: event.pageY - event.offsetY - event.target.offsetTop + event.target.offsetParent.offsetHeight - 65,
                right: 'auto',
                bottom: 'auto'
            });

            popover.removeClass('left right top bottom');
            popover.addClass('right');
        }

        function showTagsFilterPopover(event) {
            popover = angular.element('#tags-filter-popover');

            var targetOffsetLeft = event.target.parentElement.localName == 'button'
                    ? event.target.offsetLeft : 0,
                targetOffsetTop = event.target.parentElement.localName == 'button'
                    ? event.target.offsetTop : 0,
                popoverWidth = parseInt(popover.css('width').split('px')[0]);

            popover.css({
                right: 'auto',
                left: event.pageX - event.offsetX - targetOffsetLeft - popoverWidth + 70,
                top: event.pageY - event.offsetY - targetOffsetTop - 25,
                bottom: 'auto'
            });

            popover.removeClass('left right top bottom');
            popover.addClass('left');
        }

        function createDocument(templateId, templateName) {
            logger.loading();

            var params = {
                templateId: templateId,
                name: templateName + ' ' + $filter('date')(new Date(), 'short'),
                default: {time: new Date().getHours() * 3600 + new Date().getMinutes() * 60}
            };

            return Documents.api.save(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                logger.clear();
                $state.go('filler', {
                    new: true,
                    id: response.data.id,
                    templateType: response.data.template.data.type
                });
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function startFill(document) {
            $state.go('filler', {
                id: document.id,
                templateType: document.template.data.type
            });
        }

    }
})();
