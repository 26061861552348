(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('autoselect', autoselect);

    autoselect.$inject = ['$timeout'];

    /* @ngInject */
    function autoselect($timeout) {
        return {
            restrict: 'A',
            link: function($scope, $element) {
                $timeout(function() {
                    $element[0].select();
                });
            }
        };
    }
})();
