(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('fitCanvas', fitCanvas);

    fitCanvas.$inject = ['$document', '$window', '$timeout'];

    /* @ngInject */
    function fitCanvas($document, $window, $timeout) {
        return {
            restrict: 'A',
            link: link
        };

        function link(scope, element, attrs) {
            var debounce,
                scale,
                width = attrs.fitCanvas;

            go(width);

            angular.element($window).on('resize', function() {
                if (debounce) $timeout.cancel(debounce);

                debounce = $timeout(function() {
                    go(width);
                }, 100);
            });

            function go(elWidth) {
                if ($document[0].body.clientWidth > elWidth) {
                    scale = 1;

                    element.css('transform', 'scale(1, 1)');
                } else {
                    scale = $document[0].body.clientWidth / elWidth;

                    element.css({
                        '-webkit-transform-origin': '0 0',
                        'transform-origin': '0 0',
                        'transform': 'scale(' + scale + ', ' + scale + ')'
                    });
                }
            }
        }
    }
})();
