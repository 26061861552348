(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('TopNavController', TopNavController);

    TopNavController.$inject = ['$rootScope', '$state', 'Auth', 'Session'];

    /* @ngInject */
    function TopNavController($rootScope ,$state, Auth, Session) {
        var vm = this;

        vm.name = '';
        vm.view = $state.current.name;
        vm.permissions = Session.permissions;
        vm.company = Session.data.customer.name;

        vm.logOut = logOut;
        vm.doName = doName;

        activate();

        function activate() {
            vm.doName();

            if ($rootScope.$$listeners['nameChange']) $rootScope.$$listeners['nameChange'] = [];
            $rootScope.$on('nameChange', doName);
        }

        function logOut() {
            Auth.logOut();
            $state.go('auth.login');
        }

        function doName() {
            vm.name = Session.data.user.firstName + ' ' + Session.data.user.lastName;
        }
    }
})();
