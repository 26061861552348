(function() {
    'use strict';

    angular
        .module('app.common')
        .factory('msgBus', msgBus);

    msgBus.$inject = ['$rootScope'];

    /* @ngInject */
    function msgBus($rootScope) {
        var msgBus = {};

        msgBus.messages = {};

        msgBus.emit = function(event, data) {
            data = data || {};

            msgBus.messages[event] = data;

            $rootScope.$emit(event, data);
        };

        msgBus.on = function(event, func, scope) {
            var unbind = $rootScope.$on(event, func);

            if (scope) scope.$on('$destroy', unbind);

            return unbind;
        };

        return msgBus;
    }
})();
