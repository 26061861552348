(function() {
    'use strict';

    angular
        .module('app.templates')
        .directive('juiResizable', juiResizable);

    function juiResizable() {
        return {
            restrict: 'A',
            link: link,
            scope: {
                juiResizable: '@',
                juiAspectRatio: '@',
                juiHandles: '@',
                juiOnResize: '&',
                juiOnResizeStop: '&',
                juiOnResizeStart: '&'
            }
        };

        function link(scope, elem, attrs) {

            if (scope.juiResizable == 'false') return;

            elem.resizable({aspectRatio: scope.juiAspectRatio === 'true' ? 1 : null});

            elem.on('resizestop', function(event, ui) {
                if (scope.juiOnResizeStop) {
                    scope.juiOnResizeStop({
                        event: event,
                        ui: ui,
                        scope: scope,
                        attrs: attrs
                    });
                }
            });

            elem.on('resize', function(event, ui) {
                if (scope.juiOnResize) {
                    scope.juiOnResize({
                        event: event,
                        ui: ui,
                        scope: scope,
                        attrs: attrs
                    });
                }
            });

            elem.on('resizestart', function(event, ui) {
                if (scope.juiOnResizeStart) {
                    scope.juiOnResizeStart({
                        event: event,
                        ui: ui,
                        scope: scope,
                        attrs: attrs
                    });
                }
            });

        }
    }
})();
