(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('SignatureController', SignatureController);

    SignatureController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', 'Documents', 'Zoom', 'logger'];

    /* @ngInject */
    function SignatureController($scope, $rootScope, $state, $stateParams, Documents, Zoom, logger) {
        var emptySignature = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB3aWR0aD0iMCIgaGVpZ2h0PSIwIj48L3N2Zz4=',
            parent = $state.current.name.split('.signature')[0],
            vm = this;

        vm.documentId = parseInt($stateParams.id);
        vm.fieldId = parseInt($stateParams.fieldId);
        vm.signature = false; // boolean so initial load does not trigger accept in the directive
        vm.signatureCache = '';

        vm.accept = accept;
        vm.clear = clear;
        vm.done = done;

        activate();

        function activate() {
            if (!vm.fieldId) return $state.go(parent, {id: vm.documentId});

            angular.element('body').css({'overflow': 'hidden'});

            Zoom.disable();
        }

        function accept() {
            if (vm.signature == vm.signatureCache) return false;

            var params = {id: vm.documentId, values: {}};

            params.values[vm.fieldId] = {
                id: vm.fieldId,
                value: vm.signature == emptySignature ? '' : vm.signature
            };

            return Documents.api.update(params).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.signatureCache = angular.copy(vm.signature);
                $rootScope.$broadcast('fieldUpdate', {field: vm.fieldId, value: vm.signature == emptySignature ? '' : vm.signature});
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function clear() {
            vm.signature = '';
        }

        function done() {
            if (parent == 'filler.pdf') Zoom.enable();

            $state.go(parent, {id: vm.documentId});
        }

        $scope.$on('$destroy', function() {
            angular.element('body').css({'overflow': 'auto'});
        });
    }
})();
