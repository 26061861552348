(function(window) {
    window.eezeEnv = window.eezeEnv || {};

    // API url
    window.eezeEnv.apiUrl = 'https://app.eeze.ee/api';

    // UI url
    window.eezeEnv.uiUrl = 'https://app.eeze.ee';

    // Info Email
    window.eezeEnv.infoEmail = 'info@eeze.ee';

    // Bugsnag Apikey
    window.eezeEnv.bugsnagApikey = '';
}(this));
