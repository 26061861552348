(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('jSignature', jSignature);

    jSignature.$inject = ['$timeout'];

    /* @ngInject */
    function jSignature($timeout) {
        return {
            link: link,
            scope: {
                model: '=jSignature',
                accept: '&'
            }
        };

        function link(scope, element) {
            element.jSignature({
                'decor-color': 'transparent',
                'sizeRatio': 2.3
            });

            scope.$watch('model', function(newValue) {
                if (typeof newValue !== 'undefined' && typeof newValue !== 'boolean') {
                    if (newValue.length == 0) element.jSignature('reset');
                }
            });

            element.bind('change', function() {
                $timeout(function() {
                    var data = element.jSignature('getData', 'svgbase64');

                    data[0] = 'data:' + data[0];

                    scope.model = data.join(',');
                    scope.$apply();

                    scope.accept();
                }, 100, true);
            });
        }
    }
})();
