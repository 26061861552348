// Include in index.html so that app level exceptions are handled.
// Exclude from testRunner.html which should run exactly what it wants to run
(function() {
    'use strict';

    angular
        .module('blocks.exception')
        .provider('exceptionHandler', exceptionHandlerProvider)
        .config(config);

    /**
     * Must configure the exception handling
     */
    function exceptionHandlerProvider() {

        /* jshint validthis:true */
        this.config = {appErrorPrefix: undefined};

        this.configure = function(appErrorPrefix) {
            this.config.appErrorPrefix = appErrorPrefix;
        };

        this.$get = function() {
            return {config: this.config};
        };
    }

    config.$inject = ['$provide'];

    /**
     * Configure by setting an optional string value for appErrorPrefix.
     * Accessible via config.appErrorPrefix (via config value).
     * @param  {Object} $provide
     */
    /* @ngInject */
    function config($provide) {
        $provide.decorator('$exceptionHandler', extendExceptionHandler);
    }

    extendExceptionHandler.$inject = ['$delegate', '$log', '$filter', 'exceptionHandler', 'logger', 'bugsnag'];

    /**
     * Extend the $exceptionHandler service to also display a toast.
     * @param  {Object} $delegate
     * @param  {Object} $log
     * @param  {Object} $filter
     * @param  {Object} exceptionHandler
     * @param  {Object} logger
     * @param  {Object} bugsnag
     * @return {Function} the decorated $exceptionHandler service
     */
    function extendExceptionHandler($delegate, $log, $filter, exceptionHandler, logger, bugsnag) {
        return function(exception, cause) {
            var appErrorPrefix = exceptionHandler.config.appErrorPrefix || '';

            exception.message = appErrorPrefix + exception.message;

            /**
             * Could add the error to a service's collection,
             * add errors to $rootScope, log errors to remote web server,
             * or log locally. Or throw hard. It is entirely up to you.
             * throw exception;
             *
             * @example
             *     throw { message: 'error message we added' };
             */

            // Send error to bugsnag remote server
            try {
                bugsnag.fixContext();
                if (angular.isString(exception)) {
                    bugsnag.notify(exception);
                } else {
                    bugsnag.notifyException(exception);
                }
            } catch (e) {
                $log.error(e);
            }

            // Tell Customer that something went wrong and we are notified
            logger.error($filter('translate')('SOMETHING_WENT_WRONG'));

            $delegate(exception, cause);
        };
    }
})();
