(function() {
    'use strict';

    angular
        .module('app.users')
        .controller('UsersController', UsersController);

    UsersController.$inject = ['$rootScope', '$filter', '$q', '$uibModal', 'Session', 'Users', 'Customers', 'SweetAlert', 'logger', 'globals', 'listStorage'];

    /* @ngInject */
    function UsersController($rootScope, $filter, $q, $uibModal, Session, Users, Customers, SweetAlert, logger, globals, listStorage) {
        var params,
            vm = this;

        vm.self = Session.id;
        vm.permissions = Session.permissions;
        vm.globals = globals;

        vm.inProgress = false;
        vm.timeout = 300;
        vm.progress = {userCountLoader: false};

        vm.users = [];
        vm.usersMeta = {};
        vm.filter = {status: '', orderBy: 'email', order: 'asc'};

        vm.customer = {};

        vm.getUsers = getUsers;
        vm.moreUsers = moreUsers;

        vm.resendInvitation = resendInvitation;

        vm.openNewUser = openNewUser;
        vm.openBulkInvite = openBulkInvite;

        vm.editUser = editUser;
        vm.editUserStatus = editUserStatus;

        vm.deleteUser = deleteUser;
        vm.deleteUserConfirm = deleteUserConfirm;

        activate();

        function activate() {
            listStorage.clearExcept('eezeUsersListSearch');
            var promises = [getUsers(), getCount()];

            return $q.all(promises);
        }

        function getUsers(filter) {
            if (filter) {
                listStorage.setItem('eezeUsersListSearch', vm.filter);
            } else if (localStorage.getItem('eezeUsersListSearch')) {
                vm.filter = listStorage.getItem('eezeUsersListSearch');
            }

            vm.users = [];
            vm.inProgress = true;

            params = {
                search: vm.filter.search || null,
                status: vm.filter.status || null,
                orderBy: vm.filter.orderBy,
                order: vm.filter.order,
                include: 'groups'
            };

            return Users.api.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.users = response.data;
                vm.usersMeta = response.meta;

                vm.inProgress = false;

                return vm.users;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function getCount() {
            if (!vm.permissions.customer.view) return false;

            vm.progress.userCountLoader = true;

            return Customers.api.get({include: 'restrictions'}).$promise
                .then(success);

            function success(response) {
                vm.customer = response;
                vm.customer.remainingUsers = vm.customer.data.restrictions.data.userLimit - vm.customer.meta.activeUsers;
                vm.progress.userCountLoader = false;
                return vm.customer;
            }
        }

        function moreUsers() {
            if (vm.users.length && vm.usersMeta.cursor.next && !vm.inProgress) {
                vm.inProgress = true;

                params = {
                    search: vm.filter.search || null,
                    status: vm.filter.status || null,
                    orderBy: vm.filter.orderBy,
                    order: vm.filter.order,
                    current: vm.usersMeta.cursor.next,
                    prev: vm.usersMeta.cursor.current
                };

                return Users.api.get(params).$promise
                    .then(success);
            }

            function success(response) {
                vm.inProgress = false;

                vm.users = vm.users.concat(response.data);
                vm.usersMeta = response.meta;
            }
        }

        function openNewUser() {

            if (!vm.permissions.users.create || vm.customer.remainingUsers < 1) {
                return false;
            }

            $uibModal.open({
                animation: false,
                templateUrl: 'app/users/new/users-new.html',
                controller: 'UsersNewController',
                controllerAs: 'vm'
            });

            if ($rootScope.$$listeners['newUserData']) $rootScope.$$listeners['newUserData'] = [];
            $rootScope.$on('newUserData', function(event, data) {
                vm.users.unshift(data.user);
                getCount();
            });

        }

        function openBulkInvite() {
            var modalInstance = $uibModal.open({
                animation: false,
                templateUrl: 'app/users/invite/users-bulk-invite.html',
                controller: 'UsersBulkInviteController',
                controllerAs: 'vm',
                size: 'lg'
            });

            modalInstance.result
                .then(function() {
                    getCount();
                    getUsers();
                });
        }

        function resendInvitation(id) {
            logger.loading();

            return Users.resendInvitation(id)
                .then(success)
                .catch(fail);

            function success() {
                logger.clear();
                logger.success($filter('translate')('USERS.INVITE_RESENT'));
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function editUserStatus(user, status, index) {
            var params = angular.copy(user);

            if (user.role) params.role = user.role.data.id;
            params.status = status;

            return Users.api.update(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                if (vm.filter.status && response.data.status !== vm.filter.status) {
                    vm.users.splice(vm.users.indexOf(user), 1);
                } else {
                    vm.users[index] = response.data;
                }
                getCount();
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function editUser(user, index) {
            var modalInstance = $uibModal.open({
                animation: false,
                templateUrl: 'app/users/edit/users-edit.html',
                controller: 'UsersEditController',
                controllerAs: 'vm',
                resolve: {
                    user: function() {
                        return user;
                    },
                    index: function() {
                        return index;
                    }
                }
            });

            modalInstance.result
                .then(function(data) {
                    vm.users[data.index] = data.user;
                    getCount();
                });
        }

        function deleteUser(user) {
            SweetAlert.swal({
                title: $filter('translate')('USERS.ARE_YOU_SURE'),
                text: $filter('translate')('USERS.ABOUT_TO_DELETE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('USERS.DELETE')
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteUserConfirm(user);
                }
            });
        }

        function deleteUserConfirm(user) {
            logger.loading();

            return Users.api.delete({id: user.id}).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.users.splice(vm.users.indexOf(user), 1);

                logger.clear();
                logger.success($filter('translate')('USERS.DELETED'));

                getCount();
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }
    }

})();
