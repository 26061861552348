(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('eezeDurationpicker', eezeDurationpicker);

    eezeDurationpicker.$inject = ['$compile', '$timeout', 'moment', 'eezeDurationpickerHelper'];

    /* @ngInject */
    function eezeDurationpicker($compile, $timeout, moment, eezeDurationpickerHelper) {
        return {
            require: 'ngModel',
            link: link,
            scope: {
                action: '&',
                ngModel: '=',
                fieldId: '='
            }
        };

        function link(scope, element, attrs, ngModel) {
            var actionTimeout,
                noReset = false,
                timeout = 0;

            var hoursInput = angular.element(getHoursInput());

            $compile(hoursInput)(scope);
            element.append(hoursInput);
            var separator = angular.element(getSeparator());

            $compile(separator)(scope);
            element.append(separator);
            var minutesInput = angular.element(getMinutesInput());

            $compile(minutesInput)(scope);
            element.append(minutesInput);
            var clearButton = angular.element(getClear());

            $compile(clearButton)(scope);
            element.append(clearButton);

            scope.hoursChange = hoursChange;
            scope.minutesChange = minutesChange;
            scope.clear = clear;
            scope.pad = pad;

            activate();

            function activate() {
                eezeDurationpickerHelper.invoker = null;

                if (scope.ngModel) doTime(scope.ngModel);
                scope.$watch('ngModel', doTime);
            }

            function doTime(time, previousTime) {
                if (time === '' || time === true || time === false || time === null) {
                    if (!previousTime && previousTime !== 0) return false;

                    scope.hours = null;
                    scope.minutes = null;

                    return true;
                } else if (time === undefined) {
                    if (!noReset) {
                        scope.hours = null;
                        scope.minutes = null;
                    }

                    noReset = false;

                    eezeDurationpickerHelper.invoker = null;

                    return false;
                } else if (eezeDurationpickerHelper.invoker === scope.fieldId || time === 'Invalid Date') {
                    eezeDurationpickerHelper.invoker = null;

                    return false;
                } else if (typeof time === 'number') {
                    if (previousTime) {
                        previousTime = typeof previousTime === 'number' ? previousTime : previousTime.getTime();

                        if (time === previousTime / 1000) return false;
                    }
                }

                time = moment.duration(time, 'seconds');

                scope.hours = pad(parseInt(time.asHours()));
                scope.minutes = pad(time.asMinutes() - parseInt(time.asHours()) * 60);
            }

            function hoursChange(operation) {
                scope.hours = scope.hours ? (scope.hours.toString().match(/\d+/) ? scope.hours.toString().match(/\d+/)[0] : null) : null;

                if (operation) {
                    if (isNaN(parseInt(scope.hours))) {
                        if (operation == 'add') scope.hours = 0;
                    } else {
                        scope.hours = parseInt(scope.hours);

                        if (operation == 'add') scope.hours++;
                        if (operation == 'substract' && scope.hours > 0) scope.hours--;
                    }

                    scope.hours = pad(scope.hours);
                }

                checkValue();
            }

            function minutesChange(operation) {
                scope.minutes = scope.minutes ? (scope.minutes.toString().match(/\d+/) ? scope.minutes.toString().match(/\d+/)[0] : null) : null;

                if (operation) {
                    if (isNaN(parseInt(scope.minutes))) {
                        if (operation == 'add') scope.minutes = 0;
                        if (operation == 'substract') scope.minutes = 59;
                    } else {
                        scope.minutes = parseInt(scope.minutes);

                        if (operation == 'add' && scope.minutes < 59) scope.minutes++;
                        if (operation == 'substract' && scope.minutes > 0) scope.minutes--;
                    }

                    scope.minutes = pad(scope.minutes);
                }

                if (scope.minutes > 59) scope.minutes = 59;
                if (scope.minutes > 9 && !scope.hours) {
                    hoursInput.find('input').focus();
                    hoursInput.find('input').select();
                }

                checkValue();
            }

            function clear() {
                scope.hours = null;
                scope.minutes = null;

                checkValue();
            }

            function checkValue() {
                var proceed = false,
                    isValid = false,
                    value = null;

                ngModel.$setTouched();

                if (isNaN(parseInt(scope.hours)) || isNaN(parseInt(scope.minutes)) || String(scope.minutes).length !== 2 || String(scope.hours).length < 2) {
                    if (scope.hours || scope.hours === 0 || scope.minutes || scope.minutes === 0) {
                        noReset = true;
                        isValid = false;
                    } else {
                        if (isChanged(null)) proceed = true;

                        ngModel.$setViewValue(null);
                        isValid = true;
                    }
                } else {
                    var hours = parseInt(scope.hours) * 3600,
                        minutes = parseInt(scope.minutes) * 60;

                    value = hours + minutes;

                    isValid = true;

                    if (isValid && isChanged(value)) proceed = true;

                    ngModel.$setViewValue(value);
                }

                if (proceed) {
                    eezeDurationpickerHelper.invoker = scope.fieldId;

                    if (actionTimeout) $timeout.cancel(actionTimeout);
                    actionTimeout = $timeout(function() {
                        ngModel.$commitViewValue();
                        scope.action();
                    }, timeout);

                    noReset = false;
                }

                ngModel.$setValidity('duration-format', isValid);
            }

            function isChanged(newValue) {
                var oldValue = angular.isDefined(ngModel.$modelValue) ? ngModel.$modelValue : null;

                return !angular.equals(oldValue, newValue);
            }

            function pad(input) {
                if (input || typeof input == 'number') return input.toString().length == 1 ? ('0' + input).slice(-2) : input;
            }

            hoursInput.on('click', function() {
                hoursInput.find('input').select();
            });

            minutesInput.on('click', function() {
                minutesInput.find('input').select();
            });

            function getHoursInput() {
                return '<div class="field-duration-container">' +
                    '<input type="tel" class="form-control field-duration no-right-border" translate-once-placeholder="DOCUMENTS.PICKER.HRS" ' +
                    'ng-model="hours" ng-change="hoursChange()" ng-blur="hours = pad(hours); hoursChange()">' +
                    '</div>';
            }

            function getSeparator() {
                return '<span class="form-control field-time-separator no-left-border no-right-border">:</span>';
            }

            function getMinutesInput() {
                return '<div class="field-time-container">' +
                    '<input type="tel" class="form-control field-time no-left-border" translate-once-placeholder="DOCUMENTS.PICKER.MIN" ' +
                    'ng-model="minutes" ng-change="minutesChange()" ng-blur="minutes = pad(minutes); minutesChange()">' +
                    '</div>';
            }

            function getClear() {
                return '<button type="button" class="btn btn-link clear-button m-l-0" ng-click="clear()" translate-once="DOCUMENTS.CLEAR"></button>';
            }
        }
    }

    angular
        .module('app.documents')
        .service('eezeDurationpickerHelper', eezeDurationpickerHelper);

    function eezeDurationpickerHelper() {
        this.invoker = null;
    }
})();
