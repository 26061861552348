(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('listFieldsRenderer', listFieldsRenderer);

    listFieldsRenderer.$inject = ['RecursionHelper'];

    /* @ngInject */
    function listFieldsRenderer(RecursionHelper) {
        return {
            restrict: 'EA',
            templateUrl: 'app/documents/fill/list-filler/list-fields-renderer/listFields.html',
            scope: {
                vm: '=',
                fields: '=',
                device: '='
            },
            compile: RecursionHelper.compile
        };
    }
})();
