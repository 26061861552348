(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('TagsController', TagsController);

    TagsController.$inject = ['$rootScope', '$q', 'Tags', 'logger'];

    /* @ngInject */
    function TagsController($rootScope, $q, Tags, logger) {
        var params,
            vm = this;

        vm.available = [];

        vm.tagsChanged = tagsChanged;
        vm.getAvailableTags = getAvailableTags;

        activate();

        function activate() {
            if (!Tags.list.length) {
                Tags.get();
                $rootScope.$on('gotTags', function() {
                    vm.available = Tags.list;
                });
            } else {
                vm.available = Tags.list;
            }
        }

        function tagsChanged() {
            params = {
                id: vm.document.id,
                tags: Tags.transform.forSave(vm.tags)
            };

            return Tags.document.update(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                if (response.meta.newTags.length) {
                    vm.available = response.meta.newTags.concat(vm.available);

                    Tags.list = vm.available;
                    $rootScope.$broadcast('newTagAdded');
                }

                vm.document.tags.data = response.data;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function getAvailableTags(query) {
            var result;
            var deferred = $q.defer();

            result = vm.available.filter(function(field) {
                return field.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });

            deferred.resolve(result);
            return deferred.promise;
        }
    }
})();
