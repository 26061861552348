(function() {
    'use strict';

    angular
        .module('app.documents')
        .service('Documents', Documents);

    Documents.$inject = ['$http', '$resource', 'globals'];

    /* @ngInject */
    function Documents($http, $resource, globals) {
        var s = this;

        s.getSvg = getSvg;
        s.duplicate = duplicate;
        s.email = email;
        s.validateRequiredFields = validateRequiredFields;

        s.api = $resource(globals.apiUrl + '/documents/:id', {id: '@id'}, {update: {method: 'PUT'}});

        function getSvg(source) {
            return $http.get(source);
        }

        function duplicate(params) {
            return $http.post(globals.apiUrl + '/documents/' + params.id + '/duplicate', params);
        }

        function email(params) {
            return $http.post(globals.apiUrl + '/documents/' + params.id + '/email', params);
        }

        function validateRequiredFields(params) {
            return $http.get(globals.apiUrl + '/documents/' + params.id + '/validate', params);
        }
    }
})();
