(function() {
    'use strict';

    angular
        .module('app.documents')
        .service('Files', Files);

    Files.$inject = ['$http', 'globals'];

    /* @ngInject */
    function Files($http, globals) {
        var formData,
            s = this;

        s.upload = upload;
        s.remove = remove;

        function upload(file, documentId, fieldId) {
            formData = new FormData();
            formData.append('file', file);

            return $http.post(globals.apiUrl + '/documents/' + documentId + '/fields/' + fieldId + '/files', formData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        }

        function remove(documentId, fieldId, imageId) {
            return $http.delete(globals.apiUrl + '/documents/' + documentId + '/fields/' + fieldId + '/files/' + imageId, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined}
            });
        }
    }
})();
