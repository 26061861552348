(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('eezeDatepicker', eezeDatepicker);

    eezeDatepicker.$inject = ['$compile', '$locale', '$timeout', 'eezeDatepickerHelper'];

    /* @ngInject */
    function eezeDatepicker($compile, $locale, $timeout, eezeDatepickerHelper) {
        return {
            require: 'ngModel',
            link: link,
            scope: {
                action: '&',
                ngModel: '=',
                fieldId: '='
            }
        };

        function link(scope, element, attrs, ngModel) {
            var dayInput = angular.element(getDayInput()),
                monthInput = angular.element(getMonthInput()),
                yearInput = angular.element(getYearInput()),
                clearButton = angular.element(getClear()),
                actionTimeout,
                noReset = false,
                timeout = 0;

            scope.us = $locale.DATETIME_FORMATS.shortDate.split('/').length > 1;
            scope.year = (new Date().getFullYear()).toString();

            scope.dayChange = dayChange;
            scope.monthChange = monthChange;
            scope.yearChange = yearChange;
            scope.clear = clear;
            scope.pad = pad;

            activate();

            function activate() {
                eezeDatepickerHelper.invoker = null;

                $compile(monthInput)(scope);
                $compile(dayInput)(scope);
                $compile(yearInput)(scope);
                $compile(clearButton)(scope);

                if (scope.us) {
                    element.append(monthInput);
                    element.append(dayInput);
                } else {
                    element.append(dayInput);
                    element.append(monthInput);
                }

                element.append(yearInput);
                element.append(clearButton);

                if (scope.ngModel) doDate(scope.ngModel);
                scope.$watch('ngModel', doDate);
            }

            function doDate(date) {
                if (eezeDatepickerHelper.invoker === scope.fieldId) return false;

                if (date === '' || date === undefined) {
                    if (!noReset) {
                        scope.day = null;
                        scope.month = null;
                        scope.year = (new Date().getFullYear()).toString();
                    }

                    noReset = false;

                    return true;
                }

                if (typeof date == 'string') date = new Date(date);
                if (!date || typeof date == 'number' || date == 'Invalid Date') return false;

                scope.day = pad(date.getUTCDate());
                scope.month = pad(date.getUTCMonth() + 1);
                scope.year = date.getUTCFullYear().toString();
            }

            function dayChange() {
                scope.day = scope.day ? (scope.day.match(/\d+/) ? scope.day.match(/\d+/)[0] : null) : null;

                if (scope.day > 31) scope.day = 31;
                if (scope.day > 3 || (scope.day && scope.day.length == 2)) {
                    if (scope.us) {
                        yearInput.focus();
                        yearInput.select();
                    } else {
                        monthInput.focus();
                        monthInput.select();
                    }
                }

                checkDayCount();
                checkValue();
            }

            function monthChange() {
                scope.month = scope.month ? (scope.month.match(/\d+/) ? scope.month.match(/\d+/)[0] : null) : null;

                if (scope.month > 12) scope.month = 12;
                if (scope.month && scope.month.length == 2) {
                    if (scope.us) {
                        dayInput.focus();
                        dayInput.select();
                    } else {
                        yearInput.focus();
                        yearInput.select();
                    }
                }

                if (scope.day) checkDayCount();
                checkValue();
            }

            function yearChange() {
                scope.year = scope.year ? (scope.year.match(/\d+/) ? scope.year.match(/\d+/)[0] : '') : '';

                if (scope.year.length > 4) scope.year = scope.year.slice(0, 4);

                checkDayCount();
                checkValue();
            }

            function clear() {
                scope.day = null;
                scope.month = null;
                scope.year = (new Date().getFullYear()).toString();

                checkValue();
            }

            function checkValue() {
                var proceed = false,
                    isValid = false,
                    value = null;

                ngModel.$setTouched();

                if (scope.day > 0 && scope.month > 0 && scope.year && scope.year.length == 4 && String(scope.day).length === 2 && String(scope.month).length === 2) {
                    isValid = true;
                    value = new Date(scope.year + '-' + pad(scope.month) + '-' + pad(scope.day) + 'T00:00:00.000Z');

                    if (isChanged(value)) proceed = true;

                    ngModel.$setViewValue(value);
                } else {
                    if (scope.day || scope.day === 0 || scope.month || scope.month === 0) {
                        noReset = true;
                        isValid = false;
                    } else {
                        isValid = true;
                        if (isChanged(null)) proceed = true;

                        ngModel.$setViewValue(null);
                    }
                }

                if (proceed) {
                    eezeDatepickerHelper.invoker = scope.fieldId;

                    if (actionTimeout) $timeout.cancel(actionTimeout);
                    actionTimeout = $timeout(function() {
                        ngModel.$commitViewValue();
                        scope.action();
                    }, timeout);

                    noReset = false;
                }

                ngModel.$setValidity('date-format', isValid);
            }

            function isChanged(newValue) {
                var oldValue = angular.isDefined(ngModel.$modelValue) ? ngModel.$modelValue : null;

                return !angular.equals(oldValue, newValue);
            }

            function checkDayCount() {
                if (scope.month) {
                    var month = parseInt(scope.month);

                    if ([4, 6, 9, 11].indexOf(month) > -1 && scope.day > 30) scope.day = 30;
                    if (month == 2 && scope.day > 29) scope.day = 29;
                    if (scope.year && scope.year.length == 4 &&
                        new Date(scope.year, 1, 29).getMonth() != 1 && month == 2 && scope.day > 28) scope.day = 28;
                }
            }

            function pad(input) {
                if (input || typeof input == 'number') return ('0' + input).slice(-2);
            }

            dayInput.on('click', function() {
                dayInput.select();
            });

            monthInput.on('click', function() {
                monthInput.select();
            });

            yearInput.on('click', function() {
                yearInput.select();
            });

            function getDayInput() {
                return '<input type="tel" class="form-control field-date no-right-border" ng-class="{\'no-left-border\': us}" translate-once-placeholder="DOCUMENTS.PICKER.DAY" ' +
                    'ng-model="day" ng-change="dayChange()" ng-blur="day = pad(day); dayChange()">' + getSeparator();
            }

            function getMonthInput() {
                return '<input type="tel" class="form-control field-date no-right-border" ng-class="{\'no-left-border\': !us}" translate-once-placeholder="DOCUMENTS.PICKER.MONTH" ' +
                    'ng-model="month" ng-change="monthChange()" ng-blur="month = pad(month); monthChange()">' + getSeparator();
            }

            function getYearInput() {
                return '<input type="tel" class="form-control field-date field-date-year no-left-border" translate-once-placeholder="DOCUMENTS.PICKER.YEAR" ' +
                    'ng-model="year" ng-change="yearChange()">';
            }

            function getSeparator() {
                return '<span class="form-control field-time-separator no-left-border no-right-border">{{us ? \'/\' : \'.\'}}</span>';
            }

            function getClear() {
                return '<button type="button" class="btn btn-link clear-button m-l-0" ng-click="clear()" translate-once="DOCUMENTS.CLEAR"></button>';
            }
        }
    }

    angular
        .module('app.documents')
        .service('eezeDatepickerHelper', eezeDatepickerHelper);

    function eezeDatepickerHelper() {
        this.invoker = null;
    }
})();
