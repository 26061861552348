(function() {
    'use strict';

    angular
        .module('app.core')
        .config(translationConfig);

    translationConfig.$inject = ['$translateProvider'];

    /* @ngInject */
    function translationConfig($translateProvider) {
        $translateProvider
            .useStaticFilesLoader({
                prefix: '/assets/languages/',
                suffix: '.json?v=' + window.eezeUiVersion
            })
            .preferredLanguage(localStorage.getItem('eezeLanguage') || 'en')
            .useSanitizeValueStrategy('sanitizeParameters');
    }
})();
