(function() {
    'use strict';

    angular
        .module('app.auth')
        .service('Session', Session);

    Session.$inject = ['$rootScope', '$translate', 'tmhDynamicLocale', 'bugsnag', 'Permissions'];

    /* @ngInject */
    function Session($rootScope, $translate, tmhDynamicLocale, bugsnag, Permissions) {
        var s = this;

        s.jwt = 0;
        s.id = 0;
        s.group = null;
        s.permissions = Permissions.get();
        s.can = Permissions.can;
        s.data = {};
        s.pageLoaded = false;
        s.popoverTogglers = [];
        s.lastState = '';

        s.setData = setData;
        s.removeData = removeData;
        s.refreshToken = refreshToken;
        s.isLoggedIn = isLoggedIn;

        function setData(data) {
            $rootScope.loggedIn = true;

            s.id = data.user.id;
            s.group = data.user.group;
            s.data = data;

            s.permissions = Permissions.set(data.permissions);

            tmhDynamicLocale.set(data.user.locale);
            $translate.use(data.user.language);

            bugsnag.user = {
                id: data.user.id,
                name: data.user.firstName + ' ' + data.user.lastName,
                email: data.user.email
            };

            bugsnag.appVersion = angular.isDefined(data.version.ui) ? data.version.ui : null;

            bugsnag.metaData = {'Session': data};

            localStorage.setItem('eezeLanguage', data.user.language);
        }

        function removeData() {
            $rootScope.loggedIn = false;

            s.jwt = 0;
            s.id = 0;
            s.group = null;
            s.data = {};

            s.permissions = Permissions.clear();

            if (localStorage.getItem('eezeToken')) localStorage.removeItem('eezeToken');
        }

        function refreshToken(jwt) {
            s.jwt = jwt;
            s.data.jwt = jwt;

            localStorage.setItem('eezeToken', jwt);
        }

        function isLoggedIn() {
            return $rootScope.loggedIn;
        }
    }
})();
