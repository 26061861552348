(function() {
    'use strict';

    angular
        .module('app.common')
        .controller('ChooseTemplateController', ChooseTemplateController);

    ChooseTemplateController.$inject = ['$scope', '$element', '$q', '$uibModal', 'Templates', 'logger'];

    /* @ngInject */
    function ChooseTemplateController($scope, $element, $q, $uibModal, Templates, logger) {
        var modal,
            params,
            body,
            scrollPos,
            vm = this;

        vm.filter = {orderBy: 'updated'};
        vm.inProgress = false;
        vm.timeout = 300;

        vm.templates = [];
        vm.templatesMeta = [];

        vm.getTemplates = getTemplates;
        vm.moreTemplates = moreTemplates;
        vm.chooseTemplate = chooseTemplate;

        vm.cancel = cancel;

        $element.bind('click', function() {
            modal = $uibModal.open({
                animation: false,
                templateUrl: 'app/common/choosetemplate/choose-template.html',
                scope: $scope
            });

            modal.rendered
                .then(activate());

            modal.closed
                .then(close);
        });

        function activate() {
            var promises = [getTemplates()];

            body = angular.element('body');
            scrollPos = body[0].scrollTop;

            body.css({
                'width': '100%',
                'overflow-y': 'scroll',
                'position': 'fixed',
                'top': -scrollPos
            });

            return $q.all(promises);
        }

        function close() {
            body.css({
                'width': '',
                'overflow-y': '',
                'position': '',
                'top': ''
            }).scrollTop(scrollPos);
        }

        function getTemplates() {
            vm.inProgress = true;

            params = {
                search: vm.filter.search ? vm.filter.search : null,
                exclude: 'withoutFile',
                order: vm.filter.orderBy == 'name' ? 'asc' : 'desc',
                orderBy: vm.filter.orderBy
            };

            return Templates.api.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.templates = response.data;
                vm.templatesMeta = response.meta;

                vm.inProgress = false;

                return vm.templates;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function moreTemplates() {
            if (vm.templates.length && vm.templatesMeta.cursor.next && !vm.inProgress) {
                vm.inProgress = true;

                params = {
                    orderBy: vm.filter.orderBy,
                    order: vm.filter.orderBy == 'name' ? 'asc' : 'desc',
                    search: vm.filter.search ? vm.filter.search : null,
                    exclude: 'withoutFile',
                    current: vm.templatesMeta.cursor.next,
                    prev: vm.templatesMeta.cursor.current
                };

                return Templates.api.get(params).$promise
                    .then(success);
            }

            function success(response) {
                vm.templates = vm.templates.concat(response.data);
                vm.templatesMeta = response.meta;
                vm.inProgress = false;
            }
        }

        function chooseTemplate(templateId, templateName) {
            modal.close({templateId: templateId, templateName: templateName});
            vm.action({templateId: templateId, templateName: templateName});
        }

        function cancel() {
            modal.dismiss('cancel');
        }
    }
})();
