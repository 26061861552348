(function() {
    'use strict';

    angular
        .module('app.reports')
        .controller('ReportController', ReportController);

    ReportController.$inject = ['$scope', '$q', '$state', '$stateParams', '$filter', 'Session', 'Reports', 'Users', 'globals', 'logger', 'pdf', 'msgBus'];

    /* @ngInject */
    function ReportController($scope, $q, $state, $stateParams, $filter, Session, Reports, Users, globals, logger, pdf, msgBus) {
        var reportId = $stateParams.reportId,
            templateId = $stateParams.templateId,
            vm = this;

        vm.jwt = Session.jwt;
        vm.apiUrl = globals.apiUrl;
        vm.uiUrl = globals.uiUrl;
        vm.report = {};
        vm.fields = [];
        vm.results = [];

        vm.users = [];
        vm.usersFilter = {search: ''};

        vm.inProgress = true;
        vm.resultsInProgress = true;
        vm.loadingMore = false;
        vm.timeout = 300;
        vm.selectedColumnField = null;

        vm.defaults = {popover: {width: 300}, columnPopover: {width: 184}};

        vm.statusValues = [
            {id: 3, label: 'Draft'},
            {id: 4, label: 'Completed'},
            {id: 5, label: 'Reopened'},
            {id: 6, label: 'Archived'}
        ];

        vm.showInFilter = function(item) {
            return item.filterable || item.from === 'template';
        };

        vm.valueChangeEvents = {
            onItemSelect: function() {
                changeFilterValue();
            },
            onItemDeselect: function() {
                changeFilterValue();
            },
            onSelectAll: function() {
                changeFilterValue();
            },
            onDeselectAll: function() {
                changeFilterValue();
            }
        };

        vm.filterDragListeners = {
            'axis': 'y',
            'handle': '.ui-sortable-item-handle',
            'placeholder': 'filter-row ui-sortable-placeholder',
            stop: function() {
                saveReport('filters', true);
            },
            containment: '#filters'
        };

        vm.columnDragListeners = {
            'axis': 'x',
            'ui-floating': true,
            'handle': '.ui-sortable-item-handle',
            'helper': 'clone',
            'cursor': 'move',
            'cursorAt': {left: 80},
            'placeholder': 'ui-sortable-placeholder',
            stop: function() {
                vm.resultsInProgress = true;
                saveReport('columns');
            }
        };

        vm.print = pdf.print;
        vm.download = pdf.download;

        vm.getReport = getReport;
        vm.saveReport = saveReport;
        vm.doSaveTime = doSaveTime;
        vm.deleteFilter = deleteFilter;
        vm.addFilter = addFilter;
        vm.selectField = selectField;
        vm.selectComparator = selectComparator;
        vm.changeFilterValue = changeFilterValue;
        vm.getReportResults = getReportResults;
        vm.moreResults = moreResults;
        vm.showColumnPopover = showColumnPopover;
        vm.deleteColumn = deleteColumn;
        vm.addColumn = addColumn;
        vm.getUsers = getUsers;

        activate();

        function activate() {
            if (!reportId || !templateId) $state.go('app.reports');

            vm.inProgress = true;

            var promises = {
                report: getReport(reportId),
                templateFields: getTemplatesFields(templateId),
                documentsFields: getDocumentsFields(),
                users: getUsers()
            };

            return $q.all(promises)
                .then(finish)
                .catch(fail);

            function finish(response) {
                vm.fields = Reports.transformFields(
                    response.documentsFields.data,
                    response.templateFields
                );

                vm.report = Reports.transformReport(
                    response.report,
                    response.documentsFields.data,
                    response.templateFields,
                    vm.fields
                );

                vm.inProgress = false;

                getReportResults();

                msgBus.emit('breadcrumbs.setObject', {
                    object: vm.report,
                    meta: {new: $stateParams.new}
                });

                msgBus.on('breadcrumbs.action', function(event, data) {
                    vm.saveReport('name', data.name);
                }, $scope);
            }

            function fail(response) {
                vm.inProgress = false;
                logger.error(response.data);

                if (response.status == 403 || response.status == 404) {
                    $state.go('app.reportsError');
                }
            }
        }

        function getDocumentsFields() {

            return Reports.documentsFields({include: 'comparators'})
                .then(success)
                .catch(fail);

            function success(response) {
                return response.data;
            }

            function fail(response) {
                return $q.reject(response);
            }

        }

        function getTemplatesFields(id) {
            var deferred = $q.defer();
            var result = [];

            getTemplatesFieldsRecursive();

            function getTemplatesFieldsRecursive(cursor) {
                var params = {
                    id: id,
                    include: 'comparators',
                    current: cursor || null
                };

                Reports.templatesFields(params)
                    .then(success)
                    .catch(fail);

                function success(response) {
                    result = result.concat(response.data.data);

                    if (response.data.meta.cursor.next) {
                        getTemplatesFieldsRecursive(response.data.meta.cursor.next);
                    } else {
                        deferred.resolve(result);
                    }
                }

                function fail(response) {
                    return deferred.reject(response.data.error.message);
                }
            }

            return deferred.promise;
        }

        function getReportResults() {
            vm.resultsInProgress = true;

            if (vm.results.length) {
                vm.results.meta.next = null;
            }

            var params = {id: reportId};

            return Reports.results(params)
                .then(success)
                .catch(fail);

            function success(response) {
                vm.results.data = response.data.data;
                vm.results.meta = response.data.meta;
                vm.resultsInProgress = false;
            }

            function fail(response) {
                vm.resultsInProgress = false;
                logger.error(response.data);
            }

        }

        function moreResults() {

            if (vm.results.data && vm.results.data.length && vm.results.meta.next && !vm.loadingMore) {

                vm.loadingMore = true;

                var params = {
                    id: reportId,
                    filters: Reports.transformFilters(vm.report.filters.data),
                    current: vm.results.meta.next
                };

                return Reports.results(params)
                    .then(success)
                    .catch(fail);

            }

            function success(response) {
                vm.results.data = vm.results.data.concat(response.data.data);
                vm.results.meta = response.data.meta;
                vm.loadingMore = false;
            }

            function fail(response) {
                vm.loadingMore = false;
                logger.error(response.data);
            }

        }

        function getReport() {
            var params = {
                id: reportId,
                include: 'columns,filters,template,sort'
            };

            return Reports.api.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                if ($stateParams.new) msgBus.emit('breadcrumbs.focus');

                return response.data;
            }

            function fail(response) {
                return $q.reject(response);
            }
        }

        function saveReport(input, orderChange) {
            logger.saving();

            if (input === 'name' && !vm.report.name) return;

            var params = {
                id: vm.report.id,
                name: vm.report.name
            };

            if (input === 'filters') {
                params.filters = Reports.transformFilters(vm.report.filters.data);
            }

            if (input === 'columns') {
                vm.resultsInProgress = true;
                params.columns = vm.report.columns.data;
            }

            return Reports.api.update(params).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.doSaveTime();

                logger.clear();

                if ((input === 'filters' || input === 'columns') && !orderChange) getReportResults();
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function doSaveTime() {
            vm.report.updated = new Date();

            msgBus.emit('breadcrumbs.setObject', {object: vm.report});
        }

        function deleteFilter(filter, index) {
            vm.report.filters.data.splice(index, 1);
            saveReport('filters');
        }

        function addFilter() {
            var filter = {
                field: {id: 0},
                value: [],
                order: 0
            };

            vm.report.filters.data.push(filter);
        }

        function showColumnPopover() {

            var popover = angular.element('#column-popover');

            popover.css({
                left: 'auto',
                top: 45,
                right: 0,
                bottom: 'auto'
            });

            popover.removeClass('left right top bottom');
            popover.addClass('bottom');
        }

        function selectField(filter) {
            for (var i = 0; i < vm.fields.length; i++) {
                if (vm.fields[i].id === filter.field.id) {
                    var field = angular.copy(vm.fields[i]);

                    filter.field = field;
                    filter.field.name = field.options.name;
                    filter.field.group = field.options.table ? field.group : $filter('translate')(field.from);
                    filter.field.options = field.options;

                    filter.value = [];
                    filter.fieldCount = 1;
                    filter.comparator = Object.keys(filter.field.comparators)[0];

                    break;
                }
            }

            saveReport('filters');
        }

        function selectComparator(filter) {

            if (filter.comparator === 'is_empty' || filter.comparator === 'is_not_empty') {
                filter.fieldCount = 0;
            } else {
                filter.fieldCount = filter.field.comparators[filter.comparator].field_count || 0;
            }

            saveReport('filters');
        }

        function changeFilterValue() {
            saveReport('filters');
        }

        function deleteColumn(column, index) {
            vm.resultsInProgress = true;
            vm.report.columns.data.splice(index, 1);
            saveReport('columns');
        }

        function addColumn() {
            vm.resultsInProgress = true;

            var column = {order: vm.report.columns.data.length + 1};

            column.field = vm.selectedColumnField;
            column.field.name = vm.selectedColumnField.options.name;
            column.field.options = vm.selectedColumnField.options;
            column.field.group = vm.selectedColumnField.options.table ? vm.selectedColumnField.group : $filter('translate')(vm.selectedColumnField.from);
            column.field.formulaType = vm.selectedColumnField.type === 'calculations' ? vm.selectedColumnField.options.formulaType : null;

            vm.report.columns.data.push(column);

            vm.selectedColumnField = null;

            saveReport('columns');

            angular.element('#column-popover').addClass('hidden');
        }

        function getUsers(cursor) {

            var params = {
                search: vm.usersFilter.search || null,
                current: cursor || null,
                number: 200
            };

            return Users.api.get(params).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.users = vm.users.concat(response.data);

                if (response.meta.cursor.next) {
                    vm.getUsers(response.meta.cursor.next);
                }
            }

            function fail(response) {
                return $q.reject(response);
            }
        }

    }
})();
