(function() {
    'use strict';

    angular
        .module('app.users')
        .controller('UsersNewController', UsersNewController);

    UsersNewController.$inject = ['$rootScope', '$uibModalInstance', '$filter', 'Users', 'logger'];

    /* @ngInject */
    function UsersNewController($rootScope, $uibModalInstance, $filter, Users, logger) {
        var vm = this;

        vm.newUser = {};
        vm.timeout = 300;

        vm.clearNewUserErrors = clearNewUserErrors;
        vm.clearPasswords = clearPasswords;
        vm.newUserReset = newUserReset;
        vm.createUser = createUser;
        vm.cancel = cancel;

        activate();

        function activate() {
            vm.newUser = {
                status: 1,
                invite: true,
                createAnother: vm.newUser.createAnother || false,
                email: null,
                password: null,
                passwordAgain: null
            };
        }

        function clearNewUserErrors() {
            vm.newUserForm.$setPristine();
            vm.newUserForm.$setUntouched();
            vm.usersError = null;
        }

        function clearPasswords() {
            vm.newUser.password = '';
            vm.newUser.passwordAgain = '';
        }

        function newUserReset() {
            activate();
            vm.clearNewUserErrors();
        }

        function createUser() {
            if (vm.newUserForm.$invalid) return false;
            if (vm.newUser.invite) vm.clearPasswords();

            return Users.api.save(vm.newUser).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                if (vm.newUser.invite) {
                    logger.success($filter('translate')('USERS.USER_INVITED'));
                } else {
                    logger.success($filter('translate')('USERS.SAVED'));
                }

                $rootScope.$broadcast('newUserData', {user: response.data});

                if (!vm.newUser.createAnother) {
                    $uibModalInstance.close();
                } else {
                    vm.newUserReset();
                }
            }

            function fail(response) {
                vm.usersError = response.data.error.message;
                vm.clearPasswords();
            }
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
