(function() {
    'use strict';

    angular.module('app.reports', [
        'app.core',
        'app.layout',
        'app.common',
        'angularjs-dropdown-multiselect'
    ]);
})();
