(function() {
    'use strict';

    angular
        .module('app.reports')
        .controller('ReportsController', ReportsController);

    ReportsController.$inject = ['$filter', '$q', '$state', 'Reports', 'Session', 'logger', 'SweetAlert', 'listStorage'];

    /* @ngInject */
    function ReportsController($filter, $q, $state, Reports, Session, logger, SweetAlert, listStorage) {
        var request, params,
            vm = this;

        vm.permissions = Session.permissions;

        vm.filter = {orderBy: 'created', order: 'desc'};

        vm.reports = [];
        vm.reportsMeta = [];
        vm.newReport = {};

        vm.getReports = getReports;
        vm.moreReports = moreReports;

        vm.deleteReport = deleteReport;
        vm.deleteReportConfirm = deleteReportConfirm;
        vm.createReport = createReport;
        vm.duplicateReport = duplicateReport;

        vm.inProgress = false;
        vm.timeout = 500;

        activate();

        function activate() {
            listStorage.clearExcept('eezeReportsListSearch');
            var promises = [getReports()];

            return $q.all(promises);
        }

        function getReports(filter) {
            if (filter) {
                listStorage.setItem('eezeReportsListSearch', vm.filter);
            } else if (localStorage.getItem('eezeReportsListSearch')) {
                vm.filter = listStorage.getItem('eezeReportsListSearch');
            }

            vm.reports = [];
            vm.inProgress = true;

            params = {
                search: vm.filter.search ? vm.filter.search : null,
                order: vm.filter.order,
                orderBy: vm.filter.orderBy,
                include: 'template'
            };

            if (request) request.$cancelRequest();
            request = Reports.api.get(params);

            return request.$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.reports = response.data;
                vm.reportsMeta = response.meta;

                vm.inProgress = false;

                return vm.reports;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function moreReports() {
            if (vm.reports.length && vm.reportsMeta.cursor.next && !vm.inProgress) {

                vm.inProgress = true;

                params = {
                    orderBy: vm.filter.orderBy,
                    order: vm.filter.order,
                    search: vm.filter.search ? vm.filter.search : null,
                    current: vm.reportsMeta.cursor.next,
                    prev: vm.reportsMeta.cursor.current,
                    include: 'template'
                };

                return Reports.api.get(params).$promise
                    .then(success);
            }

            function success(response) {
                vm.reports = vm.reports.concat(response.data);
                vm.reportsMeta = response.meta;
                vm.inProgress = false;
            }
        }

        function deleteReport(report) {
            if (!vm.permissions.reports.delete) {
                logger.error($filter('translate')('ROUTE_PERMISSIONS_ERROR'));
                return;
            }

            SweetAlert.swal({
                title: $filter('translate')('REPORTS.ARE_YOU_SURE'),
                text: $filter('translate')('REPORTS.ABOUT_TO_DELETE'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: $filter('translate')('REPORTS.DELETE'),
                closeOnConfirm: true
            }, function(isConfirm) {
                if (isConfirm) {
                    deleteReportConfirm(report);
                }
            });
        }

        function deleteReportConfirm(report) {
            logger.saving();

            return Reports.api.delete({id: report.id}).$promise
                .then(success)
                .catch(fail);

            function success() {
                vm.reports.splice(vm.reports.indexOf(report), 1);

                logger.clear();
                logger.success($filter('translate')('REPORTS.DELETED'));
            }

            function fail(response) {
                logger.clear();
                logger.warning(response.data.error.message);
            }
        }

        function createReport(templateId, templateName) {
            if (!vm.permissions.reports.create) {
                logger.error($filter('translate')('ROUTE_PERMISSIONS_ERROR'));
                return;
            }

            logger.loading();

            vm.newReport = {
                name: templateName + ' ' + $filter('date')(new Date(), 'short'),
                templateId: templateId
            };

            return Reports.api.save(vm.newReport).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                logger.clear();
                logger.success($filter('translate')('REPORTS.SAVED'));

                $state.go('report', {
                    reportId: response.data.id,
                    templateId: templateId,
                    new: true
                });
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data);
            }
        }

        function duplicateReport(report) {
            if (!vm.permissions.reports.create) {
                logger.error($filter('translate')('ROUTE_PERMISSIONS_ERROR'));
                return;
            }

            logger.loading();

            return Reports.duplicate({
                id: report.id,
                include: 'template'
            })
                .then(success)
                .catch(fail);

            function success(response) {
                vm.reports.unshift(response.data.data);

                logger.clear();
                logger.success($filter('translate')('REPORTS.SAVED'));
            }

            function fail(response) {
                logger.clear();
                logger.error(response.data.data);
            }
        }
    }
})();
