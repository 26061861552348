(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('fieldPosition', fieldPosition);

    function fieldPosition() {
        return {
            restrict: 'A',
            link: link
        };

        function link(scope, element, attrs) {
            var field, style;

            position();

            function position(newValue, oldValue) {
                if (angular.isDefined(newValue) && newValue === oldValue) return false;

                field = JSON.parse(attrs.fieldPosition);

                if (field.type) {
                    if (field.type === 'table' && attrs.rowsCount) { // empty table
                        style = {
                            'position': 'absolute',
                            'top': 0,
                            'left': 0,
                            'width': '100%',
                            'height': 100 / attrs.rowsCount + '%'
                        };
                    } else { // everything else
                        style = {
                            'position': 'absolute',
                            'top': field.designer.top + '%' || 0,
                            'left': field.designer.left + '%' || 0,
                            'width': field.designer.width + '%' || 0,
                            'height': field.designer.height + '%' || 0
                        };

                        if (field.options.fontSize) style.fontSize = field.options.fontSize;
                    }
                } else if (field.length) { // table row
                    style = {
                        'position': 'absolute',
                        'top': 100 / attrs.rowsCount * field[0].options.row + '%',
                        'left': 0,
                        'width': '100%',
                        'height': 100 / attrs.rowsCount + '%'
                    };
                } else if (field.field) { // column in table row
                    style = {
                        'position': 'absolute',
                        'top': 0,
                        'left': field.left + '%',
                        'width': field.width + '%',
                        'height': '100%'
                    };
                }

                element.css(style);

                if (angular.isUndefined(newValue) && !attrs.noWatch) watch();
            }

            function watch() {
                scope.$watch(function() {
                    return attrs.fieldPosition;
                }, position);
            }
        }
    }
})();
