(function() {
    'use strict';

    angular
        .module('app.templates')
        .service('Templates', Templates);

    Templates.$inject = ['$resource', '$http', 'globals'];

    /* @ngInject */
    function Templates($resource, $http, globals) {
        var formData;
        var s = this;

        s.duplicate = duplicate;

        s.api = $resource(globals.apiUrl + '/templates/:id', {id: '@id'}, {update: {method: 'PUT'}});

        s.upload = upload;
        s.transform = {pages: transformPages};

        function duplicate(params) {
            return $http.post(globals.apiUrl + '/templates/' + params.id + '/duplicate', params);
        }

        function upload(file, templateId) {
            formData = new FormData();
            formData.append('file', file);

            return $http.post(globals.apiUrl + '/templates/' + templateId + '/file', formData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined},
                params: {'include': 'images'}
            });
        }

        function transformPages(pages) {
            return Object.keys(pages).map(function(key) {
                pages[key].number = key;
                return pages[key];
            });
        }
    }
})();
