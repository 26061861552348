(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('stickyHeader', stickyHeader);

    function stickyHeader($document) {
        return {
            restrict: 'A',
            link: link
        };

        function link(scope, element) {
            var stickyHeaderTop = 140;
            var scrollTop = 0;
            var doc = $document;

            doc.bind('scroll', function() {
                scrollTop = doc.scrollTop();
                if (scrollTop > stickyHeaderTop) {
                    element.show();
                } else {
                    element.hide();
                }
            });

            scope.$on('$destroy', function() {
                doc.unbind('scroll');
            });
        }
    }
})();
