(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('sidebar', sidebar);

    function sidebar() {
        return {
            restrict: 'E',
            templateUrl: 'app/layout/sidebar.html',
            bindToController: true,
            controller: 'SidebarController',
            controllerAs: 'vm',
            scope: {},
            link: link
        };

        function link(scope, element) {
            var $sidebarInner = element.find('.sidebar-inner');
            var $dropdownElement = element.find('.sidebar-dropdown a');

            element.addClass('sidebar');
            $dropdownElement.click(dropdown);

            function dropdown(e) {
                var dropClass = 'dropy';

                e.preventDefault();
                if (!$dropdownElement.hasClass(dropClass)) {
                    $sidebarInner.show();
                    $dropdownElement.addClass(dropClass);
                } else if ($dropdownElement.hasClass(dropClass)) {
                    $dropdownElement.removeClass(dropClass);
                    $sidebarInner.hide();
                }
            }
        }
    }
})();
