(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('pdfFieldRenderer', pdfFieldRenderer);

    function pdfFieldRenderer() {
        return {
            restrict: 'EA',
            templateUrl: 'app/documents/fill/pdf-filler/pdf-field-renderer/pdfField.html',
            scope: {
                vm: '=',
                field: '='
            }
        };
    }
})();
