(function() {
    'use strict';

    angular
        .module('app.account')
        .service('Account', Account);

    Account.$inject = ['$resource', 'globals'];

    /* @ngInject */
    function Account($resource, globals) {
        var s = this;

        s.locales = $resource(globals.apiUrl + '/locales/:id', {id: '@id'});
        s.languages = $resource(globals.apiUrl + '/languages/:id', {id: '@id'});
    }
})();
