(function() {
    'use strict';

    angular
        .module('app.documents')
        .run(appRun);

    appRun.$inject = ['$filter', 'routerHelper'];

    /* @ngInject */
    function appRun($filter, routerHelper) {
        routerHelper.configureStates(getStates());

        function getStates() {
            return [
                {
                    state: 'app.documentsError',
                    parent: 'app',
                    config: {
                        url: '/documents/error',
                        templateUrl: 'app/documents/error.html',
                        title: $filter('translate')('NAV.NOT_FOUND'),
                        settings: {
                            nav: 0,
                            breadcrumbs: {parent: 'app.documents', self: true}
                        }
                    }
                },
                {
                    state: 'filler',
                    config: {
                        url: '/documents/:id',
                        params: {id: null, duplicate: false, new: false, templateType: ''},
                        templateUrl: 'app/documents/fill/fill.html',
                        controller: 'FillController',
                        controllerAs: 'vm',
                        title: $filter('translate')('NAV.FILL'),
                        settings: {
                            nav: 0,
                            hideSidebar: true,
                            breadcrumbs: {parent: 'app.documents'}
                        }
                    }
                },
                {
                    state: 'filler.pdf',
                    parent: 'filler',
                    config: {
                        url: '/pdf',
                        params: {id: null, duplicate: false, new: false},
                        templateUrl: 'app/documents/fill/pdf-filler/pdfFiller.html',
                        controller: 'PdfFillController',
                        controllerAs: 'vm',
                        title: $filter('translate')('NAV.FILL'),
                        settings: {
                            nav: 0,
                            hideSidebar: true,
                            breadcrumbs: {parent: 'app.documents'}
                        }
                    }
                },
                {
                    state: 'filler.pdf.signature',
                    config: {
                        url: '/:fieldId/signature',
                        params: {id: null, fieldId: null},
                        title: $filter('translate')('NAV.SIGNATURE'),
                        settings: {
                            nav: 0,
                            breadcrumbs: {parent: 'app.documents'}
                        },
                        views: {
                            'signature@filler': {
                                templateUrl: 'app/documents/fill/signature/signature.html',
                                controller: 'SignatureController',
                                controllerAs: 'vm'
                            }
                        }
                    }
                },
                {
                    state: 'filler.list',
                    parent: 'filler',
                    config: {
                        url: '/list',
                        params: {id: null, duplicate: false, new: false},
                        templateUrl: 'app/documents/fill/list-filler/listFiller.html',
                        controller: 'ListFillController',
                        controllerAs: 'vm',
                        title: $filter('translate')('NAV.FILL'),
                        settings: {
                            nav: 0,
                            hideSidebar: true,
                            breadcrumbs: {parent: 'app.documents'}
                        }
                    }
                },
                {
                    state: 'filler.list.signature',
                    config: {
                        url: '/:fieldId/signature',
                        params: {id: null, fieldId: null},
                        title: $filter('translate')('NAV.SIGNATURE'),
                        settings: {
                            nav: 0,
                            breadcrumbs: {parent: 'app.documents'}
                        },
                        views: {
                            'signature@filler': {
                                templateUrl: 'app/documents/fill/signature/signature.html',
                                controller: 'SignatureController',
                                controllerAs: 'vm'
                            }
                        }
                    }
                }
            ];
        }
    }
})();
