(function() {
    'use strict';

    angular
        .module('app.templates')
        .service('Fields', Fields)
        .filter('getIcon', getIcon);

    Fields.$inject = ['$resource', '$filter', '$q', 'globals'];

    /* @ngInject */
    function Fields($resource, $filter, $q, globals) {
        var s = this;

        s.defaults = {
            field: {duplicateMargin: 1},
            tag: {class: ['tag-field']}
        };
        s.formulaIndex = 0; // for keeping formula fields unique

        s.available = $resource(globals.apiUrl + '/templates/:templateId/availableFields');
        s.fieldsOnPage = $resource(globals.apiUrl + '/templates/:templateId/pages/:page/fields',
            {templateId: '@templateId', page: '@page'}
        );
        s.fields = $resource(globals.apiUrl + '/templates/:templateId/fields',
            {templateId: '@templateId'}
        );
        s.duplicate = $resource(globals.apiUrl + '/fields/:id/duplicate',
            {id: '@id'}
        );
        s.document = $resource(globals.apiUrl + '/documents/:documentId/fields',
            {documentId: '@documentId'}
        );
        s.order = $resource(globals.apiUrl + '/fields/:id/order', {id: '@id'}, {update: {method: 'PUT'}});
        s.tables = $resource(globals.apiUrl + '/tables/:id', {id: '@id'}, {update: {method: 'PUT'}});
        s.rows = $resource(globals.apiUrl + '/tables/:tableId/row/:rowId', {tableId: '@tableId', rowId: '@rowId'}, {update: {method: 'PUT'}});
        s.api = $resource(globals.apiUrl + '/fields/:id', {id: '@id'}, {update: {method: 'PUT'}});

        s.getAutocompleteFields = $resource(globals.apiUrl + '/templates/:templateId/formula/:fieldId/autocomplete',
            {templateId: '@templateId', fieldId: '@fieldId'}
        );

        s.getSettingsNameAutocomplete = $resource(globals.apiUrl + '/templates/:templateId/settings/name/autocomplete',
            {templateId: '@templateId'}
        );

        s.transform = {
            fromDesigner: {
                forSave: fromDesignerForSave,
                forDuplicate: fromDesignerForDuplicate,
                forPopover: fromDesignerForPopover,
                fromPopover: {forUpdate: fromDesignerFromPopoverForUpdate}
            },
            forDesigner: {forPopulate: forDesignerForPopulate},
            forList: {forPopulate: forListForPopulate},
            forFill: {
                forPopulate: forFillForPopulate,
                removeTableRows: forFillRemoveTableRows,
                constructErrorList: forFillConstructErrorList
            },
            forToolbar: forToolbar,
            forFormula: {
                forAutoComplete: forFormulaForAutoComplete,
                forSave: forFormulaForSave,
                forPopover: forFormulaForPopover
            },
            forTable: {forPopulate: forTableForPopulate},
            forSync: forSync
        };
        s.sortForView = sortForView;

        s.calcToPrecentage = function(position, droppableHeight, droppableWidth) {
            var object = {};

            if (position.top) object.top = position.top * 100 / droppableHeight;
            if (position.left) object.left = position.left * 100 / droppableWidth;
            if (position.width) object.width = position.width * 100 / droppableWidth;
            if (position.height) object.height = position.height * 100 / droppableHeight;

            return object;
        };

        s.calcToPixels = function(value, droppable, from) {
            var d = angular.element(droppable);

            if (from === 'width' || from === 'left') {
                return value * d.width() / 100;
            }
            return value * d.height() / 100;
        };

        s.onePixelInPrecentage = function(droppable, from) {
            var d = angular.element(droppable);

            if (from === 'width' || from === 'left') {
                return 100 / d.width();
            }
            return 100 / d.height();
        };

        function fromDesignerForSave(field, templateId, page) {
            var object = {
                type: field.type,
                options: field.options,
                designer: field.designer,
                list: field.list
            };

            if (templateId) object.templateId = templateId;
            if (page) object.page = page;

            return object;
        }

        function fromDesignerForDuplicate(field, backgroundHeight) {
            var top = field.designer.top + field.designer.height + s.defaults.field.duplicateMargin;

            var object = {
                field: {
                    id: field.id,
                    type: field.type,
                    designer: {
                        left: field.designer.left,
                        top: top,
                        width: field.designer.width,
                        height: field.designer.height
                    },
                    options: field.options,
                    list: field.list
                },
                coordinates: {
                    left: field.designer.left,
                    top: top
                }
            };

            if (top + object.field.designer.height > backgroundHeight) {
                top = field.designer.top - field.designer.height - s.defaults.field.duplicateMargin;

                object.field.designer.top = top;
                object.coordinates.y = top;
            }

            return object;
        }

        function fromDesignerForPopover(field) {
            if (field.options.hasOwnProperty('name')) {
                field.options.name = field.options.name === true ? '' : field.options.name;
            }
            if (field.options.hasOwnProperty('placeholder')) {
                field.options.placeholder = field.options.placeholder === true ? '' : field.options.placeholder;
            }
            if (field.options.hasOwnProperty('value')) {
                field.options.value = field.options.value === true ? '' : field.options.value;
            }
            if (field.options.hasOwnProperty('default') && (field.type == 'text' || field.type == 'number' || field.type == 'select')) {
                field.options.default = field.options.default === false ? '' : field.options.default;
            }
            if (field.options.hasOwnProperty('radios')) {
                field.options.radios = _.map(field.options.radios, fromDesignerForPopover);
            }

            return field;
        }

        function fromDesignerFromPopoverForUpdate(field) {
            if (field.options.hasOwnProperty('name')) {
                field.options.name = typeof field.options.name === 'undefined' ? '' : field.options.name;
            }
            if (field.options.hasOwnProperty('placeholder')) {
                field.options.placeholder = typeof field.options.placeholder === 'undefined' ? '' : field.options.placeholder;
            }
            if (field.options.hasOwnProperty('value')) {
                field.options.value = typeof field.options.value === 'undefined' ? '' : field.options.value;
            }
            if (field.options.hasOwnProperty('default') && (field.type == 'text' || field.type == 'number' || field.type == 'select')) {
                field.options.default = typeof field.options.default === 'undefined' ? '' : field.options.default;
            }
            if (field.options.hasOwnProperty('syncId')) {
                field.options.syncId = typeof field.options.syncId === 'boolean' ? null : field.options.syncId;
            }

            return field;
        }

        function forDesignerForPopulate(fields, filter) {
            var array = [];

            if (filter) {
                fields = _.reject(fields, filter);
            }

            for (var i = 0; i < fields.length; i++) {
                var object = {
                    id: fields[i].id,
                    type: fields[i].type,
                    options: fields[i].options.data,
                    designer: fields[i].designer ? fields[i].designer.data : null,
                    list: fields[i].list ? fields[i].list.data : null,
                    icon: $filter('getIcon')(fields[i].type)
                };

                array.push(fromDesignerForPopover(object));
            }

            return array;
        }

        function forListForPopulate(fields) {
            var array = [];

            for (var i = 0; i < fields.length; i++) {
                var object = {
                    id: fields[i].id,
                    type: fields[i].type,
                    options: fields[i].options.data || fields[i].options,
                    list: fields[i].list ? (fields[i].list.data || fields[i].list) : {},
                    icon: $filter('getIcon')(fields[i].type)
                };

                if (fields[i].hasOwnProperty('images')) {
                    object.images = fields[i].images.data;
                }

                if (fields[i].hasOwnProperty('files')) {
                    object.files = fields[i].files.data;
                }

                if (object.options.hasOwnProperty('name')) {
                    object.options.name = object.options.name === true ? '' : object.options.name;
                }
                if (object.options.hasOwnProperty('placeholder')) {
                    object.options.placeholder = object.options.placeholder === true ? '' : object.options.placeholder;
                }
                if (object.options.hasOwnProperty('value')) {
                    object.options.value = object.options.value === true ? '' : object.options.value;
                }
                if (object.options.hasOwnProperty('default') && (fields[i].type == 'text' || fields[i].type == 'number' || fields[i].type == 'select')) {
                    object.options.default = object.options.default === false ? '' : object.options.default;
                }

                if (object.type == 'table' && object.options.hasOwnProperty('rows')) {
                    for (var j = 0; j < object.options.rows.length; j++) {
                        object.options.rows[j] = forListForPopulate(object.options.rows[j]);
                    }
                }

                array.push(object);
            }

            return array;
        }

        function forFillForPopulate(fields, values) {
            for (var i = 0; i < fields.length; i++) {
                var value = values[fields[i].id] ? values[fields[i].id].value : null;

                if (fields[i].type === 'date' && value) {
                    values[fields[i].id].value = new Date(value);
                }

                if (fields[i].type === 'calculations' &&
                    fields[i].options.formulaType === 'number' &&
                    typeof value === 'number'
                ) {
                    values[fields[i].id].value = $filter('number')(value);
                }

                if (fields[i].type === 'table') {
                    for (var j = 0; j < fields[i].options.rows.length; j++) {
                        forFillForPopulate(fields[i].options.rows[j], values);
                    }
                }
            }
        }

        function forFillRemoveTableRows(fields, table, row) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].id == table) {
                    var rows = fields[i].options.rows;

                    rows.splice(row, 1);

                    for (var j = 0; j < rows.length; j++) {
                        rows[j].row = j;

                        for (var k = 0; k < rows[j].length; k++) {
                            rows[j][k].options.row = j;
                        }
                    }
                }
            }

            return fields;
        }

        function forFillConstructErrorList(requiredFromServer) {
            var errorString = '<ul>',
                errorFields = [];

            for (var i = 0; i < requiredFromServer.length; i++) {
                appendError(requiredFromServer[i]);
            }

            function appendError(field) {
                errorFields.push(field);

                errorString += '<li>';
                errorString += field.options.data.name || $filter('translate')('FIELDS.A_FIELD');
                errorString += ' ';
                errorString += $filter('translate')('FIELDS.IS_REQUIRED');
                errorString += '</li>';
            }

            errorString += '</ul>';

            return {
                string: errorString,
                fields: errorFields
            };
        }

        function forToolbar(fields) {
            var array = [];

            for (var i = 0; i < fields.length; i++) {
                var object = {
                    type: fields[i].type,
                    options: fields[i].options.data,
                    designer: fields[i].designer.data,
                    list: fields[i].list.data
                };

                object.name = $filter('translate')('FIELDS.TYPES.' + fields[i].type); // for the toolbox
                object.icon = $filter('getIcon')(fields[i].type); // for the toolbox

                array.push(object);
            }

            array.sort(function(a, b) {
                return (a.list.toolbar || a.designer.toolbar) - (b.list.toolbar || b.designer.toolbar);
            });

            return array;
        }

        function forFormulaForAutoComplete(fields) {
            var array = [];

            for (var i = 0; i < fields.length; i++) {
                if (typeof fields[i].options.data.name == 'string') {
                    array.push({
                        id: fields[i].id,
                        options: fields[i].options.data,
                        type: fields[i].type,
                        name: fields[i].options.data.name,
                        index: s.formulaIndex++,
                        class: s.defaults.tag.class
                    });
                }
            }

            return array;
        }

        function forFormulaForSave(tags, fields) {
            var spacer = ' ',
                add = '',
                string = '';

            for (var i = 0; i < tags.length; i++) {
                var tag = tags[i].name;

                if (tag === 'SUM') {
                    string += tag;

                    if (tags[1]) {
                        string += getTableField(tags[1]);
                    }

                    break;
                }

                if (tag === 'SUMIF') {
                    string += tag;

                    if (tags[1]) {
                        string += getTableField(tags[1]);
                    }

                    if (tags[2]) {
                        string += getTableField(tags[2]);
                    }

                    if (tags[3]) {
                        for (var j = 3; j < tags.length; j++) {
                            string += spacer;
                            string += tags[j].name;
                        }
                    }

                    break;
                }

                if (tags[i].id) add = '{!!' + tags[i].id + '!!}';
                else add = tags[i].name;

                if (string === '') spacer = '';
                else spacer = ' ';

                string = string + spacer + add;
            }

            return string;

            function getTableField(tag) {
                var result = '';

                if (typeof tag.id === 'string' && tag.id.split(',').length > 1) {
                    result = tag.id;
                } else {
                    _(fields)
                        .filter(['type', 'table'])
                        .forEach(function(table) {
                            var sumField = _.find(table.options.columns, ['field.id', tag.id]);

                            if (sumField) {
                                result = sumField.field.options.table + ',' + sumField.field.options.column;
                            }
                        });
                }

                return ' {!!' + result + '!!}';
            }
        }

        function forFormulaForPopover(string, fields) {
            var array = [],
                object = {},
                parse = string.split(' '), // split the string to tags
                deferred = $q.defer();

            for (var i = 0; i < parse.length; i++) { // compose the array of tags to be populated in the view
                if (!parse[i].length) continue;

                var field = parse[i].match(/{!!(\d+[,]*\d*)!!}/);

                object.index = s.formulaIndex++;
                object.id = field ? field[1] : null;
                object.name = field ? fields[field[1]].name : parse[i];
                object.class = field ? s.defaults.tag.class : '';

                array.push(angular.copy(object));
            }

            deferred.resolve(array);

            return deferred.promise;
        }

        function sortForView(fields, state) {
            var stateOptions = '';

            if (state == 'mapper' || state == 'mapper.hierarchy') {
                var disabled = [];

                if (state == 'mapper') stateOptions = 'designer';
                if (state == 'mapper.hierarchy') stateOptions = 'list';

                for (var i = 0; i < fields.length; i++) {
                    if (!fields[i][stateOptions].toolbar) {
                        disabled.push(fields[i]);
                        fields.splice(i, 1);
                    }
                }

                fields.sort(function(a, b) {
                    return a[stateOptions].toolbar - b[stateOptions].toolbar;
                });

                return fields.concat(disabled);
            }
        }

        function forTableForPopulate(fields, field, remove) {
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].type === 'table') {
                    if (field && field.options.table === fields[i].id) {
                        if (remove) {
                            fields[i].options.columnsFieldCount--;

                            delete fields[i].options.columns[field.options.column]['field'];
                            fields[i].options.rows[0][field.options.column] = {};
                        } else {
                            fields[i].options.columnsFieldCount++;
                            fields[i].options.columns[field.options.column].field = field;

                            if (!fields[i].options.rows[0]) {
                                fields[i].options.rows[0] = [];
                            }

                            fields[i].options.rows[0][field.options.column] = field;
                        }
                    }

                    if (!remove) {
                        fields[i].options.columnsFieldCount = 0;

                        for (var k = 0; k < fields[i].options.columns.length; k++) {
                            delete fields[i].options.columns[k]['field'];
                        }

                        if (fields[i].options.rows.length) {
                            for (var j = 0; j < fields[i].options.rows[0].length; j++) {
                                if (!angular.equals(fields[i].options.rows[0][j], {})) {
                                    fields[i].options.columnsFieldCount++;
                                    fields[i].options.columns[fields[i].options.rows[0][j].options.column].field = fields[i].options.rows[0][j];
                                }
                            }
                        }
                    }
                }
            }
        }

        function forSync(fields, currentField) {
            var array = [];

            for (var i = 0; i < fields.length; i++) {
                if (typeof fields[i].options.data.name === 'string' &&
                    fields[i].options.data.name.length > 0 &&
                    fields[i].id !== currentField.id &&
                    fields[i].options.data.syncId != currentField.id &&
                    !fields[i].options.data.table) {
                    array.push(fields[i]);
                }
            }

            return array;
        }
    }

    function getIcon() {
        return function(type) {
            switch (type) {

                /* eslint-disable no-unreachable */
                case 'calculations':
                    return 'formula.svg';
                    break;
                case 'checkbox':
                    return 'checkbox.svg';
                    break;
                case 'date':
                    return 'date.svg';
                    break;
                case 'dateTime':
                    return 'date.svg';
                    break;
                case 'number':
                    return 'number.svg';
                    break;
                case 'radio':
                case 'radioGroup':
                    return 'radio.svg';
                    break;
                case 'select':
                    return 'dropdown.svg';
                    break;
                case 'separator':
                    return 'separator.svg';
                    break;
                case 'signature':
                    return 'signature.svg';
                    break;
                case 'text':
                    return 'txt.svg';
                    break;
                case 'time':
                    return 'time.svg';
                    break;
                case 'table':
                    return 'grid.svg';
                    break;
                case 'image':
                    return 'image.svg';
                    break;
                case 'file':
                    return 'file.svg';
                    break;
                default:
                    return false;
                    break;

                /* eslint-enable no-unreachable */
            }
        };
    }
})();
