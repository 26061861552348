(function() {
    'use strict';

    angular
        .module('app.templates')
        .run(appRun);

    appRun.$inject = ['$filter', 'routerHelper'];

    /* @ngInject */
    function appRun($filter, routerHelper) {
        routerHelper.configureStates([
            {
                state: 'app.templateError',
                parent: 'app',
                config: {
                    url: '/templates/error',
                    templateUrl: 'app/templates/error.html',
                    title: $filter('translate')('NAV.NOT_FOUND'),
                    settings: {
                        nav: 0,
                        breadcrumbs: {parent: 'app.templates', self: true}
                    }
                }
            },
            {
                state: 'mapper',
                parent: 'app.templates',
                config: {
                    url: '/templates/:id',
                    params: {new: false},
                    title: $filter('translate')('NAV.MAP_FIELDS'),
                    settings: {
                        nav: 0,
                        hideSidebar: true,
                        breadcrumbs: {parent: 'app.templates'}
                    },
                    views: {
                        '@': {
                            templateUrl: 'app/templates/mapper/mapper.html',
                            controller: 'MapperController',
                            controllerAs: 'vm'
                        },
                        'toolbox@mapper': {templateUrl: 'app/templates/mapper/toolbox/toolbox.html'},
                        'toolbox-bottom@mapper': {templateUrl: 'app/templates/mapper/toolbox/bottom/footer.html'}
                    }
                }
            },
            {
                state: 'mapper.pdf',
                config: {
                    url: '/page/:pageId',
                    params: {new: false, pageId: '1'},
                    title: $filter('translate')('NAV.MAP_FIELDS'),
                    settings: {
                        nav: 0,
                        hideSidebar: true,
                        breadcrumbs: {parent: 'app.templates'}
                    },
                    views: {
                        'mapper-top@mapper': {templateUrl: 'app/templates/mapper/top/pdf.html'},
                        'mapper-center@mapper': {templateUrl: 'app/templates/mapper/center/pdf/pdf.html'},
                        'toolbox-top@mapper': {templateUrl: 'app/templates/mapper/toolbox/top/switcher.html'},
                        'toolbox-center@mapper': {templateUrl: 'app/templates/mapper/toolbox/center/fields.html'},
                        'toolbox-bottom@mapper': {templateUrl: 'app/templates/mapper/toolbox/bottom/footer.html'}
                    }
                }
            },
            {
                state: 'mapper.list',
                config: {
                    url: '/list',
                    params: {new: false},
                    title: $filter('translate')('NAV.MAP_FIELDS'),
                    settings: {
                        nav: 0,
                        hideSidebar: true,
                        breadcrumbs: {parent: 'app.templates'}
                    },
                    views: {
                        'mapper-center@mapper': {templateUrl: 'app/templates/mapper/center/list/list.html'},
                        'toolbox-top@mapper': {templateUrl: 'app/templates/mapper/toolbox/top/list.html'},
                        'toolbox-center@mapper': {templateUrl: 'app/templates/mapper/toolbox/center/list-fields.html'}
                    }
                }
            },
            {
                state: 'mapper.list.field',
                config: {
                    url: '/field/:fieldId',
                    title: $filter('translate')('NAV.MAP_FIELDS'),
                    settings: {
                        nav: 0,
                        hideSidebar: true,
                        breadcrumbs: {parent: 'app.templates'}
                    },
                    views: {
                        'toolbox-top@mapper': {templateUrl: 'app/templates/mapper/toolbox/top/options.html'},
                        'toolbox-center@mapper': {templateUrl: 'app/templates/mapper/toolbox/center/options.html'}
                    }
                }
            },
            {
                state: 'mapper.pdf.field',
                config: {
                    url: '/field/:fieldId',
                    title: $filter('translate')('NAV.MAP_FIELDS'),
                    settings: {
                        nav: 0,
                        hideSidebar: true,
                        breadcrumbs: {parent: 'app.templates'}
                    },
                    views: {
                        'toolbox-top@mapper': {templateUrl: 'app/templates/mapper/toolbox/top/options.html'},
                        'toolbox-center@mapper': {templateUrl: 'app/templates/mapper/toolbox/center/options.html'}
                    }
                }
            },
            {
                state: 'mapper.pdf.hierarchy',
                config: {
                    url: '/hierarchy',
                    title: $filter('translate')('NAV.MAP_FIELDS'),
                    settings: {
                        nav: 0,
                        hideSidebar: true,
                        breadcrumbs: {parent: 'app.templates'}
                    },
                    views: {'toolbox-center@mapper': {templateUrl: 'app/templates/mapper/toolbox/center/hierarchy.html'}}
                }
            },
            {
                state: 'mapper.list.settings',
                config: {
                    url: '/settings',
                    title: $filter('translate')('NAV.MAP_FIELDS'),
                    settings: {
                        nav: 0,
                        hideSidebar: true,
                        breadcrumbs: {parent: 'app.templates'}
                    },
                    views: {
                        'toolbox-top@mapper': {templateUrl: 'app/templates/mapper/toolbox/top/settings.html'},
                        'toolbox-center@mapper': {templateUrl: 'app/templates/mapper/toolbox/center/settings.html'}
                    }
                }
            },
            {
                state: 'mapper.pdf.settings',
                config: {
                    url: '/settings',
                    title: $filter('translate')('NAV.MAP_FIELDS'),
                    settings: {
                        nav: 0,
                        hideSidebar: true,
                        breadcrumbs: {parent: 'app.templates'}
                    },
                    views: {
                        'toolbox-top@mapper': {templateUrl: 'app/templates/mapper/toolbox/top/settings.html'},
                        'toolbox-center@mapper': {templateUrl: 'app/templates/mapper/toolbox/center/settings.html'}
                    }
                }
            }
        ]);
    }
})();
