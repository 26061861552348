(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('blurOnEnter', blurOnEnter);

    function blurOnEnter() {
        return {
            restrict: 'A',
            link: function(scope, element) {
                element.bind('keypress', function(event) {
                    if (event.which === 13) {
                        element.blur();
                        event.preventDefault();
                    }
                });
            }
        };
    }
})();
