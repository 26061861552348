(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('listPlaceholder', listPlaceholder);

    function listPlaceholder() {
        return {
            restrict: 'E',
            scope: {
                heading: '@',
                text: '@',
                image: '@'
            },
            template: '<div class="no-data">' +
                '<h3 ng-if="heading">{{heading}}</h3>' +
                '<h4 ng-if="text">{{text}}</h4>' +
                '<div ng-if="image" class="picto" ng-style="picto"></div>' +
            '</div>',
            link: function(scope) {
                scope.picto = {'background-image': 'url(/assets/img/' + scope.image + ')'};
            }
        };
    }
})();
