(function() {
    'use strict';

    angular
        .module('app.reports')
        .run(appRun);

    appRun.$inject = ['$filter', 'routerHelper'];

    /* @ngInject */
    function appRun($filter, routerHelper) {
        routerHelper.configureStates(getStates());

        function getStates() {
            return [
                {
                    state: 'app.reportsError',
                    parent: 'app',
                    config: {
                        url: '/reports/error',
                        templateUrl: 'app/reports/error.html',
                        title: $filter('translate')('NAV.NOT_FOUND'),
                        settings: {
                            nav: 0,
                            breadcrumbs: {parent: 'app.reports', self: true}
                        }
                    }
                },
                {
                    state: 'report',
                    config: {
                        url: '/templates/:templateId/reports/:reportId',
                        params: {new: false},
                        templateUrl: 'app/reports/report/report.html',
                        controller: 'ReportController',
                        controllerAs: 'vm',
                        title: $filter('translate')('REPORTS.REPORTEDIT.EDIT_REPORT'),
                        settings: {
                            nav: 0,
                            hideSidebar: true,
                            breadcrumbs: {parent: 'app.reports'}
                        }
                    }
                }
            ];
        }
    }
})();
