(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('allowPattern', allowPattern);

    function allowPattern() {
        return {
            restrict: 'A',
            link: function($scope, $element, $attrs) {
                var newValue, currentValue, keyCode, keyCodeChar, regExp;

                var allowed = [8, 9, 37, 39, 99, 118];

                try {
                    regExp = new RegExp($attrs.allowPattern);
                } catch (e) {
                    throw new Error('Invalid RegEx string: ' + $attrs.allowPattern);
                }

                $element.bind('keypress', function(event) {
                    keyCode = event.which || event.keyCode;

                    if (allowed.indexOf(keyCode) !== -1) return;

                    keyCodeChar = String.fromCharCode(keyCode);

                    currentValue = angular.element($element).val();
                    newValue = currentValue.concat(keyCodeChar);

                    if (!regExp.test(newValue)) {
                        event.preventDefault();
                        return false;
                    }
                });
            }
        };
    }
})();
