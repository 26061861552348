(function() {
    'use strict';

    angular
        .module('app.auth')
        .service('Permissions', Permissions);

    /* @ngInject */
    function Permissions() {
        var s = this;

        s.permissions = {};

        s.set = set;
        s.get = get;
        s.clear = clear;

        s.can = can;

        function set(data) {
            s.permissions = data;

            if (data['customer']) {
                s.permissions['settings'] = s.permissions['customer'];
            }
            if (s.permissions['users']) {
                if (s.permissions['users']['view.profile'] || s.permissions['users']['update.profile']) {
                    s.permissions['profile'] = {
                        view: s.permissions['users']['view.profile'] || false,
                        update: s.permissions['users']['update.profile'] || false
                    };
                }
            }

            return s.get();
        }

        function get() {
            return s.permissions;
        }

        function clear() {
            s.permissions = {};

            return s.get();
        }

        function can(action, object) {
            if (action === 'delete.document') {
                return !!((s.permissions.documents['delete'] && object.status !== 4 && object.status !== 6) || (s.permissions.documents['delete.completed'] && object.status === 4) || (s.permissions.documents['delete.archive'] && object.status === 6));

            }
            if (action === 'restore.document') {
                return !!(s.permissions.documents['update.restore'] && object.status === 6);

            }
            if (action === 'archive.document') {
                return !!(s.permissions.documents['update.archive'] && object.status === 4);

            }
            if (action === 'complete.document') {
                return !!(s.permissions.documents['update'] && object.status !== 4 && object.status !== 6);
            }
            if (action === 'reopen.document') {
                return !!(s.permissions.documents['update.open'] && object.status === 4);
            }

        }
    }
})();
