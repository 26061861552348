(function() {
    'use strict';

    angular
        .module('blocks.logger')
        .factory('logger', logger);

    logger.$inject = ['$log', '$filter', 'Status', 'toastr'];

    /* @ngInject */
    function logger($log, $filter, Status, toastr) {
        return {
            showToasts: true,

            error: error,
            info: info,
            success: success,
            warning: warning,
            loading: loading,
            saving: saving,
            offline: offline,
            clear: clear,

            // straight to console; bypass toastr
            log: $log.log
        };

        function error(message, data, title) {
            if (message && Status.online) {
                var error = '',
                    options = {};

                if (typeof message === 'object' && message.error && message.error.message) {
                    error = message.error.message;
                } else {
                    if (message.split('<html>').length > 1) {
                        error = $filter('translate')('SOMETHING_WENT_WRONG');
                        options.timeOut = 0;
                    } else {
                        error = message;
                    }
                }

                toastr.error(error, title, options);
                $log.error('Error: ' + error, data);
            }
        }

        function info(message, data, title) {
            if (Status.online) {
                toastr.info(message, title);
                $log.info('Info: ' + message, data);
            }
        }

        function success(message, data, title) {
            if (Status.online) {
                toastr.success(message, title);
                // $log.info('Success: ' + message, data);
            }
        }

        function warning(message, data, title) {
            if (Status.online) {
                toastr.warning(message, title);
                $log.warn('Warning: ' + message, data);
            }
        }

        function loading() {
            toastr.warning(
                $filter('translate')('LOADING'), '',
                {timeOut: 0, extendedTimeOut: 0, tapToDismiss: false}
            );
        }

        function saving() {
            toastr.warning(
                $filter('translate')('SAVING_CHANGES'), '',
                {timeOut: 0, extendedTimeOut: 0, tapToDismiss: false}
            );
        }

        function offline() {
            toastr.warning(
                $filter('translate')('NO_CONNECTION'), '',
                {timeOut: 0, extendedTimeOut: 0, tapToDismiss: false}
            );
        }

        function clear() {
            toastr.clear();
        }
    }
}());
