(function() {
    'use strict';

    angular
        .module('app.documents')
        .component('viewSwitcher', {
            bindings: {
                state: '<',
                action: '&'
            },
            templateUrl: 'app/documents/fill/view-switcher/view-switcher.html'
        });
})();
