(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('compareTo', compareTo);

    function compareTo() {
        return {
            require: 'ngModel',
            scope: {
                otherModelValue: '=compareTo',
                required: '=compareToRequired'
            },
            link: function(scope, elm, attrs, ctrl) {

                ctrl.$validators.compareTo = function(modelValue) {

                    if (!scope.required && typeof scope.required !== 'undefined') {
                        return true;
                    }

                    var e1 = modelValue || '';
                    var e2 = scope.otherModelValue || '';

                    return e1 === e2;
                };

                scope.$watch('otherModelValue', function() {
                    ctrl.$validate();
                });

                scope.$watch('required', function() {
                    ctrl.$validate();
                });
            }
        };
    }
})();
