(function() {
    'use strict';

    angular.module('app.users', [
        'app.core',
        'app.layout',
        'app.auth',
        'app.customers'
    ]);
})();
