(function() {
    'use strict';

    angular
        .module('blocks.validator')
        .service('Validate', Validate);

    function Validate() {
        var s = this;

        s.email = email;

        function email(input) {
            var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

            return re.test(input);
        }
    }
})();
