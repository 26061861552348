(function() {
    'use strict';

    angular.module('app.documents', [
        'app.core',
        'app.layout',
        'app.templates',
        'app.users',
        'app.common'
    ]);
})();
