(function() {
    'use strict';

    var core = angular.module('app.core');

    core.config(toastrConfig);

    toastrConfig.$inject = ['toastr'];

    /* @ngInject */
    function toastrConfig(toastr) {
        toastr.options.timeOut = 3000;
        toastr.options.showDuration = 0;
        toastr.options.hideDuration = 0;
        toastr.options.positionClass = 'toast-top-center';
        toastr.options.preventDuplicates = true;
    }

    var config = {
        appErrorPrefix: '[Eeze Error] ',
        appTitle: 'Eeze'
    };

    core.value('config', config);

    core.config(configure);

    configure.$inject = ['$logProvider', '$httpProvider', '$resourceProvider', '$compileProvider', 'routerHelperProvider', 'exceptionHandlerProvider', 'formsTagsInputConfigProvider', 'tmhDynamicLocaleProvider', 'bugsnagProvider', 'globals'];

    /* @ngInject */
    function configure($logProvider, $httpProvider, $resourceProvider, $compileProvider, routerHelperProvider, exceptionHandlerProvider, formsTagsInputConfigProvider, tmhDynamicLocaleProvider, bugsnagProvider, globals) {
        if ($logProvider.debugEnabled) {
            $logProvider.debugEnabled(true);
        }

        $resourceProvider.defaults.cancellable = true;

        exceptionHandlerProvider.configure(config.appErrorPrefix);
        routerHelperProvider.configure({docTitle: config.appTitle + ': '});

        $httpProvider.interceptors.push('APIInterceptor');
        $httpProvider.useApplyAsync(true);

        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);

        formsTagsInputConfigProvider
            .setDefaults('formsTagsInput', {
                placeholder: '',
                addOnSpace: true
            });

        tmhDynamicLocaleProvider.localeLocationPattern('/assets/locales/angular-locale_{{locale}}.js');

        bugsnagProvider
            .apiKey(globals.bugsnagApikey || '')
            .releaseStage(angular.isDefined(globals.uiUrl) ? globals.uiUrl.split('//')[1] : '')
            .autoNotify(false)
            .notifyReleaseStages(['beta.app.eeze.io', 'app.eeze.io']);
    }

})();
