(function() {
    'use strict';

    angular
        .module('app.account')
        .controller('AccountController', AccountController);
    AccountController.$inject = ['$rootScope', '$q', '$filter', '$window', 'Account', 'Users', 'Session', 'tmhDynamicLocale', 'logger'];

    /* @ngInject */
    function AccountController($rootScope, $q, $filter, $window, Account, Users, Session, tmhDynamicLocale, logger) {
        var vm = this;

        vm.permissions = Session.permissions;

        vm.profile = {};
        vm.profileCache = {};
        vm.locales = [];
        vm.languages = [];
        vm.progress = {getProfile: false, saveProfile: false};

        vm.getProfile = getProfile;
        vm.getLocales = getLocales;
        vm.getLanguages = getLanguages;
        vm.profileSave = profileSave;

        activate();

        function activate() {
            var promises = [getProfile(), getLocales(), getLanguages()];

            return $q.all(promises);
        }

        function getProfile() {
            vm.progress.getProfile = true;

            return Users.profile.get().$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.profile = response.data;
                vm.progress.getProfile = false;
                vm.profileCache = angular.copy(response.data);
            }

            function fail(response) {
                vm.progress.getProfile = false;
                logger.error(response.data);
            }
        }

        function getLocales() {
            return Account.locales.get().$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.locales = response.data;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function getLanguages() {
            return Account.languages.get().$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.languages = response.data;
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function profileSave() {
            if (vm.accountForm.$invalid) return false;

            vm.profileError = false;
            vm.progress.saveProfile = true;

            return Users.profile.update(vm.profile).$promise
                .then(success)
                .catch(fail);

            function success(response) {
                vm.profileError = false;
                vm.profile.passwordNew = '';
                vm.profile.passwordAgain = '';
                vm.progress.saveProfile = false;

                Session.data.user = response.data;
                $rootScope.$broadcast('nameChange');

                if (vm.profile.locale != vm.profileCache.locale) tmhDynamicLocale.set(vm.profile.locale);

                if (vm.profile.language != vm.profileCache.language) {
                    localStorage.setItem('eezeLanguage', vm.profile.language);

                    $window.location.reload();
                }

                vm.profileCache = angular.copy(vm.profile);

                logger.success($filter('translate')('ACCOUNT.SAVED'));
            }

            function fail(response) {
                vm.progress.saveProfile = false;
                vm.profileError = response.data.error.message;
            }
        }
    }
})();
