(function() {
    'use strict';

    angular
        .module('app.common')
        .service('pdf', pdf);

    pdf.$inject = ['$rootScope', '$window', 'urlSigner', 'logger'];

    /* @ngInject */
    function pdf($rootScope, $window, urlSigner, logger) {
        var s = this;

        s.print = print;
        s.download = download;
        s.fileDownload = fileDownload;

        function makeIframeDownload(url) {
            var iframe = angular.element('body').find('iframe');

            if (!(iframe && iframe.length > 0)) {
                iframe = angular.element('<iframe/>').hide().appendTo('body');
            }

            iframe.attr('src', url);
        }

        function print(from, id, params) {
            var win = $window.open('', '_blank');

            var query = $.param(params);
            var url = '/' + from + '/' + id + '/file?' + query;

            return urlSigner.sign(url)
                .then(success)
                .catch(fail);

            function success(response) {
                if (win) {
                    win.location = response.data.data.signedUrl;
                    win.focus();
                }
            }

            function fail(response) {
                logger.error(response.data);
                if (win) {
                    win.close();
                }
            }
        }

        function download(from, id, params) {

            if ($rootScope.device.ios) {
                print(from, id, params);
                return;
            }

            var query = $.param(params);
            var url = '/' + from + '/' + id + '/file?' + query;

            return urlSigner.sign(url)
                .then(success)
                .catch(fail);

            function success(response) {
                makeIframeDownload(response.data.data.signedUrl);
            }

            function fail(response) {
                logger.error(response.data);
            }
        }

        function fileDownload(url) {
            if ($rootScope.device.ios) {
                var win = $window.open('', '_blank');
            }

            return urlSigner.sign(url)
                .then(success)
                .catch(fail);

            function success(response) {
                if ($rootScope.device.ios) {
                    win.location = response.data.data.signedUrl;
                    win.focus();
                } else {
                    makeIframeDownload(response.data.data.signedUrl);
                }
            }

            function fail(response) {
                logger.error(response.data);
            }
        }
    }
})();
