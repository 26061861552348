(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('TagsFilterController', TagsFilterController);

    TagsFilterController.$inject = ['$rootScope', '$timeout', '$filter', '$q', 'Tags', 'logger'];

    /* @ngInject */
    function TagsFilterController($rootScope, $timeout, $filter, $q, Tags, logger) {
        var vm = this;

        vm.progress = {get: false, more: false};
        vm.timeout = 300;

        vm.tags = [];
        vm.tagsMeta = {};
        vm.tagsFilter = {search: ''};

        vm.newTag = newTag;
        vm.checkSelected = checkSelected;
        vm.addTag = addTag;
        vm.removeTag = removeTag;
        vm.searchTags = searchTags;

        if ($rootScope.$$listeners['newTagAdded']) $rootScope.$$listeners['newTagAdded'] = [];
        $rootScope.$on('newTagAdded', vm.newTag);

        activate();

        function activate() {
            if (!Tags.list.length) {
                Tags.get();
                $rootScope.$on('gotTags', function() {
                    vm.tags = Tags.list;
                });
            } else {
                vm.tags = Tags.list;
            }
        }

        function newTag() {
            vm.tags = Tags.list;
        }

        function checkSelected() {
            if (vm.filter.length) {
                for (var i = 0; i < vm.filter.length; i++) {
                    for (var j = 0; j < vm.tags.length; j++) {
                        if (vm.filter[i].id == vm.tags[j].id) {
                            vm.tags[j].checked = true;
                        }
                    }
                }
            }
        }

        function addTag() {
            vm.filter = Tags.transform.removeDuplicates(vm.filter.concat($filter('filter')(vm.tags, {checked: true})));

            // timeout puts the call to the end of the digest cycle
            // otherwise the filter would not be updated in the directive's parent controller yet
            $timeout(vm.action);
        }

        function removeTag(tag) {
            for (var i = 0; i < vm.filter.length; i++) {
                if (vm.filter[i].id == tag.id) {
                    vm.filter.splice(i, 1);
                }
            }

            $timeout(vm.action);
        }

        function searchTags(cursor, concat) {
            var array = concat || [],
                params = {
                    number: 200,
                    current: cursor,
                    search: vm.tagsFilter.search
                };

            return Tags.search(params)
                .then(success)
                .catch(fail);

            function success(response) {
                array = array.concat(response.data.data);

                if (response.data.meta.cursor.next) {
                    vm.searchTags(response.data.meta.cursor.next, array);
                } else {
                    vm.tags = array;
                    vm.checkSelected();
                }
            }

            function fail(response) {
                logger.error(response.data);
            }
        }
    }
})();
