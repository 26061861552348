(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('pdfFieldsRepeater', pdfFieldsRepeater);

    function pdfFieldsRepeater() {
        return {
            restrict: 'EA',
            templateUrl: 'app/documents/fill/pdf-filler/pdf-fields-repeater/pdfFieldsRepeater.html',
            scope: {
                vm: '=',
                field: '='
            }
        };
    }
})();
