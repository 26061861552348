(function() {
    'use strict';

    angular
        .module('app.documents')
        .directive('sendPdf', sendPdf);

    function sendPdf() {
        return {
            restrict: 'A',
            scope: {document: '='},
            controller: 'SendPdfController',
            controllerAs: 'vm',
            bindToController: true
        };
    }
})();
