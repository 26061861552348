(function() {
    'use strict';

    angular
        .module('app.auth')
        .service('Auth', Auth);

    Auth.$inject = ['$http', '$q', '$document', 'Session', 'globals', 'Tags', 'logger'];

    /* @ngInject */
    function Auth($http, $q, $document, Session, globals, Tags, logger) {
        var checkPermission, checkState,
            s = this;

        s.jwt = 0;
        s.id = 0;

        s.logIn = logIn;
        s.logOut = logOut;
        s.verifyJwt = verifyJwt;
        s.remindPassword = remindPassword;
        s.verifyReset = verifyReset;
        s.resetPassword = resetPassword;
        s.setAccount = setAccount;
        s.allowedTo = checkPermissions;

        s.allowedStates = getAllowedStates();

        function logIn(email, password) {
            $document[0].activeElement.blur();
            angular.element('input').blur();

            return $http.post(globals.apiUrl + '/authenticate', {
                email: email,
                password: password
            })
                .then(success)
                .catch(fail);

            function success(response) {
                Session.setData(response.data.data);
                return true;
            }

            function fail(response) {
                logger.error(response.data || null);
                return false;
            }
        }

        function logOut() {
            Session.removeData();
            Tags.clearAll();
        }

        function verifyJwt(supportUserToken) {
            var token,
                deferred = $q.defer();

            if (supportUserToken) {
                token = supportUserToken;
            } else {
                token = localStorage.getItem('eezeToken');
            }

            if (token) {
                $http.post(globals.apiUrl + '/verify')
                    .then(success)
                    .catch(fail);
            } else {
                Session.removeData();
                deferred.reject();
            }

            return deferred.promise;

            function success(response) {
                Session.setData(response.data.data);
                deferred.resolve();
            }

            function fail() {
                Session.removeData();
                deferred.reject();
            }
        }

        function remindPassword(email) {
            return $http.post(globals.apiUrl + '/reset', {email: email})
                .then(success)
                .catch(fail);

            function success() {
                return true;
            }

            function fail() {
                return false;
            }
        }

        function verifyReset(token) {
            return $http.post(globals.apiUrl + '/verifyReset', {token: token})
                .then(success)
                .catch(fail);

            function success(response) {
                return response.data;
            }

            function fail(response) {
                return response.data;
            }
        }

        function resetPassword(params, token) {
            return $http.put(globals.apiUrl + '/reset', {
                password: params.password,
                token: token
            })
                .then(success)
                .catch(fail);

            function success(response) {
                return response;
            }

            function fail(response) {
                return response;
            }
        }

        function setAccount(params, token) {
            return $http.put(globals.apiUrl + '/set', {
                password: params.password,
                firstName: params.firstName,
                lastName: params.lastName,
                token: token
            })
                .then(success)
                .catch(fail);

            function success(response) {
                return response;
            }

            function fail(response) {
                return response;
            }
        }

        function checkPermissions(state) {
            checkState = state;
            checkPermission = 'view';

            checkState = state == 'app.documents' ? 'documents' : checkState;

            checkState = state == 'app.templates' ? 'templates' : checkState;
            checkState = state == 'app.settings.users' ? 'users' : checkState;
            checkState = state == 'app.settings' ? 'settings' : checkState;
            checkState = state == 'app.settings.mail' ? 'customer' : checkState;
            checkState = state == 'app.settings.tags' ? 'tags' : checkState;
            checkState = state == 'app.settings.groups' ? 'groups' : checkState;
            checkState = state == 'app.settings.account' ? 'profile' : checkState;
            checkState = state == 'app.settings.billing' ? 'profile' : checkState;
            checkState = state == 'filler' ? 'documents' : checkState;
            checkState = state == 'filler.pdf' ? 'documents' : checkState;
            checkState = state == 'filler.pdf.signature' ? 'documents' : checkState;
            checkState = state == 'filler.list' ? 'documents' : checkState;
            checkState = state == 'filler.list.signature' ? 'documents' : checkState;
            checkState = state == 'mapper' ? 'templates' : checkState;
            checkState = state == 'mapper.pdf' ? 'templates' : checkState;
            checkState = state == 'mapper.list' ? 'templates' : checkState;
            checkState = state == 'mapper.list.field' ? 'templates' : checkState;
            checkState = state == 'mapper.pdf.field' ? 'templates' : checkState;
            checkState = state == 'mapper.pdf.hierarchy' ? 'templates' : checkState;
            checkState = state == 'mapper.pdf.settings' ? 'templates' : checkState;
            checkState = state == 'app.reports' ? 'reports' : checkState;
            checkState = state == 'report' ? 'reports' : checkState;

            checkState = state == 'app.documentsError' ? 'documents' : checkState;
            checkState = state == 'app.templateError' ? 'templates' : checkState;
            checkState = state == 'app.reportsError' ? 'reports' : checkState;

            checkPermission = state == 'filler' ? 'update' : checkPermission;
            checkPermission = state == 'mapper' ? 'update' : checkPermission;
            checkPermission = state == 'mapper.pdf' ? 'update' : checkPermission;
            checkPermission = state == 'mapper.list' ? 'update' : checkPermission;
            checkPermission = state == 'report' ? 'update' : checkPermission;

            return Session.permissions[checkState] ? Session.permissions[checkState][checkPermission] : false;
        }

        function getAllowedStates() {
            return [
                'error.404',
                'auth.login',
                'auth.forgot',
                'auth.reset',
                'auth.set',
                'auth.setFull',
                'auth.emailSent',
                'auth.support'
            ];
        }
    }
})();
