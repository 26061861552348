(function() {
    'use strict';

    angular
        .module('app.common')
        .directive('uniqueEmail', uniqueEmail);

    uniqueEmail.$inject = ['$q', 'Users'];

    /* @ngInject */
    function uniqueEmail($q, Users) {
        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {

                ctrl.$errorMessage = ctrl.$errorMessage || null;
                ctrl.$startingValue = ctrl.$startingValue || null;

                ctrl.$asyncValidators.email = function(modelValue) {

                    if (ctrl.$pristine || ctrl.$startingValue === modelValue) {
                        ctrl.$startingValue = modelValue;
                        return $q.when();
                    }

                    var def = $q.defer();

                    Users.validateEmail(modelValue)
                        .then(success)
                        .catch(fail);

                    function success() {
                        ctrl.$errorMessage = null;
                        def.resolve();
                    }

                    function fail(response) {
                        ctrl.$errorMessage = response.data.error.message;
                        def.reject(response.data.error.message);
                    }

                    return def.promise;
                };
            }
        };
    }
})();
