(function() {
    'use strict';

    angular
        .module('app.layout')
        .controller('BreadcrumbsController', BreadcrumbsController);

    BreadcrumbsController.$inject = ['$scope', '$rootScope', '$state', 'msgBus'];

    /* @ngInject */
    function BreadcrumbsController($scope, $rootScope, $state, msgBus) {
        var vm = this;
        var placeholder = true;

        vm.showInput = false;
        vm.nameCache = null;

        vm.startEdit = startEdit;
        vm.action = action;

        activate();

        stateChange($state.current);

        $rootScope.$on('$stateChangeSuccess', function(event, toState) {
            stateChange(toState);
        });

        function activate() {
            $scope.$watch(function() {
                return msgBus.messages['breadcrumbs.setObject'];
            }, function(data) {
                if (data) {
                    vm.object = data.object;

                    if (data.meta) {
                        vm.nameCache = angular.copy(vm.object.name);
                        if (data.meta.new) {
                            clearNameForPlaceholder();
                        } else {
                            placeholder = false;
                        }
                    }
                }
            });

            msgBus.on('breadcrumbs.focus', function() {
                vm.startEdit();
            }, $scope);
        }

        function stateChange(state) {
            if (state.settings.breadcrumbs) {
                if (vm.parent && vm.parent.name != state.settings.breadcrumbs.parent) {
                    delete msgBus.messages['breadcrumbs.setObject'];

                    vm.object = null;
                    vm.nameCache = null;
                }

                vm.parent = state.settings.breadcrumbs.parent ? $state.get(state.settings.breadcrumbs.parent) : null;
                vm.self = state.settings.breadcrumbs.self ? state : null;
            }
        }

        function startEdit() {
            vm.showInput = true;
            clearNameForPlaceholder();
        }

        function action() {
            vm.showInput = false;

            if (!vm.object.name) {
                vm.object.name = vm.nameCache;
            } else {
                placeholder = false;
            }

            if (vm.object.name !== vm.nameCache) {
                msgBus.emit('breadcrumbs.action', vm.object);
                vm.nameCache = vm.object.name;
            }
        }

        function clearNameForPlaceholder() {
            if (placeholder && vm.object && $rootScope.device.small === false) vm.object.name = null;
        }
    }
})();
