(function() {
    'use strict';

    angular
        .module('app.templates')
        .directive('colResizeable', colResizeable);

    colResizeable.$inject = ['$timeout'];

    function colResizeable($timeout) {
        return {
            restrict: 'A',
            link: link,
            scope: {
                onColResize: '&',
                onColChange: '='
            }
        };

        function link(scope, elem, attrs) {

            scope.$watch('onColChange', function() {
                $timeout(function() {
                    elem.colResizable({disable: true});

                    elem.colResizable({
                        liveDrag: true,
                        draggingClass: 'dragging',
                        onResize: function(event) {
                            if (scope.onColResize) {
                                scope.onColResize({
                                    event: event,
                                    scope: scope,
                                    attrs: attrs
                                });
                            }
                        }
                    });
                });
            });

        }
    }

})();
