(function() {
    'use strict';

    angular
        .module('app.layout')
        .directive('breadcrumbs', breadcrumbs);

    breadcrumbs.$inject = [];

    /* @ngInject */
    function breadcrumbs() {
        return {
            bindToController: true,
            controller: 'BreadcrumbsController',
            controllerAs: 'vm',
            templateUrl: 'app/layout/breadcrumbs/breadcrumbs.html',
            replace: true,
            scope: {}
        };
    }
})();
