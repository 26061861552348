(function() {
    'use strict';

    angular
        .module('app.documents')
        .controller('FillController', FillController);

    FillController.$inject = ['$scope', '$rootScope', '$state', '$stateParams', '$timeout'];

    /* @ngInject */
    function FillController($scope, $rootScope, $state, $stateParams, $timeout) {
        var deregister,
            parentState = 'filler',
            listState = 'filler.list',
            pdfState = 'filler.pdf',
            vm = this;

        vm.progress = {getDocument: false};

        activate();

        function activate() {
            deregister = $rootScope.$on(
                '$stateChangeStart',
                function(e, to, toParams, from) {
                    checkState(to, from);
                }
            );

            if ($state.current.name == parentState) {
                $timeout(function() {
                    $state.go(getState(), {
                        'id': $stateParams.id,
                        'new': $stateParams.new,
                        'duplicate': $stateParams.duplicate
                    });
                });
            }
        }

        function getState() {
            if ($stateParams.templateType === 'list') return listState;

            return localStorage.getItem('eezeFillerView') || ($rootScope.device.extraSmall ? listState : pdfState);
        }

        function checkState(to, from) {
            if (to.name == parentState && (from.name == pdfState || from.name == listState)) $state.go('app.documents');
        }

        $scope.$on('$destroy', deregister);
    }
})();
