(function() {
    'use strict';

    angular
        .module('app.auth')
        .controller('ResetController', ResetController);

    ResetController.$inject = ['$q', '$state', '$stateParams', '$timeout', '$window', 'Auth', 'Session'];

    /* @ngInject */
    function ResetController($q, $state, $stateParams, $timeout, $window, Auth, Session) {
        var vm = this;

        vm.logIn = logIn;
        vm.verifyReset = verifyReset;
        vm.resetPassword = resetPassword;
        vm.setAccount = setAccount;

        vm.progress = false;
        vm.resetError = false;

        activate();

        function activate() {
            var promises = [verifyReset()];

            return $q.all(promises);
        }

        function verifyReset() {
            vm.progress = true;

            return Auth.verifyReset($stateParams.token)
                .then(success);

            function success(response) {
                if (response.error) {
                    vm.resetError = 'expired';
                }

                $timeout(function() {
                    vm.progress = false;
                });
            }
        }

        function logIn(email, password) {
            vm.inProgress = true;
            return Auth.logIn(email, password).then(function(response) {
                if (response === true) {
                    vm.logInError = false;
                    $window.scrollTo(0, 0);
                    $state.go(Session.lastState.name || 'app.documents', Session.lastState.params || {});
                    Session.lastState = '';
                } else {
                    vm.logInError = response.message;
                    vm.password = '';
                }
                vm.inProgress = false;
            });
        }

        function resetPassword() {
            if (vm.resetForm.$invalid) return false;

            vm.inProgress = true;

            return Auth.resetPassword({password: vm.password}, $stateParams.token)
                .then(function(response) {
                    if (!response.data.error) {
                        vm.resetError = false;
                        vm.logIn(response.data.data.email, vm.password);
                    } else {
                        if (response.status == 422) {
                            vm.resetError = 'expired';
                        } else {
                            vm.resetError = response.data.error.message;
                        }
                        vm.password = '';
                        vm.passwordAgain = '';
                    }
                    vm.inProgress = false;
                });
        }

        function setAccount() {
            if (vm.setForm.$invalid) return false;

            vm.inProgress = true;

            return Auth.setAccount({password: vm.password, firstName: vm.firstName, lastName: vm.lastName}, $stateParams.token)
                .then(function(response) {
                    if (!response.data.error) {
                        vm.setError = false;
                        vm.logIn(response.data.data.email, vm.password);
                    } else {
                        if (response.status == 422) {
                            vm.setError = 'expired';
                        } else {
                            vm.setError = response.data.error.message;
                        }
                        vm.password = '';
                        vm.passwordAgain = '';
                    }
                    vm.inProgress = false;
                });
        }

    }
})();
