/* global toastr:false, moment:false */
(function() {
    'use strict';

    var env = {};

    // Import variables if present (from env.js)
    if (window && window.eezeEnv) {
        angular.copy(window.eezeEnv, env);
    }

    angular
        .module('app.core')
        .constant('toastr', toastr)
        .constant('moment', moment)
        .constant('constLineHeight', 1.42857143)
        .constant('globals', env);
})();
