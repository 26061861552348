(function() {
    'use strict';

    angular.module('app.templates', [
        'app.core',
        'app.layout',
        'app.common',
        'rzModule',
        'ngHandsontable',
        'ui.sortable'
    ]);
})();
